import { combineReducers } from 'redux';

import {
  saga as scholarshipDefinitionSagas,
  reducer as scholarshipDefinitionReducer
} from 'pages/scholarship/definitions/_store/scholarship-definition';

export const scholarshipSagas = [scholarshipDefinitionSagas()];
export const scholarshipReducer = combineReducers({
  definitions: scholarshipDefinitionReducer
});
