import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';
import BankAccountNew from './BankAccountNew';

const AccountList = Loadable(lazy(() => import('./BankAccountList')));
const BankAccountSettingList = Loadable(lazy(() => import('./BankAccountSettingList')));

const routes = {
  path: 'accounts',
  children: [
    {
      path: '',
      element: <AccountList />
    },
    {
      path: 'new',
      element: <BankAccountNew />
    },
    {
      path: ':id',
      element: <AccountList />
    },
    {
      path: ':id/:action',
      element: <AccountList />
    },
    {
      path: ':id/setting',
      element: <BankAccountSettingList />
    },
    {
      path: ':id/setting/:subid/:subaction',
      element: <BankAccountSettingList />
    }
  ]
};

export default routes;
