import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction, TPhase } from 'store/store';
import { BANK_ACCOUNTS_SETTINGS_API_URL } from 'store/ApiUrls';

export interface IBankAccountSetting {
  id?: number;
  paymentExpiry?: number;
  commissionRate?: number;
  paymentMethodId?: number;
  bankAccountId?: number;
  school?: ISchool;
}

interface IBankAccountSettingState {
  bankAccountSettings: IBankAccountSetting[];
  phase: TPhase;
}

type TActionAllState = IBankAccountSettingState & {
  id: number;
  activeSchool: ISchool;
  bankAccountId: number;
  bankAccountSetting: IBankAccountSetting;
  bankAccountSettingInfo: Partial<IBankAccountSetting>;
};

export const actionTypes = {
  PULL_BANK_ACCOUNT_SETTINGS: 'bankAccountSettings/PULL_BANK_ACCOUNT_SETTINGS',
  SET_BANK_ACCOUNT_SETTINGS: 'bankAccountSettings/SET_BANK_ACCOUNT_SETTINGS',
  ADD_BANK_ACCOUNT_SETTING: 'bankAccountSettings/ADD_BANK_ACCOUNT_SETTING',
  UPDATE_BANK_ACCOUNT_SETTING: 'bankAccountSettings/UPDATE_BANK_ACCOUNT_SETTING',
  DELETE_BANK_ACCOUNT_SETTING: 'bankAccountSettings/DELETE_BANK_ACCOUNT_SETTING',
  REMOVE_BANK_ACCOUNT_SETTING: 'bankAccountSettings/REMOVE_BANK_ACCOUNT_SETTING',
  SET_BANK_ACCOUNT_SETTING: 'bankAccountSettings/SET_BANK_ACCOUNT_SETTING',
  SET_PHASE: 'bankAccountSettings/SET_PHASE'
};

export const initialState: IBankAccountSettingState = {
  bankAccountSettings: [],
  phase: null
};

export const bankAccountSettingsSelector = createSelector(
  (state: IBankAccountSettingState) =>
    objectPath.get(state, ['bankAccountSettings', 'bankAccountSettings']),
  (bankAccountSettings: IBankAccountSetting[]) => bankAccountSettings
);

export const bankAccountSettingsPhaseSelector = createSelector(
  (state: IBankAccountSettingState) => objectPath.get(state, ['bankAccountSettings', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'bankAccountSettings' },
  (
    state: IBankAccountSettingState = initialState,
    action: IAction<TActionAllState>
  ): IBankAccountSettingState => {
    switch (action.type) {
      case actionTypes.SET_BANK_ACCOUNT_SETTINGS: {
        const { bankAccountSettings } = action.payload;
        return { ...state, bankAccountSettings };
      }
      case actionTypes.SET_BANK_ACCOUNT_SETTING: {
        const { bankAccountSetting } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.bankAccountSettings.findIndex(
            (d) => d.id === bankAccountSetting.id
          );
          if (index > -1) {
            draftState.bankAccountSettings[index] = bankAccountSetting;
          } else {
            draftState.bankAccountSettings.unshift(bankAccountSetting);
          }
        });
      }
      case actionTypes.REMOVE_BANK_ACCOUNT_SETTING: {
        const { id } = action.payload;
        const bankAccountSettings = { ...state }.bankAccountSettings.filter((d) => d.id !== id);
        return { ...state, bankAccountSettings };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const bankAccountSettingsActions = {
  pullBankAccountSettings: (
    activeSchool: ISchool,
    bankAccountId: number
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_BANK_ACCOUNT_SETTINGS,
    payload: { activeSchool, bankAccountId }
  }),
  setBankAccountSettings: (
    bankAccountSettings: IBankAccountSetting[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANK_ACCOUNT_SETTINGS,
    payload: { bankAccountSettings }
  }),
  addBankAccountSetting: (
    bankAccountSettingInfo: Partial<IBankAccountSetting>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_BANK_ACCOUNT_SETTING,
    payload: { bankAccountSettingInfo }
  }),
  updateBankAccountSetting: (
    bankAccountSettingInfo: Partial<IBankAccountSetting>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_BANK_ACCOUNT_SETTING,
    payload: { bankAccountSettingInfo }
  }),
  deleteBankAccountSetting: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_BANK_ACCOUNT_SETTING,
    payload: { id }
  }),
  removeBankAccountSetting: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_BANK_ACCOUNT_SETTING,
    payload: { id }
  }),
  setBankAccountSetting: (
    bankAccountSetting: IBankAccountSetting
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANK_ACCOUNT_SETTING,
    payload: { bankAccountSetting }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_BANK_ACCOUNT_SETTINGS,
    function* pullBankAccountSettingsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountSettingsActions.setPhase('loading'));

      const { activeSchool, bankAccountId } = payload;
      const url = `${BANK_ACCOUNTS_SETTINGS_API_URL}.json?school=${activeSchool.id}&bankAccountId=${bankAccountId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(bankAccountSettingsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountSettingsActions.setBankAccountSettings(response.data));
      yield put(bankAccountSettingsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_BANK_ACCOUNT_SETTING,
    function* addBankAccountSettingsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountSettingsActions.setPhase('adding'));

      const { bankAccountSettingInfo } = payload;

      const response = yield axios.post(
        `${BANK_ACCOUNTS_SETTINGS_API_URL}`,
        bankAccountSettingInfo
      );

      if (response.status !== 200) {
        yield put(bankAccountSettingsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountSettingsActions.setBankAccountSetting(response.data));
      yield put(bankAccountSettingsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_BANK_ACCOUNT_SETTING,
    function* updateBankAccountSettingsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountSettingsActions.setPhase('updating'));

      const { bankAccountSettingInfo } = payload;

      const response = yield axios.patch(
        `${BANK_ACCOUNTS_SETTINGS_API_URL}/${bankAccountSettingInfo.id}`,
        bankAccountSettingInfo
      );

      if (response.status !== 200) {
        yield put(bankAccountSettingsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountSettingsActions.setBankAccountSetting(response.data));
      yield put(bankAccountSettingsActions.setPhase('success'));
    }
  );
}
