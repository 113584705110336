import { combineReducers } from 'redux';

import {
  saga as enrollmentAgreementSagas,
  reducer as enrollmentAgreementReducer
} from 'pages/enrollment/agreements/_store/enrollment-agreement';

import {
  saga as enrollmentAgreementItemSagas,
  reducer as enrollmentAgreementItemReducer
} from 'pages/enrollment/agreements/items/_store/enrollment-agreement-item';

import {
  saga as enrollmentDocumentSagas,
  reducer as enrollmentDocumentReducer
} from 'pages/enrollment/documents/_store/enrollment-document';

export const enrollmentSagas = [
  enrollmentAgreementSagas(),
  enrollmentAgreementItemSagas(),
  enrollmentDocumentSagas()
];
export const enrollmentReducer = combineReducers({
  enrollmentAgreements: enrollmentAgreementReducer,
  enrollmentAgreementItems: enrollmentAgreementItemReducer,
  enrollmentDocuments: enrollmentDocumentReducer
});
