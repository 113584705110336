import { combineReducers } from 'redux';
import * as studentTags from 'pages/students/tags/_store/tags';
import * as parentStudents from 'pages/students/parent/_store/students';
import * as students from 'pages/students/student-list/_store/students';
import * as studentDiscounts from 'pages/students/student-discount/_store/student-discount';
import * as studentCourses from 'pages/students/student-course/_store/student-course';
import * as studentBatches from 'pages/students/student-batch/_store/student-batch';
import * as studentScholarships from 'pages/students/student-scholarship/_store/student-scholarship';
import * as studentBusFees from 'pages/students/student-bus-fee/_store/student-bus-fee';
import * as promissoryNotes from 'pages/students/promissory-note/_store/promissory-note';
import * as deletedPromissoryNotes from 'pages/students/promissory-note/_store/deleted-promissory-note';
import * as studentPayments from 'pages/students/student-payment/_store/student-payment';
import * as invoices from 'pages/students/student-invoice/_store/invoice';
import * as studentDocuments from 'pages/students/student-document/_store/student-document';

export const studentsSagas = [
  parentStudents.saga(),
  studentTags.saga(),
  students.saga(),
  studentDiscounts.saga(),
  studentCourses.saga(),
  studentBatches.saga(),
  studentScholarships.saga(),
  studentBusFees.saga(),
  promissoryNotes.saga(),
  deletedPromissoryNotes.saga(),
  studentPayments.saga(),
  invoices.saga(),
  studentDocuments.saga()
];
export const studentsReducer = combineReducers({
  parent: parentStudents.reducer,
  tags: studentTags.reducer,
  student: students.reducer,
  studentDiscounts: studentDiscounts.reducer,
  studentCourses: studentCourses.reducer,
  studentBatches: studentBatches.reducer,
  studentScholarships: studentScholarships.reducer,
  studentBusFees: studentBusFees.reducer,
  promissoryNotes: promissoryNotes.reducer,
  deletedPromissoryNotes: deletedPromissoryNotes.reducer,
  studentPayments: studentPayments.reducer,
  invoices: invoices.reducer,
  studentDocuments: studentDocuments.reducer
});
