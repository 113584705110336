import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { SCHOOL_BUS_FEES_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';

export interface ISchoolBusFee {
  id: number;
  feeTitle?: string;
  description?: string;
  feeAmount?: number;
  school?: number;
}

interface ISchoolBusFeeState {
  schoolBusFees: ISchoolBusFee[];
  phase: string;
}

type TActionAllState = ISchoolBusFeeState & {
  id: number;
  school: ISchool;
  schoolBusFee: ISchoolBusFee;
  schoolBusFeeInfo: Partial<ISchoolBusFee>;
};

export const actionTypes = {
  PULL_SCHOOL_BUS_FEES: 'schoolBusFees/PULL_SCHOOL_BUS_FEES',
  SET_SCHOOL_BUS_FEES: 'schoolBusFees/SET_SCHOOL_BUS_FEES',
  ADD_SCHOOL_BUS_FEE: 'schoolBusFees/ADD_SCHOOL_BUS_FEE',
  UPDATE_SCHOOL_BUS_FEE: 'schoolBusFees/UPDATE_SCHOOL_BUS_FEE',
  DELETE_SCHOOL_BUS_FEE: 'schoolBusFees/DELETE_SCHOOL_BUS_FEE',
  REMOVE_SCHOOL_BUS_FEE: 'schoolBusFees/REMOVE_SCHOOL_BUS_FEE',
  SET_SCHOOL_BUS_FEE: 'schoolBusFees/SET_SCHOOL_BUS_FEE',
  SET_PHASE: 'schoolBusFees/SET_PHASE'
};

export const initialState: ISchoolBusFeeState = {
  schoolBusFees: [],
  phase: null
};

export const schoolBusFeesSelector = createSelector(
  (state: ISchoolBusFeeState) => objectPath.get(state, ['schoolBusFees', 'schoolBusFees']),
  (schoolBusFees: ISchoolBusFee[]) => schoolBusFees
);

export const schoolBusFeesPhaseSelector = createSelector(
  (state: ISchoolBusFeeState) => objectPath.get(state, ['schoolBusFees', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'schoolBuses' },
  (
    state: ISchoolBusFeeState = initialState,
    action: IAction<TActionAllState>
  ): ISchoolBusFeeState => {
    switch (action.type) {
      case actionTypes.SET_SCHOOL_BUS_FEES: {
        const { schoolBusFees } = action.payload;
        return { ...state, schoolBusFees };
      }
      case actionTypes.SET_SCHOOL_BUS_FEE: {
        const { schoolBusFee } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.schoolBusFees.findIndex((d) => d.id === schoolBusFee.id);
          if (index > -1) {
            draftState.schoolBusFees[index] = schoolBusFee;
          } else {
            draftState.schoolBusFees.unshift(schoolBusFee);
          }
        });
      }
      case actionTypes.REMOVE_SCHOOL_BUS_FEE: {
        const { id } = action.payload;
        const schoolBusFees = { ...state }.schoolBusFees.filter((d) => d.id !== id);
        return { ...state, schoolBusFees };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const schoolBusFeesActions = {
  pullSchoolBusFees: (school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_SCHOOL_BUS_FEES,
    payload: { school }
  }),
  setSchoolBusFees: (schoolBusFees: ISchoolBusFee[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SCHOOL_BUS_FEES,
    payload: { schoolBusFees }
  }),
  addSchoolBusFee: (
    schoolBusFeeInfo: Partial<ISchoolBusFee>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_SCHOOL_BUS_FEE,
    payload: { schoolBusFeeInfo }
  }),
  updateSchoolBusFee: (
    schoolBusFeeInfo: Partial<ISchoolBusFee>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_SCHOOL_BUS_FEE,
    payload: { schoolBusFeeInfo }
  }),
  deleteSchoolBusFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_SCHOOL_BUS_FEE,
    payload: { id }
  }),
  removeSchoolBusFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_SCHOOL_BUS_FEE,
    payload: { id }
  }),
  setSchoolBusFee: (schoolBusFee: ISchoolBusFee): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SCHOOL_BUS_FEE,
    payload: { schoolBusFee }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_SCHOOL_BUS_FEES,
    function* pullSchoolBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(schoolBusFeesActions.setPhase('loading'));

      const { school } = payload;
      const url = `${SCHOOL_BUS_FEES_API_URL}.json?school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(schoolBusFeesActions.setPhase('error'));
        return;
      }

      yield put(schoolBusFeesActions.setSchoolBusFees(response.data));
      yield put(schoolBusFeesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_SCHOOL_BUS_FEE,
    function* addSchoolBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(schoolBusFeesActions.setPhase('adding'));

      const { schoolBusFeeInfo } = payload;
      const response = yield axios.post(`${SCHOOL_BUS_FEES_API_URL}`, schoolBusFeeInfo);

      if (response.status !== 201) {
        yield put(schoolBusFeesActions.setPhase('adding-error'));
        return;
      }

      yield put(schoolBusFeesActions.setSchoolBusFee(response.data));
      yield put(schoolBusFeesActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_SCHOOL_BUS_FEE,
    function* updateSchoolBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(schoolBusFeesActions.setPhase('adding'));

      const { schoolBusFeeInfo } = payload;
      const response = yield axios.patch(
        `${SCHOOL_BUS_FEES_API_URL}/${schoolBusFeeInfo.id}`,
        schoolBusFeeInfo
      );

      if (response.status !== 200) {
        yield put(schoolBusFeesActions.setPhase('error'));
        return;
      }

      yield put(schoolBusFeesActions.setSchoolBusFee(response.data));
      yield put(schoolBusFeesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_SCHOOL_BUS_FEE,
    function* deleteSchoolBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(schoolBusFeesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${SCHOOL_BUS_FEES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(schoolBusFeesActions.setPhase('error'));
        return;
      }

      yield put(schoolBusFeesActions.removeSchoolBusFee(id));
      yield put(schoolBusFeesActions.setPhase('success'));
    }
  );
}
