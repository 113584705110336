import React from 'react';
import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const CompanyList = Loadable(lazy(() => import('./CompanyList')));
const CompanyNew = Loadable(lazy(() => import('./CompanyNew')));
const routes = {
  path: 'companies',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <CompanyList />
    },
    {
      path: 'new',
      element: <CompanyNew />
    },
    {
      path: ':id',
      element: <CompanyList />
    },
    {
      path: ':id/:action',
      element: <CompanyList />
    }
  ]
};

export default routes;
