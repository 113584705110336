import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { STUDENT_SCHOLARSHIPS_API_URL } from 'store/ApiUrls';
import { IStudent } from 'pages/students/_store/types';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';

interface IScholarship {
  id?: number;
  title?: string;
  active?: string;
}

export interface IStudentScholarship {
  id?: number;
  studentId?: number;
  scholarship?: IScholarship;
  scholarshipRate?: number;
  scholarshipAmount?: number;
  description?: string;
  school: ISchool;
}

interface IStudentScholarshipState {
  studentScholarships: IStudentScholarship[];
  phase: string;
}

type TActionAllState = IStudentScholarshipState & {
  id: number;
  student: IStudent;
  studentScholarship: IStudentScholarship;
  studentScholarshipInfo: Partial<IStudentScholarship>;
};

export const actionTypes = {
  PULL_STUDENT_SCHOLARSHIPS: 'studentScholarships/PULL_STUDENT_SCHOLARSHIPS',
  SET_STUDENT_SCHOLARSHIPS: 'studentScholarships/SET_STUDENT_SCHOLARSHIPS',
  ADD_STUDENT_SCHOLARSHIP: 'studentScholarships/ADD_STUDENT_SCHOLARSHIP',
  UPDATE_STUDENT_SCHOLARSHIP: 'studentScholarships/UPDATE_STUDENT_SCHOLARSHIP',
  DELETE_STUDENT_SCHOLARSHIP: 'studentScholarships/DELETE_STUDENT_SCHOLARSHIP',
  REMOVE_STUDENT_SCHOLARSHIP: 'studentScholarships/REMOVE_STUDENT_SCHOLARSHIP',
  SET_STUDENT_SCHOLARSHIP: 'studentScholarships/SET_STUDENT_SCHOLARSHIP',
  SET_PHASE: 'studentScholarships/SET_PHASE'
};

export const initialState: IStudentScholarshipState = {
  studentScholarships: [],
  phase: null
};

export const studentScholarshipsSelector = createSelector(
  (state: IStudentScholarshipState) =>
    objectPath.get(state, ['students', 'studentScholarships', 'studentScholarships']),
  (studentScholarships: IStudentScholarship[]) => studentScholarships
);

export const studentScholarshipsPhaseSelector = createSelector(
  (state: IStudentScholarshipState) =>
    objectPath.get(state, ['students', 'studentScholarships', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentScholarships' },
  (
    state: IStudentScholarshipState = initialState,
    action: IAction<TActionAllState>
  ): IStudentScholarshipState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_SCHOLARSHIPS: {
        const { studentScholarships } = action.payload;
        return { ...state, studentScholarships };
      }
      case actionTypes.SET_STUDENT_SCHOLARSHIP: {
        const { studentScholarship } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentScholarships.findIndex(
            (d) => d.id === studentScholarship.id
          );
          if (index > -1) {
            draftState.studentScholarships[index] = studentScholarship;
          } else {
            draftState.studentScholarships.unshift(studentScholarship);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_SCHOLARSHIP: {
        const { id } = action.payload;
        const studentScholarships = { ...state }.studentScholarships.filter((d) => d.id !== id);
        return { ...state, studentScholarships };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentScholarshipsActions = {
  pullStudentScholarships: (student: IStudent): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_SCHOLARSHIPS,
    payload: { student }
  }),
  setStudentScholarships: (
    studentScholarships: IStudentScholarship[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_SCHOLARSHIPS,
    payload: { studentScholarships }
  }),
  addStudentScholarship: (
    studentScholarshipInfo: Partial<IStudentScholarship>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_SCHOLARSHIP,
    payload: { studentScholarshipInfo }
  }),
  updateStudentScholarship: (
    studentScholarshipInfo: Partial<IStudentScholarship>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_SCHOLARSHIP,
    payload: { studentScholarshipInfo }
  }),
  deleteStudentScholarship: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_SCHOLARSHIP,
    payload: { id }
  }),
  removeStudentScholarship: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_SCHOLARSHIP,
    payload: { id }
  }),
  setStudentScholarship: (
    studentScholarship: IStudentScholarship
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_SCHOLARSHIP,
    payload: { studentScholarship }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_SCHOLARSHIPS,
    function* pullStudentScholarshipSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentScholarshipsActions.setPhase('loading'));

      const { student } = payload;
      const url = `${STUDENT_SCHOLARSHIPS_API_URL}.json?studentId=${student.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentScholarshipsActions.setPhase('error'));
        return;
      }

      yield put(studentScholarshipsActions.setStudentScholarships(response.data));
      yield put(studentScholarshipsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_SCHOLARSHIP,
    function* addStudentScholarshipSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentScholarshipsActions.setPhase('adding'));

      const { studentScholarshipInfo } = payload;
      const response = yield axios.post(`${STUDENT_SCHOLARSHIPS_API_URL}`, studentScholarshipInfo);

      if (response.status !== 201) {
        yield put(studentScholarshipsActions.setPhase('error'));
        return;
      }

      yield put(studentScholarshipsActions.setStudentScholarship(response.data));
      yield put(studentScholarshipsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_SCHOLARSHIP,
    function* updateStudentScholarshipSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentScholarshipsActions.setPhase('adding'));

      const { studentScholarshipInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_SCHOLARSHIPS_API_URL}/${studentScholarshipInfo.id}`,
        studentScholarshipInfo
      );

      if (response.status !== 200) {
        yield put(studentScholarshipsActions.setPhase('error'));
        return;
      }

      yield put(studentScholarshipsActions.setStudentScholarship(response.data));
      yield put(studentScholarshipsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_SCHOLARSHIP,
    function* deleteStudentScholarshipSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentScholarshipsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_SCHOLARSHIPS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentScholarshipsActions.setPhase('deleting-error'));
        return;
      }

      yield put(studentScholarshipsActions.removeStudentScholarship(id));
      yield put(studentScholarshipsActions.setPhase('deleting-success'));
    }
  );
}
