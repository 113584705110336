import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const MailTemplateList = Loadable(lazy(() => import('./MailTemplateList')));
const MailTemplateNew = Loadable(lazy(() => import('./MailTemplateNew')));
const routes = {
  path: 'templates',
  children: [
    {
      path: '',
      element: <MailTemplateList />
    },
    {
      path: 'new',
      element: <MailTemplateNew />
    },
    {
      path: ':id',
      element: <MailTemplateList />
    },
    {
      path: ':id/:action',
      element: <MailTemplateList />
    }
  ]
};

export default routes;
