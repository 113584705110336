import { combineReducers } from 'redux';
import { saga as personsSagas, reducer as personsReducer } from '../personnel/_store/personnel';
import {
  saga as personnelCategorySagas,
  reducer as personnelCategoryReducer
} from './personnel-category';
import {
  saga as personnelDepartmentSagas,
  reducer as personnelDepartmentReducer
} from './personnel-department';

export const personnelSagas = [
  personsSagas(),
  personnelCategorySagas(),
  personnelDepartmentSagas()
];
export const personnelReducer = combineReducers({
  personnel: personsReducer,
  categories: personnelCategoryReducer,
  departments: personnelDepartmentReducer
});
