import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const OpticFormTemplateList = Loadable(lazy(() => import('./OpticFormTemplateList')));
const OpticFormTemplateNew = Loadable(lazy(() => import('./OpticFormTemplateNew')));
const OpticFormTemplateField = Loadable(lazy(() => import('./OpticFormTemplateField')));
const routes = {
  path: 'optic-form-template',
  children: [
    {
      path: '',
      element: <OpticFormTemplateList />
    },
    {
      path: 'new',
      element: <OpticFormTemplateNew />
    },
    {
      path: ':id',
      element: <OpticFormTemplateList />
    },
    {
      path: ':id/:action',
      element: <OpticFormTemplateList />
    },
    {
      path: ':id/field',
      element: <OpticFormTemplateField />
    },
    {
      path: ':id/field/:subaction',
      element: <OpticFormTemplateField />
    },
    {
      path: ':id/field/:subid/:subaction',
      element: <OpticFormTemplateField />
    }
  ]
};

export default routes;
