import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { OPTIC_FORM_TEMPLATE_FIELDS_API_URL } from 'store/ApiUrls';

export interface IOpticFormTemplateField {
  id?: number;
  fieldSubjectId?: string;
  fieldStart?: number;
  fieldLength?: number;
  fieldOrder?: number;
  opticFormId?: number;
}

interface IOpticFormTemplateFieldState {
  opticFormTemplateFields: IOpticFormTemplateField[];
  phase: string;
}

type TActionAllState = IOpticFormTemplateFieldState & {
  id: number;
  opticFormId: number;
  opticFormTemplateField: IOpticFormTemplateField;
  opticFormTemplateFieldInfo: Partial<IOpticFormTemplateField>;
};

export const actionTypes = {
  PULL_OPTIC_FORM_TEMPLATE_FIELDS: 'opticFormTemplateFields/PULL_OPTIC_FORM_TEMPLATE_FIELDS',
  SET_OPTIC_FORM_TEMPLATE_FIELDS: 'opticFormTemplateFields/SET_OPTIC_FORM_TEMPLATE_FIELDS',
  ADD_OPTIC_FORM_TEMPLATE_FIELD: 'opticFormTemplateFields/ADD_OPTIC_FORM_TEMPLATE_FIELD',
  UPDATE_OPTIC_FORM_TEMPLATE_FIELD: 'opticFormTemplateFields/UPDATE_OPTIC_FORM_TEMPLATE_FIELD',
  DELETE_OPTIC_FORM_TEMPLATE_FIELD: 'opticFormTemplateFields/DELETE_OPTIC_FORM_TEMPLATE_FIELD',
  REMOVE_OPTIC_FORM_TEMPLATE_FIELD: 'opticFormTemplateFields/REMOVE_OPTIC_FORM_TEMPLATE_FIELD',
  SET_OPTIC_FORM_TEMPLATE_FIELD: 'opticFormTemplateFields/SET_OPTIC_FORM_TEMPLATE_FIELD',
  SET_PHASE: 'opticFormTemplateFields/SET_PHASE'
};

export const initialState: IOpticFormTemplateFieldState = {
  opticFormTemplateFields: [],
  phase: null
};

export const opticFormTemplateFieldsSelector = createSelector(
  (state: IOpticFormTemplateFieldState) =>
    objectPath.get(state, ['exams', 'opticFormTemplateFields', 'opticFormTemplateFields']),
  (opticFormTemplateFields: IOpticFormTemplateField[]) => opticFormTemplateFields
);
export const opticFormTemplateFieldsPhaseSelector = createSelector(
  (state: IOpticFormTemplateFieldState) =>
    objectPath.get(state, ['exams', 'opticFormTemplateFields', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'opticFormTemplateFields' },
  (
    state: IOpticFormTemplateFieldState = initialState,
    action: IAction<TActionAllState>
  ): IOpticFormTemplateFieldState => {
    switch (action.type) {
      case actionTypes.SET_OPTIC_FORM_TEMPLATE_FIELDS: {
        const { opticFormTemplateFields } = action.payload;
        return { ...state, opticFormTemplateFields };
      }
      case actionTypes.SET_OPTIC_FORM_TEMPLATE_FIELD: {
        const { opticFormTemplateField } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.opticFormTemplateFields.findIndex(
            (d) => d.id === opticFormTemplateField.id
          );
          if (index > -1) {
            draftState.opticFormTemplateFields[index] = opticFormTemplateField;
          } else {
            draftState.opticFormTemplateFields.unshift(opticFormTemplateField);
          }
        });
      }
      case actionTypes.REMOVE_OPTIC_FORM_TEMPLATE_FIELD: {
        const { id } = action.payload;
        const opticFormTemplateFields = { ...state }.opticFormTemplateFields.filter(
          (d) => d.id !== id
        );
        return { ...state, opticFormTemplateFields };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const opticFormTemplateFieldsActions = {
  pullOpticFormTemplateFields: (opticFormId: number) => ({
    type: actionTypes.PULL_OPTIC_FORM_TEMPLATE_FIELDS,
    payload: { opticFormId }
  }),
  setOpticFormTemplateFields: (
    opticFormTemplateFields: IOpticFormTemplateField[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_OPTIC_FORM_TEMPLATE_FIELDS,
    payload: { opticFormTemplateFields }
  }),
  addOpticFormTemplateField: (
    opticFormTemplateFieldInfo: Partial<IOpticFormTemplateField>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_OPTIC_FORM_TEMPLATE_FIELD,
    payload: { opticFormTemplateFieldInfo }
  }),
  updateOpticFormTemplateField: (
    opticFormTemplateFieldInfo: Partial<IOpticFormTemplateField>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_OPTIC_FORM_TEMPLATE_FIELD,
    payload: { opticFormTemplateFieldInfo }
  }),
  deleteOpticFormTemplateField: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_OPTIC_FORM_TEMPLATE_FIELD,
    payload: { id }
  }),
  removeOpticFormTemplateField: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_OPTIC_FORM_TEMPLATE_FIELD,
    payload: { id }
  }),
  setOpticFormTemplateField: (
    opticFormTemplateField: IOpticFormTemplateField
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_OPTIC_FORM_TEMPLATE_FIELD,
    payload: { opticFormTemplateField }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_OPTIC_FORM_TEMPLATE_FIELDS,
    function* pullOpticFormTemplateFieldSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplateFieldsActions.setPhase('loading'));

      const { opticFormId } = payload;

      const url = `${OPTIC_FORM_TEMPLATE_FIELDS_API_URL}.json?opticFormId=${opticFormId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(opticFormTemplateFieldsActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplateFieldsActions.setOpticFormTemplateFields(response.data));
      yield put(opticFormTemplateFieldsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_OPTIC_FORM_TEMPLATE_FIELD,
    function* addOpticFormTemplateFieldSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplateFieldsActions.setPhase('adding'));

      const { opticFormTemplateFieldInfo } = payload;
      const response = yield axios.post(
        `${OPTIC_FORM_TEMPLATE_FIELDS_API_URL}`,
        opticFormTemplateFieldInfo
      );

      if (response.status !== 201) {
        yield put(opticFormTemplateFieldsActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplateFieldsActions.setOpticFormTemplateField(response.data));
      yield put(opticFormTemplateFieldsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_OPTIC_FORM_TEMPLATE_FIELD,
    function* updateOpticFormTemplateFieldSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplateFieldsActions.setPhase('updating'));

      const { opticFormTemplateFieldInfo } = payload;
      const response = yield axios.patch(
        `${OPTIC_FORM_TEMPLATE_FIELDS_API_URL}/${opticFormTemplateFieldInfo.id}`,
        opticFormTemplateFieldInfo
      );

      if (response.status !== 200) {
        yield put(opticFormTemplateFieldsActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplateFieldsActions.setOpticFormTemplateField(response.data));
      yield put(opticFormTemplateFieldsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_OPTIC_FORM_TEMPLATE_FIELD,
    function* deleteOpticFormTemplateFieldSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplateFieldsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${OPTIC_FORM_TEMPLATE_FIELDS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(opticFormTemplateFieldsActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplateFieldsActions.removeOpticFormTemplateField(id));
      yield put(opticFormTemplateFieldsActions.setPhase('success'));
    }
  );
}
