import React from 'react';
import loadable from '@loadable/component';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AppDispatch, RootState } from 'store/store';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { SaveButton, CancelButton, FormButtons } from 'utils/ActionLinks';
import { TActionType } from 'utils/shared-types';
import { userActiveSchoolSelector } from 'store/user';
import {
  enrollmentAgreementItemsActions,
  enrollmentAgreementItemsPhaseSelector,
  enrollmentAgreementItemsSelector,
  IEnrollmentAgreementItem
} from './_store/enrollment-agreement-item';

const QuillEditor = loadable(() => import('components/QuillEditor'));

const mapStateToProps = (state: RootState) => ({
  activeSchool: userActiveSchoolSelector(state),
  enrollmentAgreementItems: enrollmentAgreementItemsSelector(state),
  phase: enrollmentAgreementItemsPhaseSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addEnrollmentAgreementItem: (enrollmentAgreementItemInfo: Partial<IEnrollmentAgreementItem>) =>
    dispatch(
      enrollmentAgreementItemsActions.addEnrollmentAgreementItem(enrollmentAgreementItemInfo)
    ),
  updateEnrollmentAgreementItem: (enrollmentAgreementItemInfo: Partial<IEnrollmentAgreementItem>) =>
    dispatch(
      enrollmentAgreementItemsActions.updateEnrollmentAgreementItem(enrollmentAgreementItemInfo)
    )
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TFormProps = PropsFromRedux & {
  actionType: TActionType;
  sideForm?: boolean;
  handleClose?: any;
};

const ItemForm: React.FC<TFormProps> = (props) => {
  const {
    actionType,
    sideForm,
    enrollmentAgreementItems,
    phase,
    activeSchool,
    handleClose,
    addEnrollmentAgreementItem,
    updateEnrollmentAgreementItem
  } = props;

  const { id, subaction, subid } = useParams();

  const intl = useTranslation();
  const { showSnackbar } = useSnackbar();

  const enrollmentAgreementItemInfo: IEnrollmentAgreementItem = enrollmentAgreementItems.find(
    (g: IEnrollmentAgreementItem) => g.id === parseInt(subid)
  );

  const [additionalInfo, setAdditionalInfo] = React.useState(
    (subaction === 'add' && '0') || enrollmentAgreementItemInfo?.additionalInfo || '0'
  );

  const initialFormValues: Partial<IEnrollmentAgreementItem> = {
    id: (subaction === 'add' && null) || enrollmentAgreementItemInfo?.id || null,
    enrollmentAgreementsId: parseInt(id),
    itemText: (subaction === 'add' && '') || enrollmentAgreementItemInfo?.itemText || '',
    additionalInfo:
      (subaction === 'add' && '0') || enrollmentAgreementItemInfo?.additionalInfo || '0'
  };
  const { handleSubmit, setFieldValue, values, isSubmitting, setSubmitting, status, setStatus } =
    useFormik({
      initialValues: initialFormValues,
      onSubmit: (values) => submitForm(values)
    });

  const submitForm = (values: Partial<IEnrollmentAgreementItem>) => {
    setStatus('submitted');

    setSubmitting(true);

    const maxItemNo = Math.max(...enrollmentAgreementItems.map((val) => val.itemNo), 0) + 1;

    if (actionType === 'add') {
      const newValue: Partial<IEnrollmentAgreementItem> = Object.assign(values, {
        school: `/api/schools/${activeSchool.id}`,
        itemNo: maxItemNo
      });

      addEnrollmentAgreementItem(newValue);
    } else {
      updateEnrollmentAgreementItem(values);
    }
  };

  const handleChangeAdditional = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAdditionalInfo(newAlignment);
    setFieldValue('additionalInfo', newAlignment);
  };

  React.useEffect(() => {
    setSubmitting(false);

    if (status === 'submitted' && phase === 'success') {
      showSnackbar({
        message: intl.translate({ id: 'app.saved' }),
        open: true
      });

      if (!sideForm) {
        setTimeout(() => {
          handleClose();
        }, 500);
      }
    }
  }, [status, phase, setSubmitting, handleClose]);

  return (
    <Box sx={{ display: 'flex' }}>
      <form
        style={{ width: '100%' }}
        className='form'
        noValidate={true}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <QuillEditor
              placeholder={intl.formatMessage({ id: 'enrollment.agreements.title' })}
              sx={{
                flexGrow: 1
              }}
              id='itemText'
              rows={5}
              value={values?.itemText}
              onChange={(e: any) => setFieldValue('itemText', e)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ToggleButtonGroup
              color='primary'
              value={additionalInfo}
              exclusive
              onChange={handleChangeAdditional}
              aria-label='Platform'
            >
              <ToggleButton value='0'>
                {intl.formatMessage({ id: 'enrollment.agreements.no_template' })}
              </ToggleButton>
              <ToggleButton value='feeInfo'>
                {intl.formatMessage({ id: 'enrollment.agreements.fee_info' })}
              </ToggleButton>
              <ToggleButton value='discountInfo'>
                {intl.formatMessage({ id: 'enrollment.agreements.discount_info' })}
              </ToggleButton>
              <ToggleButton value='installmentInfo'>
                {intl.formatMessage({ id: 'enrollment.agreements.installment_info' })}
              </ToggleButton>
              <ToggleButton value='invoiceInfo'>
                {intl.formatMessage({ id: 'enrollment.agreements.invoice_info' })}
              </ToggleButton>
              <ToggleButton value='scholarshipInfo'>
                {intl.formatMessage({ id: 'enrollment.agreements.scholarship_info' })}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <FormButtons
          saveButton={<SaveButton disabled={isSubmitting} mode={isSubmitting && 'saving'} />}
          cancelButton={<CancelButton onClick={handleClose} />}
        />
      </form>
    </Box>
  );
};

export default connector(ItemForm);
