import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction, TPhase } from 'store/store';
import { BANKS_API_URL, BANK_BRANCHES_API_URL, PAYMENT_METHODS_API_URL } from 'store/ApiUrls';

export interface IPaymentMethod {
  id?: number;
  paymentMethodName?: string;
  bank?: string;
  creditCard?: string;
  countryCode?: string;
  commissionRate?: number;
  paymentExpiry?: number;
}

export interface IBank {
  id?: number;
  bankName?: string;
  countryCode?: string;
}

export interface IBankBranch {
  id?: number;
  bankBranchCode?: string;
  branchName?: string;
  stateId?: number;
  cityId?: number;
  address?: string;
  phone1?: string;
  phone2?: string;
  fax?: string;
  email?: string;
  principal?: string;
  deputyPrincipal?: string;
  customerRepresentative?: string;
  bank?: IBank;
}

interface IBankState {
  banks: IBank[];
  bankBranches: IBankBranch[];
  paymentMethods: IPaymentMethod[];
  phase: TPhase;
}

type TActionAllState = IBankState & {
  bankId: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_BANKS: 'banks/PULL_BANKS',
  SET_BANKS: 'banks/SET_BANKS',
  PULL_BANK_BRANCHES: 'banks/PULL_BANK_BRANCHES',
  SET_BANK_BRANCHES: 'banks/SET_BANK_BRANCHES',
  PULL_PAYMENT_METHODS: 'banks/PULL_PAYMENT_METHODS',
  SET_PAYMENT_METHODS: 'banks/SET_PAYMENT_METHODS',
  SET_PHASE: 'banks/SET_PHASE'
};

export const initialState: IBankState = {
  banks: [],
  bankBranches: [],
  paymentMethods: [],
  phase: null
};

export const banksSelector = createSelector(
  (state: IBankState) => objectPath.get(state, ['banks', 'banks']),
  (banks: IBank[]) => banks
);

export const bankBranchesSelector = createSelector(
  (state: IBankState) => objectPath.get(state, ['banks', 'bankBranches']),
  (bankBranches: IBankBranch[]) => bankBranches
);

export const paymentMethodsSelector = createSelector(
  (state: IBankState) => objectPath.get(state, ['banks', 'paymentMethods']),
  (paymentMethods: IPaymentMethod[]) => paymentMethods
);

export const banksPhaseSelector = createSelector(
  (state: IBankState) => objectPath.get(state, ['banks', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'banks' },
  (state: IBankState = initialState, action: IAction<TActionAllState>): IBankState => {
    switch (action.type) {
      case actionTypes.SET_BANKS: {
        const { banks } = action.payload;
        return { ...state, banks };
      }
      case actionTypes.SET_BANK_BRANCHES: {
        const { bankBranches } = action.payload;
        return { ...state, bankBranches };
      }
      case actionTypes.SET_PAYMENT_METHODS: {
        const { paymentMethods } = action.payload;
        return { ...state, paymentMethods };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const banksActions = {
  pullBanks: (countryCode: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_BANKS,
    payload: { countryCode }
  }),
  setBanks: (banks: IBank[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANKS,
    payload: { banks }
  }),
  pullBankBranches: (bankId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_BANK_BRANCHES,
    payload: { bankId }
  }),
  setBankBranches: (bankBranches: IBankBranch[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANK_BRANCHES,
    payload: { bankBranches }
  }),
  pullPaymentMethods: (countryCode: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_PAYMENT_METHODS,
    payload: { countryCode }
  }),
  setPaymentMethods: (paymentMethods: IPaymentMethod[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PAYMENT_METHODS,
    payload: { paymentMethods }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_BANKS,
    function* pullBanksSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(banksActions.setPhase('loading'));

      const { countryCode } = payload;
      const url = `${BANKS_API_URL}.json?countryCode=${countryCode}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(banksActions.setPhase('error'));
        return;
      }

      yield put(banksActions.setBanks(response.data));
      yield put(banksActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.PULL_BANK_BRANCHES,
    function* pullBankBranchesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(banksActions.setPhase('loading'));

      const { bankId } = payload;
      const url = `${BANK_BRANCHES_API_URL}.json?bankId=${bankId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(banksActions.setPhase('error'));
        return;
      }

      yield put(banksActions.setBankBranches(response.data));
      yield put(banksActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.PULL_PAYMENT_METHODS,
    function* pullPaymentMethodsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(banksActions.setPhase('loading'));

      const { countryCode } = payload;
      const url = `${PAYMENT_METHODS_API_URL}.json?countryCode=${countryCode}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(banksActions.setPhase('error'));
        return;
      }

      yield put(banksActions.setPaymentMethods(response.data));
      yield put(banksActions.setPhase('success'));
    }
  );
}
