import React from 'react';
import loadable from '@loadable/component';

import courseVideoRoutes from './videos/routes';
import courseAssetRoutes from './assets/routes';
import feeSettingRoutes from './fee-setting/routes';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const routes = {
  path: 'courses',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [courseVideoRoutes, courseAssetRoutes, feeSettingRoutes]
};

export default routes;
