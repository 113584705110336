import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { BATCHES_API_URL, CLASS_BATCHES_API_URL } from 'store/ApiUrls';
import { IClass } from 'pages/classes/_store/types';

export interface IClassBatch {
  id?: number;
  classs?: IClass;
  batchId?: number;
  termId?: number;
  school?: number;
}

export interface IBatch {
  id?: number;
  title?: string;
  capacity?: number;
  calendar?: string;
  isIB?: boolean;
  cohort?: number;
  program?: number;
  courseId?: number;
  lmsIntegrationId?: number;
}

interface IBatchState {
  batches: IBatch[];
  classBatches: IClassBatch[];
  phase: string;
}

type TActionAllState = IBatchState & {
  id: number;
  courseId: number;
  batchId: number;
  batch: IBatch;
  batchInfo: Partial<IBatch>;
};

export const actionTypes = {
  PULL_BATCHES: 'batch/PULL_BATCHES',
  PULL_CLASS_BATCHES: 'batch/PULL_CLASS_BATCHES',
  SET_BATCHES: 'batch/SET_BATCHES',
  SET_CLASS_BATCHES: 'batch/SET_CLASS_BATCHES',
  SET_PHASE: 'batch/SET_PHASE'
};

export const initialState: IBatchState = {
  batches: [],
  classBatches: [],
  phase: null
};

export const batchSelector = createSelector(
  (state: IBatchState) => objectPath.get(state, ['batches', 'batches']),
  (batches: IBatch[]) => batches
);

export const classBatchSelector = createSelector(
  (state: IBatchState) => objectPath.get(state, ['batches', 'classBatches']),
  (classBatches: IClassBatch[]) => classBatches
);

export const batchPhaseSelector = createSelector(
  (state: IBatchState) => objectPath.get(state, ['batches', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'batches' },
  (state: IBatchState = initialState, action: IAction<TActionAllState>): IBatchState => {
    switch (action.type) {
      case actionTypes.SET_BATCHES: {
        const { batches } = action.payload;
        return { ...state, batches };
      }
      case actionTypes.SET_CLASS_BATCHES: {
        const { classBatches } = action.payload;
        return { ...state, classBatches };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const batchesActions = {
  pullBatches: (courseId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_BATCHES,
    payload: { courseId }
  }),
  pullClassBatches: (batchId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_CLASS_BATCHES,
    payload: { batchId }
  }),
  setBatches: (batches: IBatch[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BATCHES,
    payload: { batches }
  }),
  setClassBatches: (classBatches: IClassBatch[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_CLASS_BATCHES,
    payload: { classBatches }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_BATCHES,
    function* pullBatchesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(batchesActions.setPhase('loading'));

      const { courseId } = payload;

      const url = `${BATCHES_API_URL}.json?courseId=${courseId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(batchesActions.setPhase('error'));
        return;
      }

      yield put(batchesActions.setBatches(response.data));
      yield put(batchesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.PULL_CLASS_BATCHES,
    function* pullClassBatchesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(batchesActions.setPhase('loading'));

      const { batchId } = payload;

      const url = `${CLASS_BATCHES_API_URL}.json?batchId=${batchId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(batchesActions.setPhase('error'));
        return;
      }

      yield put(batchesActions.setClassBatches(response.data));
      yield put(batchesActions.setPhase('success'));
    }
  );
}
