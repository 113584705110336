import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { LAWYERS_API_URL } from 'store/ApiUrls';

export interface ILawyer {
  id?: number;
  lawyerName?: string;
  address?: string;
  phone1?: string;
  phone2?: string;
  mobilePhone?: string;
  fax?: string;
  email?: string;
  school?: ISchool;
}

interface ILawyerState {
  lawyers: ILawyer[];
  phase: string;
}

type TActionAllState = ILawyerState & {
  id: number;
  activeSchool: ISchool;
  lawyer: ILawyer;
  lawyerInfo: Partial<ILawyer>;
};

export const actionTypes = {
  PULL_LAWYERS: 'lawyers/PULL_LAWYERS',
  SET_LAWYERS: 'lawyers/SET_LAWYERS',
  ADD_LAWYER: 'lawyers/ADD_LAWYER',
  UPDATE_LAWYER: 'lawyers/UPDATE_LAWYER',
  DELETE_LAWYER: 'lawyers/DELETE_LAWYER',
  REMOVE_LAWYER: 'lawyers/REMOVE_LAWYER',
  SET_LAWYER: 'lawyers/SET_LAWYER',
  SET_PHASE: 'lawyers/SET_PHASE'
};

export const initialState: ILawyerState = {
  lawyers: [],
  phase: null
};

export const lawyersSelector = createSelector(
  (state: ILawyerState) => objectPath.get(state, ['lawyers', 'lawyers']),
  (lawyers: ILawyer[]) => lawyers
);
export const lawyersPhaseSelector = createSelector(
  (state: ILawyerState) => objectPath.get(state, ['lawyers', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'lawyers' },
  (state: ILawyerState = initialState, action: IAction<TActionAllState>): ILawyerState => {
    switch (action.type) {
      case actionTypes.SET_LAWYERS: {
        const { lawyers } = action.payload;
        return { ...state, lawyers };
      }
      case actionTypes.SET_LAWYER: {
        const { lawyer } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.lawyers.findIndex((d) => d.id === lawyer.id);
          if (index > -1) {
            draftState.lawyers[index] = lawyer;
          } else {
            draftState.lawyers.unshift(lawyer);
          }
        });
      }
      case actionTypes.REMOVE_LAWYER: {
        const { id } = action.payload;
        const lawyers = { ...state }.lawyers.filter((d) => d.id !== id);
        return { ...state, lawyers };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const lawyersActions = {
  pullLawyers: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_LAWYERS,
    payload: { activeSchool }
  }),
  setLawyers: (lawyers: ILawyer[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_LAWYERS,
    payload: { lawyers }
  }),
  addLawyer: (lawyerInfo: Partial<ILawyer>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_LAWYER,
    payload: { lawyerInfo }
  }),
  updateLawyer: (lawyerInfo: Partial<ILawyer>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_LAWYER,
    payload: { lawyerInfo }
  }),
  deleteLawyer: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_LAWYER,
    payload: { id }
  }),
  removeLawyer: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_LAWYER,
    payload: { id }
  }),
  setLawyer: (lawyer: ILawyer): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_LAWYER,
    payload: { lawyer }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_LAWYERS,
    function* pullLawyersSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(lawyersActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${LAWYERS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(lawyersActions.setPhase('error'));
        return;
      }

      yield put(lawyersActions.setLawyers(response.data));
      yield put(lawyersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_LAWYER,
    function* addLawyerSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(lawyersActions.setPhase('adding'));

      const { lawyerInfo } = payload;
      const response = yield axios.post(`${LAWYERS_API_URL}`, lawyerInfo);

      if (response.status !== 200) {
        yield put(lawyersActions.setPhase('error'));
        return;
      }

      yield put(lawyersActions.setLawyer(response.data));
      yield put(lawyersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_LAWYER,
    function* updateLawyerSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(lawyersActions.setPhase('updating'));

      const { lawyerInfo } = payload;
      const response = yield axios.patch(`${LAWYERS_API_URL}/${lawyerInfo.id}`, lawyerInfo);

      if (response.status !== 200) {
        yield put(lawyersActions.setPhase('error'));
        return;
      }

      yield put(lawyersActions.setLawyer(response.data));
      yield put(lawyersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_LAWYER,
    function* deleteAccountCodeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(lawyersActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${LAWYERS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(lawyersActions.setPhase('error'));
        return;
      }

      yield put(lawyersActions.removeLawyer(id));
      yield put(lawyersActions.setPhase('success'));
    }
  );
}
