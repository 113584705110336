import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction, TPhase } from 'store/store';
import { BANK_ACCOUNTS_API_URL } from 'store/ApiUrls';
import { IBankBranch } from 'pages/bank/_store/bank';

export interface IBankAccount {
  id?: number;
  accountName?: string;
  accountNumber?: string;
  iban?: string;
  currency?: string;
  bankId?: number;
  bankBranch?: IBankBranch;
  bankBranchId?: number;
  school?: number;
}

interface IBankAccountState {
  bankAccounts: IBankAccount[];
  phase: TPhase;
}

type TActionAllState = IBankAccountState & {
  id: number;
  activeSchool: ISchool;
  bankAccount: IBankAccount;
  bankAccountInfo: Partial<IBankAccount>;
};

export const actionTypes = {
  PULL_BANK_ACCOUNTS: 'bankAccounts/PULL_BANK_ACCOUNTS',
  SET_BANK_ACCOUNTS: 'bankAccounts/SET_BANK_ACCOUNTS',
  ADD_BANK_ACCOUNT: 'bankAccounts/ADD_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT: 'bankAccounts/UPDATE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT: 'bankAccounts/DELETE_BANK_ACCOUNT',
  REMOVE_BANK_ACCOUNT: 'bankAccounts/REMOVE_BANK_ACCOUNT',
  SET_BANK_ACCOUNT: 'bankAccounts/SET_BANK_ACCOUNT',
  SET_PHASE: 'bankAccounts/SET_PHASE'
};

export const initialState: IBankAccountState = {
  bankAccounts: [],
  phase: null
};

export const bankAccountsSelector = createSelector(
  (state: IBankAccountState) => objectPath.get(state, ['bankAccounts', 'bankAccounts']),
  (bankAccounts: IBankAccount[]) => bankAccounts
);

export const bankAccountsPhaseSelector = createSelector(
  (state: IBankAccountState) => objectPath.get(state, ['bankAccounts', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'bankAccounts' },
  (
    state: IBankAccountState = initialState,
    action: IAction<TActionAllState>
  ): IBankAccountState => {
    switch (action.type) {
      case actionTypes.SET_BANK_ACCOUNTS: {
        const { bankAccounts } = action.payload;
        return { ...state, bankAccounts };
      }
      case actionTypes.SET_BANK_ACCOUNT: {
        const { bankAccount } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.bankAccounts.findIndex((d) => d.id === bankAccount.id);
          if (index > -1) {
            draftState.bankAccounts[index] = bankAccount;
          } else {
            draftState.bankAccounts.unshift(bankAccount);
          }
        });
      }
      case actionTypes.REMOVE_BANK_ACCOUNT: {
        const { id } = action.payload;
        const bankAccounts = { ...state }.bankAccounts.filter((d) => d.id !== id);
        return { ...state, bankAccounts };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const bankAccountsActions = {
  pullBankAccounts: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_BANK_ACCOUNTS,
    payload: { activeSchool }
  }),
  setBankAccounts: (bankAccounts: IBankAccount[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANK_ACCOUNTS,
    payload: { bankAccounts }
  }),
  addBankAccount: (bankAccountInfo: Partial<IBankAccount>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_BANK_ACCOUNT,
    payload: { bankAccountInfo }
  }),
  updateBankAccount: (
    bankAccountInfo: Partial<IBankAccount>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_BANK_ACCOUNT,
    payload: { bankAccountInfo }
  }),
  deleteBankAccount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_BANK_ACCOUNT,
    payload: { id }
  }),
  removeBankAccount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_BANK_ACCOUNT,
    payload: { id }
  }),
  setBankAccount: (bankAccount: IBankAccount): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_BANK_ACCOUNT,
    payload: { bankAccount }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_BANK_ACCOUNTS,
    function* pullBankAccountsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${BANK_ACCOUNTS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(bankAccountsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountsActions.setBankAccounts(response.data));
      yield put(bankAccountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_BANK_ACCOUNT,
    function* addBankAccountsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountsActions.setPhase('adding'));

      const { bankAccountInfo } = payload;
      const response = yield axios.post(`${BANK_ACCOUNTS_API_URL}`, {
        id: bankAccountInfo.id,
        accountName: bankAccountInfo.accountName,
        accountNumber: bankAccountInfo.accountNumber,
        iban: bankAccountInfo.iban,
        currency: bankAccountInfo.currency,
        bankId: bankAccountInfo.bankId,
        bankBranch: `/api/bank_branches/${bankAccountInfo.bankBranchId}`,
        school: bankAccountInfo.school
      });

      if (response.status !== 200) {
        yield put(bankAccountsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountsActions.setBankAccount(response.data));
      yield put(bankAccountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_BANK_ACCOUNT,
    function* updateBankAccountsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountsActions.setPhase('updating'));

      const { bankAccountInfo } = payload;
      const response = yield axios.patch(`${BANK_ACCOUNTS_API_URL}/${bankAccountInfo.id}`, {
        accountName: bankAccountInfo.accountName,
        accountNumber: bankAccountInfo.accountNumber,
        iban: bankAccountInfo.iban,
        currency: bankAccountInfo.currency,
        bankId: bankAccountInfo.bankId,
        bankBranch: `/api/bank_branches/${bankAccountInfo.bankBranchId}`
      });

      if (response.status !== 200) {
        yield put(bankAccountsActions.setPhase('error'));
        return;
      }

      yield put(bankAccountsActions.setBankAccount(response.data));
      yield put(bankAccountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_BANK_ACCOUNT,
    function* deleteBankAccountsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(bankAccountsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${BANK_ACCOUNTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(bankAccountsActions.setPhase('error'));
        return;
      }
      yield put(bankAccountsActions.removeBankAccount(id));
      yield put(bankAccountsActions.setPhase('success'));
    }
  );
}
