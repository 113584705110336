import React from 'react';
import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const DiscountList = Loadable(lazy(() => import('./DiscountList')));
const DiscountNew = Loadable(lazy(() => import('./DiscountNew')));
const DiscountCriteriaNew = Loadable(lazy(() => import('./DiscountCriteriaNew')));
const routes = {
  path: 'discounts',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <DiscountList />
    },
    {
      path: 'new',
      element: <DiscountNew />
    },
    {
      path: 'criteria/new',
      element: <DiscountCriteriaNew />
    },
    {
      path: ':id',
      element: <DiscountList />
    },
    {
      path: ':id/:action',
      element: <DiscountList />
    },
    {
      path: 'criteria/:subid',
      element: <DiscountList />
    },
    {
      path: 'criteria/:subid/:subaction',
      element: <DiscountList />
    }
  ]
};

export default routes;
