export const actionTypes = {
  PULL_MAILS_FROM_SERVER: 'mails/PULL_MAILS_FROM_SERVER',
  UPDATE_MAILS_IN_STORE: 'mails/UPDATE_MAILS_IN_STORE',
  UPDATE_MAIL_IN_SERVER: 'mails/UPDATE_MAIL_IN_SERVER',
  UPDATE_MAIL_IN_STORE: 'mails/UPDATE_MAIL_IN_STORE',
  SEND_MAIL: 'mails/SEND_MAIL',
  UPDATE_DRAFT_THROTTLE: 'mails/UPDATE_DRAFT_THROTTLE',
  UPDATE_DRAFT_IN_STORE: 'mails/UPDATE_DRAFT_IN_STORE',
  DELETE_DRAFT_IN_STORE: 'mails/DELETE_DRAFT_IN_STORE',
  UPDATE_COMPOSE: 'mails/UPDATE_COMPOSE',
  UPDATE_SIDEBAR: 'mails/UPDATE_SIDEBAR',
  SET_MAIL_PAGE: 'mails/SET_MAIL_PAGE',
  UPDATE_PHASE: 'mails/UPDATE_PHASE'
};
