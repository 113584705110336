import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { STUDENT_DISCOUNTS_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { IDiscount } from 'pages/discount/_store/data-types';
import { ISchool } from 'pages/organization/organization-types';
import moment from 'moment';

interface IScholarship {
  id?: number;
  title?: string;
  active?: string;
}

interface IStudentScholarship {
  id?: number;
  studentId?: number;
  scholarship?: IScholarship;
  scholarshipRate?: number;
  scholarshipAmount?: number;
  description?: string;
  school: ISchool;
}

export interface IStudentDiscount {
  id: number;
  studentId: number;
  discount: IDiscount;
  scholarship?: IStudentScholarship;
  discountType?: string;
  discountAmount?: number;
  isScholarship?: string;
  description?: string;
  document?: string;
  courseId: number;
  addedBy: number;
  addedAt: string;
}

interface IStudentDiscountState {
  studentDiscounts: IStudentDiscount[];
  phase: string;
}

type TActionAllState = IStudentDiscountState & {
  id: number;
  studentId: number;
  studentDiscount: IStudentDiscount;
  studentDiscountInfo: Partial<IStudentDiscount>;
  studentDiscountInfos: Partial<IStudentDiscount[]>;
};

export const actionTypes = {
  PULL_STUDENT_DISCOUNTS: 'studentDiscounts/PULL_STUDENT_DISCOUNTS',
  SET_STUDENT_DISCOUNTS: 'studentDiscounts/SET_STUDENT_DISCOUNTS',
  ADD_STUDENT_DISCOUNT: 'studentDiscounts/ADD_STUDENT_DISCOUNT',
  ADD_STUDENT_BULK_DISCOUNT: 'studentDiscounts/ADD_STUDENT_BULK_DISCOUNT',
  UPDATE_STUDENT_DISCOUNT: 'studentDiscounts/UPDATE_STUDENT_DISCOUNT',
  DELETE_STUDENT_DISCOUNT: 'studentDiscounts/DELETE_STUDENT_DISCOUNT',
  REMOVE_STUDENT_DISCOUNT: 'studentDiscounts/REMOVE_STUDENT_DISCOUNT',
  SET_STUDENT_DISCOUNT: 'studentDiscounts/SET_STUDENT_DISCOUNT',
  SET_PHASE: 'studentDiscounts/SET_PHASE'
};

export const initialState: IStudentDiscountState = {
  studentDiscounts: [],
  phase: null
};

export const studentDiscountsSelector = createSelector(
  (state: IStudentDiscountState) =>
    objectPath.get(state, ['students', 'studentDiscounts', 'studentDiscounts']),
  (studentDiscounts: IStudentDiscount[]) => studentDiscounts
);

export const studentDiscountsPhaseSelector = createSelector(
  (state: IStudentDiscountState) =>
    objectPath.get(state, ['students', 'studentDiscounts', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentDiscounts' },
  (
    state: IStudentDiscountState = initialState,
    action: IAction<TActionAllState>
  ): IStudentDiscountState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_DISCOUNTS: {
        const { studentDiscounts } = action.payload;
        return { ...state, studentDiscounts };
      }
      case actionTypes.SET_STUDENT_DISCOUNT: {
        const { studentDiscount } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentDiscounts.findIndex((d) => d.id === studentDiscount.id);
          if (index > -1) {
            draftState.studentDiscounts[index] = studentDiscount;
          } else {
            draftState.studentDiscounts.unshift(studentDiscount);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_DISCOUNT: {
        const { id } = action.payload;
        const studentDiscounts = { ...state }.studentDiscounts.filter((d) => d.id !== id);
        return { ...state, studentDiscounts };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentDiscountsActions = {
  pullStudentDiscounts: (studentId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_DISCOUNTS,
    payload: { studentId }
  }),
  setStudentDiscounts: (
    studentDiscounts: IStudentDiscount[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_DISCOUNTS,
    payload: { studentDiscounts }
  }),
  addStudentDiscount: (
    studentDiscountInfo: Partial<IStudentDiscount>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_DISCOUNT,
    payload: { studentDiscountInfo }
  }),
  addStudentBulkDiscount: (
    studentDiscountInfos: Partial<IStudentDiscount[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_BULK_DISCOUNT,
    payload: { studentDiscountInfos }
  }),
  updateStudentDiscount: (
    studentDiscountInfo: Partial<IStudentDiscount>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_DISCOUNT,
    payload: { studentDiscountInfo }
  }),
  deleteStudentDiscount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_DISCOUNT,
    payload: { id }
  }),
  removeStudentDiscount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_DISCOUNT,
    payload: { id }
  }),
  setStudentDiscount: (studentDiscount: IStudentDiscount): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_DISCOUNT,
    payload: { studentDiscount }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_DISCOUNTS,
    function* pullStudentDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDiscountsActions.setPhase('loading'));

      const { studentId } = payload;
      const url = `${STUDENT_DISCOUNTS_API_URL}.json?studentId=${studentId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentDiscountsActions.setPhase('error'));
        return;
      }

      yield put(studentDiscountsActions.setStudentDiscounts(response.data));
      yield put(studentDiscountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_DISCOUNT,
    function* addStudentDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDiscountsActions.setPhase('adding'));

      const { studentDiscountInfo } = payload;
      const response = yield axios.post(`${STUDENT_DISCOUNTS_API_URL}`, studentDiscountInfo);

      if (response.status !== 200) {
        yield put(studentDiscountsActions.setPhase('error'));
        return;
      }

      yield put(studentDiscountsActions.setStudentDiscount(response.data));
      yield put(studentDiscountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_BULK_DISCOUNT,
    function* addStudentBulkDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDiscountsActions.setPhase('bulk-adding-loading'));
      const { studentDiscountInfos } = payload;

      try {
        for (let i = 0; i < studentDiscountInfos.length; i++) {
          const response = yield axios.post(`${STUDENT_DISCOUNTS_API_URL}`, {
            studentId: studentDiscountInfos[i].studentId,
            discount:
              studentDiscountInfos[i].isScholarship === '1'
                ? null
                : `/api/discounts/${studentDiscountInfos[i]?.discount?.id}`,
            scholarship:
              studentDiscountInfos[i].isScholarship === '1'
                ? `/api/student_scholarships/${studentDiscountInfos[i]?.scholarship?.id}`
                : null,
            discountType: studentDiscountInfos[i].discountType,
            discountAmount: studentDiscountInfos[i].discountAmount.toString(),
            isScholarship: studentDiscountInfos[i].isScholarship,
            courseId: studentDiscountInfos[i].courseId,
            addedBy: studentDiscountInfos[i].addedBy,
            addedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            description: '',
            document: ''
          });

          yield put(studentDiscountsActions.setStudentDiscount(response.data));
        }

        yield put(studentDiscountsActions.setPhase('bulk-adding-success'));
      } catch {
        yield put(studentDiscountsActions.setPhase('bulk-adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_DISCOUNT,
    function* updateStudentDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDiscountsActions.setPhase('adding'));

      const { studentDiscountInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_DISCOUNTS_API_URL}/${studentDiscountInfo.id}`,
        studentDiscountInfo
      );

      if (response.status !== 200) {
        yield put(studentDiscountsActions.setPhase('error'));
        return;
      }

      yield put(studentDiscountsActions.setStudentDiscount(response.data));
      yield put(studentDiscountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_DISCOUNT,
    function* deleteStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDiscountsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_DISCOUNTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentDiscountsActions.setPhase('deleting-error'));
        return;
      }

      yield put(studentDiscountsActions.removeStudentDiscount(id));
      yield put(studentDiscountsActions.setPhase('deleting-success'));
    }
  );
}
