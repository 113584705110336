import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { STUDENT_COURSES_API_URL } from 'store/ApiUrls';
import produce from 'immer';

export interface IStudentCourse {
  id: number;
  studentId: number;
  courseId?: number;
  numberOfClasses?: number;
  enrollmentDate?: string;
  isActive?: string;
}

interface IStudentCourseState {
  studentCourses: IStudentCourse[];
  phase: string;
}

type TActionAllState = IStudentCourseState & {
  id: number;
  studentId: number;
  studentCourse: IStudentCourse;
  studentCourseInfo: Partial<IStudentCourse>;
};

export const actionTypes = {
  PULL_STUDENT_COURSES: 'studentCourses/PULL_STUDENT_COURSES',
  SET_STUDENT_COURSES: 'studentCourses/SET_STUDENT_COURSES',
  ADD_STUDENT_COURSE: 'studentCourses/ADD_STUDENT_COURSE',
  UPDATE_STUDENT_COURSE: 'studentCourses/UPDATE_STUDENT_COURSE',
  DELETE_STUDENT_COURSE: 'studentCourses/DELETE_STUDENT_COURSE',
  REMOVE_STUDENT_COURSE: 'studentCourses/REMOVE_STUDENT_COURSE',
  SET_STUDENT_COURSE: 'studentCourses/SET_STUDENT_COURSE',
  SET_PHASE: 'studentCourses/SET_PHASE'
};

export const initialState: IStudentCourseState = {
  studentCourses: [],
  phase: null
};

export const studentCoursesSelector = createSelector(
  (state: IStudentCourseState) =>
    objectPath.get(state, ['students', 'studentCourses', 'studentCourses']),
  (studentCourses: IStudentCourse[]) => studentCourses
);

export const studentCoursesPhaseSelector = createSelector(
  (state: IStudentCourseState) => objectPath.get(state, ['students', 'studentCourses', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentCourses' },
  (
    state: IStudentCourseState = initialState,
    action: IAction<TActionAllState>
  ): IStudentCourseState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_COURSES: {
        const { studentCourses } = action.payload;
        return { ...state, studentCourses };
      }
      case actionTypes.SET_STUDENT_COURSE: {
        const { studentCourse } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentCourses.findIndex((d) => d.id === studentCourse.id);
          if (index > -1) {
            draftState.studentCourses[index] = studentCourse;
          } else {
            draftState.studentCourses.unshift(studentCourse);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_COURSE: {
        const { id } = action.payload;
        const studentCourses = { ...state }.studentCourses.filter((d) => d.id !== id);
        return { ...state, studentCourses };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentCoursesActions = {
  pullStudentCourses: (studentId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_COURSES,
    payload: { studentId }
  }),
  setStudentCourses: (studentCourses: IStudentCourse[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_COURSES,
    payload: { studentCourses }
  }),
  addStudentCourse: (
    studentCourseInfo: Partial<IStudentCourse>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_COURSE,
    payload: { studentCourseInfo }
  }),
  updateStudentCourse: (
    studentCourseInfo: Partial<IStudentCourse>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_COURSE,
    payload: { studentCourseInfo }
  }),
  deleteStudentCourse: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_COURSE,
    payload: { id }
  }),
  removeStudentCourse: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_COURSE,
    payload: { id }
  }),
  setStudentCourse: (studentCourse: IStudentCourse): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_COURSE,
    payload: { studentCourse }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_COURSES,
    function* pullStudentCoursesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentCoursesActions.setPhase('loading'));

      const { studentId } = payload;
      const url = `${STUDENT_COURSES_API_URL}.json?studentId=${studentId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentCoursesActions.setPhase('error'));
        return;
      }

      yield put(studentCoursesActions.setStudentCourses(response.data));
      yield put(studentCoursesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_COURSE,
    function* addStudentCoursesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentCoursesActions.setPhase('adding'));

      const { studentCourseInfo } = payload;
      const response = yield axios.post(`${STUDENT_COURSES_API_URL}`, studentCourseInfo);

      if (response.status !== 201) {
        yield put(studentCoursesActions.setPhase('adding-error'));
        return;
      }

      yield put(studentCoursesActions.setStudentCourse(response.data));
      yield put(studentCoursesActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_COURSE,
    function* updateStudentCourseSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentCoursesActions.setPhase('adding'));

      const { studentCourseInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_COURSES_API_URL}/${studentCourseInfo.id}`,
        studentCourseInfo
      );

      if (response.status !== 200) {
        yield put(studentCoursesActions.setPhase('error'));
        return;
      }

      yield put(studentCoursesActions.setStudentCourse(response.data));
      yield put(studentCoursesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_COURSE,
    function* deleteStudentCourseSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentCoursesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_COURSES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentCoursesActions.setPhase('error'));
        return;
      }

      yield put(studentCoursesActions.removeStudentCourse(id));
      yield put(studentCoursesActions.setPhase('success'));
    }
  );
}
