import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { SCHOLARSHIP_DEFINITIONS_API_URL } from 'store/ApiUrls';

export interface IScholarshipDefinition {
  id: number;
  title: string;
  active: string;
}

interface IScholarshipDefinitionState {
  scholarshipDefinitions: IScholarshipDefinition[];
  phase: string;
}

type TActionAllState = IScholarshipDefinitionState & {
  id: number;
  scholarshipDefinition: IScholarshipDefinition;
  scholarshipDefinitionInfo: Partial<IScholarshipDefinition>;
};

export const actionTypes = {
  PULL_SCHOLARSHIP_DEFINITIONS: 'scholarshipDefinitions/PULL_SCHOLARSHIP_DEFINITIONS',
  SET_SCHOLARSHIP_DEFINITIONS: 'scholarshipDefinitions/SET_SCHOLARSHIP_DEFINITIONS',
  ADD_SCHOLARSHIP_DEFINITION: 'scholarshipDefinitions/ADD_SCHOLARSHIP_DEFINITION',
  UPDATE_SCHOLARSHIP_DEFINITION: 'scholarshipDefinitions/UPDATE_SCHOLARSHIP_DEFINITION',
  DELETE_SCHOLARSHIP_DEFINITION: 'scholarshipDefinitions/DELETE_SCHOLARSHIP_DEFINITION',
  REMOVE_SCHOLARSHIP_DEFINITION: 'scholarshipDefinitions/REMOVE_SCHOLARSHIP_DEFINITION',
  SET_SCHOLARSHIP_DEFINITION: 'scholarshipDefinitions/SET_SCHOLARSHIP_DEFINITION',
  SET_PHASE: 'scholarshipDefinitions/SET_PHASE'
};

export const initialState: IScholarshipDefinitionState = {
  scholarshipDefinitions: [],
  phase: null
};

export const scholarshipDefinitionsSelector = createSelector(
  (state: IScholarshipDefinitionState) =>
    objectPath.get(state, ['scholarship', 'definitions', 'scholarshipDefinitions']),
  (scholarshipDefinitions: IScholarshipDefinition[]) => scholarshipDefinitions
);
export const scholarshipDefinitionsPhaseSelector = createSelector(
  (state: IScholarshipDefinitionState) =>
    objectPath.get(state, ['scholarship', 'definitions', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'scholarshipDefinitions' },
  (
    state: IScholarshipDefinitionState = initialState,
    action: IAction<TActionAllState>
  ): IScholarshipDefinitionState => {
    switch (action.type) {
      case actionTypes.SET_SCHOLARSHIP_DEFINITIONS: {
        const { scholarshipDefinitions } = action.payload;
        return { ...state, scholarshipDefinitions };
      }
      case actionTypes.SET_SCHOLARSHIP_DEFINITION: {
        const { scholarshipDefinition } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.scholarshipDefinitions.findIndex(
            (d) => d.id === scholarshipDefinition.id
          );
          if (index > -1) {
            draftState.scholarshipDefinitions[index] = scholarshipDefinition;
          } else {
            draftState.scholarshipDefinitions.unshift(scholarshipDefinition);
          }
        });
      }
      case actionTypes.REMOVE_SCHOLARSHIP_DEFINITION: {
        const { id } = action.payload;
        const scholarshipDefinitions = { ...state }.scholarshipDefinitions.filter(
          (d) => d.id !== id
        );
        return { ...state, scholarshipDefinitions };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const scholarshipDefinitionsActions = {
  pullScholarshipDefinitions: () => ({
    type: actionTypes.PULL_SCHOLARSHIP_DEFINITIONS
  }),
  setScholarshipDefinitions: (
    scholarshipDefinitions: IScholarshipDefinition[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SCHOLARSHIP_DEFINITIONS,
    payload: { scholarshipDefinitions }
  }),
  addScholarshipDefinition: (
    scholarshipDefinitionInfo: Partial<IScholarshipDefinition>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_SCHOLARSHIP_DEFINITION,
    payload: { scholarshipDefinitionInfo }
  }),
  updateScholarshipDefinition: (
    scholarshipDefinitionInfo: Partial<IScholarshipDefinition>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_SCHOLARSHIP_DEFINITION,
    payload: { scholarshipDefinitionInfo }
  }),
  deleteScholarshipDefinition: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_SCHOLARSHIP_DEFINITION,
    payload: { id }
  }),
  removeScholarshipDefinition: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_SCHOLARSHIP_DEFINITION,
    payload: { id }
  }),
  setScholarshipDefinition: (
    scholarshipDefinition: IScholarshipDefinition
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SCHOLARSHIP_DEFINITION,
    payload: { scholarshipDefinition }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_SCHOLARSHIP_DEFINITIONS,
    function* pullScholarshipDefinitionsSaga() {
      yield put(scholarshipDefinitionsActions.setPhase('loading'));

      const url = `${SCHOLARSHIP_DEFINITIONS_API_URL}.json?pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(scholarshipDefinitionsActions.setPhase('error'));
        return;
      }

      yield put(scholarshipDefinitionsActions.setScholarshipDefinitions(response.data));
      yield put(scholarshipDefinitionsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_SCHOLARSHIP_DEFINITION,
    function* addScholarshipDefinitionSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scholarshipDefinitionsActions.setPhase('adding'));

      const { scholarshipDefinitionInfo } = payload;
      const response = yield axios.post(
        `${SCHOLARSHIP_DEFINITIONS_API_URL}`,
        scholarshipDefinitionInfo
      );

      if (response.status !== 200) {
        yield put(scholarshipDefinitionsActions.setPhase('error'));
        return;
      }

      yield put(scholarshipDefinitionsActions.setScholarshipDefinition(response.data));
      yield put(scholarshipDefinitionsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_SCHOLARSHIP_DEFINITION,
    function* updateScholarshipDefinitionSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scholarshipDefinitionsActions.setPhase('updating'));

      const { scholarshipDefinitionInfo } = payload;
      const response = yield axios.patch(
        `${SCHOLARSHIP_DEFINITIONS_API_URL}/${scholarshipDefinitionInfo.id}`,
        scholarshipDefinitionInfo
      );

      if (response.status !== 200) {
        yield put(scholarshipDefinitionsActions.setPhase('error'));
        return;
      }

      yield put(scholarshipDefinitionsActions.setScholarshipDefinition(response.data));
      yield put(scholarshipDefinitionsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.REMOVE_SCHOLARSHIP_DEFINITION,
    function* deleteScholarshipDefinitionSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scholarshipDefinitionsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${SCHOLARSHIP_DEFINITIONS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(scholarshipDefinitionsActions.setPhase('error'));
        return;
      }

      yield put(scholarshipDefinitionsActions.removeScholarshipDefinition(id));
      yield put(scholarshipDefinitionsActions.setPhase('success'));
    }
  );
}
