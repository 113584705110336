//import { combineReducers } from 'redux';
import * as guardians from 'pages/guardians/guardian/_store/guardian';

// import * as parentStudents from 'pages/students/parent/_store/students';

// export const parentsSagas = [parentStudents.saga(), studentTags.saga()];
// export const studentsReducer = combineReducers({
//   parent: parentStudents.reducer,
//   tags: studentTags.reducer
// });

export const guardiansSagas = [guardians.saga()];
export const guardianReducer = guardians.reducer;

export const parentsSagas = null;
