import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import produce from 'immer';
import { BATCH_STUDENTS_API_URL } from 'store/ApiUrls';
import { IBatch } from 'pages/batch/_store/batch';

export interface IStudentBatch {
  id: number;
  batch: IBatch;
  studentId: number;
  school: number;
}

interface IStudentBatchState {
  studentBatches: IStudentBatch[];
  phase: string;
}

type TActionAllState = IStudentBatchState & {
  id: number;
  studentId: number;
  studentBatch: IStudentBatch;
  studentBatchInfo: Partial<IStudentBatch>;
};

export const actionTypes = {
  PULL_STUDENT_BATCHES: 'studentBatches/PULL_STUDENT_BATCHES',
  SET_STUDENT_BATCHES: 'studentBatches/SET_STUDENT_BATCHES',
  ADD_STUDENT_BATCH: 'studentBatches/ADD_STUDENT_BATCH',
  UPDATE_STUDENT_BATCH: 'studentBatches/UPDATE_STUDENT_BATCH',
  DELETE_STUDENT_BATCH: 'studentBatches/DELETE_STUDENT_BATCH',
  REMOVE_STUDENT_BATCH: 'studentBatches/REMOVE_STUDENT_BATCH',
  SET_STUDENT_BATCH: 'studentBatches/SET_STUDENT_BATCH',
  SET_PHASE: 'studentBatches/SET_PHASE'
};

export const initialState: IStudentBatchState = {
  studentBatches: [],
  phase: null
};

export const studentBatchesSelector = createSelector(
  (state: IStudentBatchState) =>
    objectPath.get(state, ['students', 'studentBatches', 'studentBatches']),
  (studentBatches: IStudentBatch[]) => studentBatches
);

export const studentBatchesPhaseSelector = createSelector(
  (state: IStudentBatchState) => objectPath.get(state, ['students', 'studentBatches', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentBatches' },
  (
    state: IStudentBatchState = initialState,
    action: IAction<TActionAllState>
  ): IStudentBatchState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_BATCHES: {
        const { studentBatches } = action.payload;
        return { ...state, studentBatches };
      }
      case actionTypes.SET_STUDENT_BATCH: {
        const { studentBatch } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentBatches.findIndex((d) => d.id === studentBatch.id);
          if (index > -1) {
            draftState.studentBatches[index] = studentBatch;
          } else {
            draftState.studentBatches.unshift(studentBatch);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_BATCH: {
        const { id } = action.payload;
        const studentBatches = { ...state }.studentBatches.filter((d) => d.id !== id);
        return { ...state, studentBatches };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentBatchesActions = {
  pullStudentBatches: (studentId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_BATCHES,
    payload: { studentId }
  }),
  setStudentBatches: (studentBatches: IStudentBatch[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BATCHES,
    payload: { studentBatches }
  }),
  addStudentBatch: (
    studentBatchInfo: Partial<IStudentBatch>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_BATCH,
    payload: { studentBatchInfo }
  }),
  updateStudentBatch: (
    studentBatchInfo: Partial<IStudentBatch>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_BATCH,
    payload: { studentBatchInfo }
  }),
  deleteStudentBatch: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_BATCH,
    payload: { id }
  }),
  removeStudentBatch: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_BATCH,
    payload: { id }
  }),
  setStudentBatch: (studentBatch: IStudentBatch): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BATCH,
    payload: { studentBatch }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_BATCHES,
    function* pullStudentBatchsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBatchesActions.setPhase('loading'));

      const { studentId } = payload;
      const url = `${BATCH_STUDENTS_API_URL}.json?studentId=${studentId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentBatchesActions.setPhase('error'));
        return;
      }

      yield put(studentBatchesActions.setStudentBatches(response.data));
      yield put(studentBatchesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_BATCH,
    function* addStudentBatchSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBatchesActions.setPhase('adding'));

      const { studentBatchInfo } = payload;
      const response = yield axios.post(`${BATCH_STUDENTS_API_URL}`, studentBatchInfo);

      if (response.status !== 201) {
        yield put(studentBatchesActions.setPhase('error'));
        return;
      }

      yield put(studentBatchesActions.setStudentBatch(response.data));
      yield put(studentBatchesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_BATCH,
    function* updateStudentBatchSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBatchesActions.setPhase('adding'));

      const { studentBatchInfo } = payload;
      const response = yield axios.patch(
        `${BATCH_STUDENTS_API_URL}/${studentBatchInfo.id}`,
        studentBatchInfo
      );

      if (response.status !== 200) {
        yield put(studentBatchesActions.setPhase('error'));
        return;
      }

      yield put(studentBatchesActions.setStudentBatch(response.data));
      yield put(studentBatchesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_BATCH,
    function* deleteStudentBatchSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBatchesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${BATCH_STUDENTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentBatchesActions.setPhase('error'));
        return;
      }

      yield put(studentBatchesActions.removeStudentBatch(id));
      yield put(studentBatchesActions.setPhase('success'));
    }
  );
}
