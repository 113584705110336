import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { MAIL_TEMPLATES_API_URL } from 'store/ApiUrls';

export interface IMailTemplate {
  id: number;
  title: string;
  subject: string;
  content: string;
  active: string;
  school: ISchool;
}
interface IMailTemplateState {
  mailTemplates: IMailTemplate[];
  phase: string;
}
type TActionAllState = IMailTemplateState & {
  id: number;
  activeSchool: ISchool;
  mailTemplate: IMailTemplate;
  mailTemplateInfo: Partial<IMailTemplate>;
};
export const actionTypes = {
  PULL_MAIL_TEMPLATES: 'mailTemplates/PULL_MAIL_TEMPLATES',
  SET_MAIL_TEMPLATES: 'mailTemplates/SET_MAIL_TEMPLATES',
  ADD_MAIL_TEMPLATE: 'mailTemplates/ADD_MAIL_TEMPLATE',
  UPDATE_MAIL_TEMPLATE: 'mailTemplates/UPDATE_MAIL_TEMPLATE',
  DELETE_MAIL_TEMPLATE: 'mailTemplates/DELETE_MAIL_TEMPLATE',
  REMOVE_MAIL_TEMPLATE: 'mailTemplates/REMOVE_MAIL_TEMPLATE',
  SET_MAIL_TEMPLATE: 'mailTemplates/SET_MAIL_TEMPLATE',
  SET_PHASE: 'mailTemplates/SET_PHASE'
};
export const initialState: IMailTemplateState = {
  mailTemplates: [],
  phase: null
};
export const mailTemplatesSelector = createSelector(
  (state: IMailTemplateState) => objectPath.get(state, ['mailTemplates', 'mailTemplates']),
  (mailTemplates: IMailTemplate[]) => mailTemplates
);
export const mailTemplatesPhaseSelector = createSelector(
  (state: IMailTemplateState) => objectPath.get(state, ['mailTemplates', 'phase']),
  (phase: string) => phase
);
export const reducer = persistReducer(
  { storage, key: 'mailTemplates' },
  (
    state: IMailTemplateState = initialState,
    action: IAction<TActionAllState>
  ): IMailTemplateState => {
    switch (action.type) {
      case actionTypes.SET_MAIL_TEMPLATES: {
        const { mailTemplates } = action.payload;
        return { ...state, mailTemplates };
      }
      case actionTypes.SET_MAIL_TEMPLATE: {
        const { mailTemplate } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.mailTemplates.findIndex((d) => d.id === mailTemplate.id);
          if (index > -1) {
            draftState.mailTemplates[index] = mailTemplate;
          } else {
            draftState.mailTemplates.unshift(mailTemplate);
          }
        });
      }
      case actionTypes.REMOVE_MAIL_TEMPLATE: {
        const { id } = action.payload;
        const mailTemplates = { ...state }.mailTemplates.filter((d) => d.id !== id);
        return { ...state, mailTemplates };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);
export const mailTemplatesActions = {
  pullMailTemplates: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_MAIL_TEMPLATES,
    payload: { activeSchool }
  }),
  setMailTemplates: (mailTemplates: IMailTemplate[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_MAIL_TEMPLATES,
    payload: { mailTemplates }
  }),
  addMailTemplate: (
    mailTemplateInfo: Partial<IMailTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_MAIL_TEMPLATE,
    payload: { mailTemplateInfo }
  }),
  updateMailTemplate: (
    mailTemplateInfo: Partial<IMailTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_MAIL_TEMPLATE,
    payload: { mailTemplateInfo }
  }),
  deleteMailTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_MAIL_TEMPLATE,
    payload: { id }
  }),
  removeMailTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_MAIL_TEMPLATE,
    payload: { id }
  }),
  setMailTemplate: (mailTemplate: IMailTemplate): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_MAIL_TEMPLATE,
    payload: { mailTemplate }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};
export function* saga() {
  yield takeLatest(
    actionTypes.PULL_MAIL_TEMPLATES,
    function* pullMailTemplatesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(mailTemplatesActions.setPhase('loading'));
      const { activeSchool } = payload;
      const url = `${MAIL_TEMPLATES_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);
      if (response.status !== 200) {
        yield put(mailTemplatesActions.setPhase('error'));
        return;
      }
      yield put(mailTemplatesActions.setMailTemplates(response.data));
      yield put(mailTemplatesActions.setPhase('success'));
    }
  );
  yield takeLatest(
    actionTypes.ADD_MAIL_TEMPLATE,
    function* addMailTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(mailTemplatesActions.setPhase('adding'));
      const { mailTemplateInfo } = payload;
      const response = yield axios.post(`${MAIL_TEMPLATES_API_URL}`, mailTemplateInfo);
      if (response.status !== 200) {
        yield put(mailTemplatesActions.setPhase('error'));
        return;
      }
      yield put(mailTemplatesActions.setMailTemplate(response.data));
      yield put(mailTemplatesActions.setPhase('success'));
    }
  );
  yield takeLatest(
    actionTypes.UPDATE_MAIL_TEMPLATE,
    function* updateMailTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(mailTemplatesActions.setPhase('updating'));
      const { mailTemplateInfo } = payload;
      const response = yield axios.patch(
        `${MAIL_TEMPLATES_API_URL}/${mailTemplateInfo.id}`,
        mailTemplateInfo
      );
      if (response.status !== 200) {
        yield put(mailTemplatesActions.setPhase('error'));
        return;
      }
      yield put(mailTemplatesActions.setMailTemplate(response.data));
      yield put(mailTemplatesActions.setPhase('success'));
    }
  );
  yield takeLatest(
    actionTypes.REMOVE_MAIL_TEMPLATE,
    function* deleteMailTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(mailTemplatesActions.setPhase('deleting'));
      const { id } = payload;
      const response = yield axios.delete(`${MAIL_TEMPLATES_API_URL}/${id}`);
      if (response.status !== 204) {
        yield put(mailTemplatesActions.setPhase('error'));
        return;
      }
      yield put(mailTemplatesActions.removeMailTemplate(id));
      yield put(mailTemplatesActions.setPhase('success'));
    }
  );
}
