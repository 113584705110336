import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction, TPhase } from 'store/store';
import { ATTENDANCE_TYPES_API_URL } from 'store/ApiUrls';

export interface IAttendanceType {
  id?: number;
  symbol?: string;
  title?: string;
  isLate?: string;
  forTeacher?: string;
  color?: string;
}

interface IAttendanceTypeState {
  attendanceTypes: IAttendanceType[];
  phase: TPhase;
}

type TActionAllState = IAttendanceTypeState & {
  attendanceType: IAttendanceType;
  attendanceTypeInfo: Partial<IAttendanceType>;
};

export const actionTypes = {
  PULL_ATTENDANCE_TYPES: 'attendanceTypes/PULL_ATTENDANCE_TYPES',
  SET_ATTENDANCE_TYPES: 'attendanceTypes/SET_INCOME_EXPENSES',
  SET_PHASE: 'attendanceTypes/SET_PHASE'
};

export const initialState: IAttendanceTypeState = {
  attendanceTypes: [],
  phase: null
};

export const attendanceTypesSelector = createSelector(
  (state: IAttendanceTypeState) => objectPath.get(state, ['attendanceTypes', 'attendanceTypes']),
  (attendanceTypes: IAttendanceType[]) => attendanceTypes
);

export const attendanceTypesPhaseSelector = createSelector(
  (state: IAttendanceTypeState) => objectPath.get(state, ['attendanceTypes', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'attendanceTypes' },
  (
    state: IAttendanceTypeState = initialState,
    action: IAction<TActionAllState>
  ): IAttendanceTypeState => {
    switch (action.type) {
      case actionTypes.SET_ATTENDANCE_TYPES: {
        const { attendanceTypes } = action.payload;
        return { ...state, attendanceTypes };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const attendanceTypesActions = {
  pullAttendanceTypes: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ATTENDANCE_TYPES
  }),
  setAttendanceTypes: (attendanceTypes: IAttendanceType[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ATTENDANCE_TYPES,
    payload: { attendanceTypes }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_ATTENDANCE_TYPES, function* pullAttendanceTypesSaga() {
    yield put(attendanceTypesActions.setPhase('loading'));

    const url = `${ATTENDANCE_TYPES_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(attendanceTypesActions.setPhase('error'));
      return;
    }

    yield put(attendanceTypesActions.setAttendanceTypes(response.data));
    yield put(attendanceTypesActions.setPhase('success'));
  });
}
