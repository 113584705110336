import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { AppDispatch, RootState } from 'store/store';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { SaveButton, CancelButton, FormButtons } from 'utils/ActionLinks';
import { TActionType } from 'utils/shared-types';
import { userActiveSchoolSelector } from 'store/user';

import {
  acReportTypesSelector,
  acReportTypesPhaseSelector,
  acReportTypesActions,
  IAcReportType
} from './_store/report-types';

const mapStateToProps = (state: RootState) => ({
  activeSchool: userActiveSchoolSelector(state),
  reportTypes: acReportTypesSelector(state),
  phase: acReportTypesPhaseSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addReportType: (reportTypeInfo: Partial<IAcReportType>) =>
    dispatch(acReportTypesActions.addReportType(reportTypeInfo)),
  updateReportType: (reportTypeInfo: Partial<IAcReportType>) =>
    dispatch(acReportTypesActions.updateReportType(reportTypeInfo))
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TFormProps = PropsFromRedux & {
  actionType: TActionType;
  sideForm?: boolean;
  handleClose?: any;
};

const ReportTypeForm: React.FC<TFormProps> = (props) => {
  const {
    actionType,
    sideForm,
    reportTypes,
    phase,
    activeSchool,
    handleClose,
    addReportType,
    updateReportType
  } = props;

  const { id } = useParams();

  const intl = useTranslation();
  const { showSnackbar } = useSnackbar();

  const [showPaperSizes, setShowPaperSizes] = React.useState(false);

  const reportTypeInfo: IAcReportType = reportTypes.find(
    (g: IAcReportType) => g.id === parseInt(id)
  );

  const initialFormValues: Partial<IAcReportType> = {
    id: (actionType === 'new' && null) || reportTypeInfo?.id || null,
    reportTitle: (actionType === 'new' && '') || reportTypeInfo?.reportTitle || '',
    paperSize: (actionType === 'new' && '') || reportTypeInfo?.paperSize || '',
    paperLayout: (actionType === 'new' && 'portrait') || reportTypeInfo?.paperLayout || 'portrait',
    fontSize: (actionType === 'new' && 0) || reportTypeInfo?.fontSize || 0,
    paperSizeWidth: (actionType === 'new' && 210) || reportTypeInfo?.paperSizeWidth || 210,
    paperSizeHeight: (actionType === 'new' && 297) || reportTypeInfo?.paperSizeHeight || 297
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    initialValues,
    errors,
    isSubmitting,
    setSubmitting,
    status,
    setStatus
  } = useFormik({
    initialValues: initialFormValues,
    validate: (values) => validateForm(values),
    onSubmit: (values) => submitForm(values)
  });
  const validateForm = (values: Partial<IAcReportType>) => {
    const errors: { [key: string]: any } = {};
    const nonEmptyFields = ['reportTitle', 'paperSize', 'paperLayout', 'fontSize'];

    nonEmptyFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = intl.translate({ id: 'app.cannot_be_empty' });
      }
    });

    return errors;
  };

  const submitForm = (values: Partial<IAcReportType>) => {
    setStatus('submitted');

    setSubmitting(true);

    if (actionType === 'add') {
      const reportTypeAdd: Partial<IAcReportType> = Object.assign(values, {
        school: `/api/schools/${activeSchool.id}`
      });
      addReportType(reportTypeAdd);
    } else {
      updateReportType(values);
    }
  };

  React.useEffect(() => {
    setSubmitting(false);

    if (status === 'submitted' && phase === 'success') {
      showSnackbar({
        message: intl.translate({ id: 'app.saved' }),
        open: true
      });

      if (!sideForm) {
        setTimeout(() => {
          handleClose();
        }, 500);
      }
    }
  }, [status, phase, setSubmitting, handleClose]);

  React.useEffect(() => {
    if (values.paperSize === 'custom') setShowPaperSizes(true);
    else setShowPaperSizes(false);
  }, [values.paperSize]);

  return (
    <Box sx={{ display: 'flex' }}>
      <form
        style={{ width: '100%' }}
        className='form'
        noValidate={true}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              autoFocus
              id='reportTitle'
              className={`${errors.reportTitle ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.reportTitle ? errors.reportTitle : ''}
              error={!!errors.reportTitle}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_title' })}
              value={values.reportTitle}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id='paperSize'
              select={true}
              className={`${errors.paperSize ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.paperSize ? errors.paperSize : ''}
              error={!!errors.paperSize}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_paper_size' })}
              value={values.paperSize}
              variant='outlined'
              onChange={(event) => setFieldValue('paperSize', event.target.value)}
            >
              <MenuItem key='1' value='4x6' dense={true}>
                {`4x6 [inches]`}
              </MenuItem>
              <MenuItem key='2' value='5x7' dense={true}>
                {'5x7 [inches]'}
              </MenuItem>
              <MenuItem key='3' value='8x10' dense={true}>
                {'8x10 [inches]'}
              </MenuItem>
              <MenuItem key='4' value='A5' dense={true}>
                {'A5'}
              </MenuItem>
              <MenuItem key='5' value='A4' dense={true}>
                {'A4'}
              </MenuItem>
              <MenuItem key='6' value='USLegal' dense={true}>
                {'US Legal'}
              </MenuItem>
              <MenuItem key='7' value='USLetter' dense={true}>
                {'US Letter'}
              </MenuItem>
              <MenuItem key='8' value='custom' dense={true}>
                {`${intl.translate({ id: 'academic.academic_report_custom_sizes' })}`}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='paperSizeWidth'
              type='number'
              className={`${errors.paperSizeWidth ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting || !showPaperSizes ? true : false}
              helperText={errors.paperSizeWidth ? errors.paperSizeWidth : ''}
              error={!!errors.paperSizeWidth}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_paper_size_width' })}
              value={values.paperSizeWidth}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='paperSizeHeight'
              type='number'
              className={`${
                errors.paperSizeHeight ? 'is-invalid' : isSubmitting ? 'is-valid' : ''
              }`}
              disabled={isSubmitting || !showPaperSizes ? true : false}
              helperText={errors.paperSizeHeight ? errors.paperSizeHeight : ''}
              error={!!errors.paperSizeHeight}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_paper_size_height' })}
              value={values.paperSizeHeight}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='paperLayout'
              select={true}
              className={`${errors.paperLayout ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.paperLayout ? errors.paperLayout : ''}
              error={!!errors.paperLayout}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_paper_layout' })}
              value={values.paperLayout}
              variant='outlined'
              onChange={(event) => setFieldValue('paperLayout', event.target.value)}
            >
              <MenuItem key='1' value='portrait' dense={true}>
                {intl.translate({ id: 'academic.academic_report_paper_portrait' })}
              </MenuItem>
              <MenuItem key='2' value='landscape' dense={true}>
                {intl.translate({ id: 'academic.academic_report_paper_landscape' })}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='fontSize'
              select={true}
              className={`${errors.fontSize ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.fontSize ? errors.fontSize : ''}
              error={!!errors.fontSize}
              fullWidth={true}
              label={intl.translate({ id: 'academic.academic_report_font_size' })}
              value={values.fontSize}
              variant='outlined'
              onChange={(event) => setFieldValue('fontSize', event.target.value)}
            >
              {[8, 9, 10, 11, 12, 13, 14, 15, 16].map((v) => (
                <MenuItem key={v} value={v} dense={true}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <FormButtons
          saveButton={
            <SaveButton
              disabled={values === initialValues || isSubmitting}
              mode={isSubmitting && 'saving'}
            />
          }
          cancelButton={<CancelButton onClick={handleClose} />}
        />
      </form>
    </Box>
  );
};

export default connector(ReportTypeForm);
