import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { CIP_SEASONS_API_URL } from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';

export interface ICipSeason {
  id?: number;
  title?: string;
  timeToBeCompleted?: number;
  startDate?: string;
  endDate?: string;
  isActive?: string;
  isMultipleProject?: string;
  school?: ISchool;
}

interface ICipSeasonState {
  cipSeasons: ICipSeason[];
  phase: string;
}

type TActionAllState = ICipSeasonState & {
  id: number;
  school: ISchool;
  cipSeason: ICipSeason;
  cipSeasonInfo: Partial<ICipSeason>;
};

export const actionTypes = {
  PULL_CIP_SEASONS: 'cipSeasons/PULL_CIP_SEASONS',
  SET_CIP_SEASONS: 'cipSeasons/SET_CIP_SEASONS',
  ADD_CIP_SEASON: 'cipSeasons/ADD_CIP_SEASON',
  UPDATE_CIP_SEASON: 'cipSeasons/UPDATE_CIP_SEASON',
  DELETE_CIP_SEASON: 'cipSeasons/DELETE_CIP_SEASON',
  REMOVE_CIP_SEASON: 'cipSeasons/REMOVE_CIP_SEASON',
  SET_CIP_SEASON: 'cipSeasons/SET_CIP_SEASON',
  SET_PHASE: 'cipSeasons/SET_PHASE'
};

export const initialState: ICipSeasonState = {
  cipSeasons: [],
  phase: null
};

export const cipSeasonsSelector = createSelector(
  (state: ICipSeasonState) => objectPath.get(state, ['cip', 'cipSeasons', 'cipSeasons']),
  (cipSeasons: ICipSeason[]) => cipSeasons
);
export const cipSeasonsPhaseSelector = createSelector(
  (state: ICipSeasonState) => objectPath.get(state, ['cip', 'cipSeasons', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'cipSeasons' },
  (state: ICipSeasonState = initialState, action: IAction<TActionAllState>): ICipSeasonState => {
    switch (action.type) {
      case actionTypes.SET_CIP_SEASONS: {
        const { cipSeasons } = action.payload;
        return { ...state, cipSeasons };
      }
      case actionTypes.SET_CIP_SEASON: {
        const { cipSeason } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.cipSeasons.findIndex((d) => d.id === cipSeason.id);
          if (index > -1) {
            draftState.cipSeasons[index] = cipSeason;
          } else {
            draftState.cipSeasons.unshift(cipSeason);
          }
        });
      }
      case actionTypes.REMOVE_CIP_SEASON: {
        const { id } = action.payload;
        const cipSeasons = { ...state }.cipSeasons.filter((d) => d.id !== id);
        return { ...state, cipSeasons };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const cipSeasonActions = {
  pullCipSeasons: (school: ISchool) => ({
    type: actionTypes.PULL_CIP_SEASONS,
    payload: { school }
  }),
  setCipSeasons: (cipSeasons: ICipSeason[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_CIP_SEASONS,
    payload: { cipSeasons }
  }),
  addCipSeason: (cipSeasonInfo: Partial<ICipSeason>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_CIP_SEASON,
    payload: { cipSeasonInfo }
  }),
  updateCipSeason: (cipSeasonInfo: Partial<ICipSeason>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_CIP_SEASON,
    payload: { cipSeasonInfo }
  }),
  deleteCipSeason: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_CIP_SEASON,
    payload: { id }
  }),
  removeCipSeason: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_CIP_SEASON,
    payload: { id }
  }),
  setCipSeason: (cipSeason: ICipSeason): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_CIP_SEASON,
    payload: { cipSeason }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_CIP_SEASONS,
    function* pullCipSeasonsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipSeasonActions.setPhase('loading'));

      const { school } = payload;
      const url = `${CIP_SEASONS_API_URL}.json?school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(cipSeasonActions.setPhase('error'));
        return;
      }

      yield put(cipSeasonActions.setCipSeasons(response.data));
      yield put(cipSeasonActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_CIP_SEASON,
    function* addCipSeasonSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipSeasonActions.setPhase('adding'));

      const { cipSeasonInfo } = payload;
      const response = yield axios.post(`${CIP_SEASONS_API_URL}`, cipSeasonInfo);

      if (response.status !== 201) {
        yield put(cipSeasonActions.setPhase('error'));
        return;
      }

      yield put(cipSeasonActions.setCipSeason(response.data));
      yield put(cipSeasonActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_CIP_SEASON,
    function* updateCipSeasonSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipSeasonActions.setPhase('updating'));

      const { cipSeasonInfo } = payload;
      const response = yield axios.patch(
        `${CIP_SEASONS_API_URL}/${cipSeasonInfo.id}`,
        cipSeasonInfo
      );

      if (response.status !== 200) {
        yield put(cipSeasonActions.setPhase('error'));
        return;
      }

      yield put(cipSeasonActions.setCipSeason(response.data));
      yield put(cipSeasonActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_CIP_SEASON,
    function* deleteCipSeasonSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipSeasonActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${CIP_SEASONS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(cipSeasonActions.setPhase('error'));
        return;
      }

      yield put(cipSeasonActions.removeCipSeason(id));
      yield put(cipSeasonActions.setPhase('success'));
    }
  );
}
