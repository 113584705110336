import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { AppDispatch, RootState } from 'store/store';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { SaveButton, CancelButton, FormButtons } from 'utils/ActionLinks';
import { TActionType } from 'utils/shared-types';
import { userActiveSchoolSelector } from 'store/user';
import {
  bankAccountsPhaseSelector,
  bankAccountsSelector,
  IBankAccount,
  bankAccountsActions
} from './_store/bank-account';
import {
  banksActions,
  banksSelector,
  bankBranchesSelector,
  IBankBranch,
  IBank
} from '../_store/bank';

const mapStateToProps = (state: RootState) => ({
  activeSchool: userActiveSchoolSelector(state),
  bankAccounts: bankAccountsSelector(state),
  banks: banksSelector(state),
  bankBranches: bankBranchesSelector(state),
  phase: bankAccountsPhaseSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addBankAccount: (bankAccountInfo: Partial<IBankAccount>) =>
    dispatch(bankAccountsActions.addBankAccount(bankAccountInfo)),
  updateBankAccount: (bankAccountInfo: Partial<IBankAccount>) =>
    dispatch(bankAccountsActions.updateBankAccount(bankAccountInfo)),
  pullBanks: (countryCode: string) => dispatch(banksActions.pullBanks(countryCode)),
  pullBankBranches: (bankId: number) => dispatch(banksActions.pullBankBranches(bankId))
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TFormProps = PropsFromRedux & {
  actionType: TActionType;
  sideForm?: boolean;
  handleClose?: any;
};

const BankAccountForm: React.FC<TFormProps> = (props) => {
  const {
    actionType,
    sideForm,
    bankAccounts,
    phase,
    activeSchool,
    handleClose,
    banks,
    bankBranches,
    addBankAccount,
    updateBankAccount,
    pullBanks,
    pullBankBranches
  } = props;

  const { id } = useParams();

  const intl = useTranslation();
  const { showSnackbar } = useSnackbar();

  const bankAccountInfo: IBankAccount = bankAccounts.find(
    (g: IBankAccount) => g.id === parseInt(id)
  );

  const initialFormValues: Partial<IBankAccount> = {
    id: (actionType === 'new' && null) || bankAccountInfo?.id || null,
    accountName: (actionType === 'new' && '') || bankAccountInfo?.accountName || '',
    accountNumber: (actionType === 'new' && '') || bankAccountInfo?.accountNumber || '',
    iban: (actionType === 'new' && '') || bankAccountInfo?.iban || '',
    currency: (actionType === 'new' && '') || bankAccountInfo?.currency || '',
    bankId: (actionType === 'new' && null) || bankAccountInfo?.bankId || null,
    bankBranchId: (actionType === 'new' && null) || bankAccountInfo?.bankBranchId || null
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    isSubmitting,
    setSubmitting,
    status,
    setStatus
  } = useFormik({
    initialValues: initialFormValues,
    validate: (values) => validateForm(values),
    onSubmit: (values) => submitForm(values)
  });
  const validateForm = (values: Partial<IBankAccount>) => {
    const errors: { [key: string]: any } = {};
    const nonEmptyFields = ['accountName'];

    nonEmptyFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = intl.translate({ id: 'app.cannot_be_empty' });
      }
    });

    return errors;
  };

  React.useEffect(() => {
    if (banks.length === 0) {
      pullBanks(activeSchool?.countryCode);
    }
    pullBankBranches(values?.bankId || bankAccountInfo?.bankId);
  }, []);

  React.useEffect(() => {
    pullBankBranches(values.bankId);
  }, [values.bankId]);

  const submitForm = (values: Partial<IBankAccount>) => {
    setStatus('submitted');

    setSubmitting(true);

    if (actionType === 'add') {
      const newValue: Partial<IBankAccount> = Object.assign(values, {
        school: `/api/schools/${activeSchool.id}`
      });
      addBankAccount(newValue);
    } else {
      updateBankAccount(values);
    }
  };

  React.useEffect(() => {
    setSubmitting(false);

    if (status === 'submitted' && phase === 'success') {
      showSnackbar({
        message: intl.translate({ id: 'app.saved' }),
        open: true
      });

      if (!sideForm) {
        setTimeout(() => {
          handleClose();
        }, 500);
      }
    }
  }, [status, phase, setSubmitting, handleClose]);

  return (
    <Box sx={{ display: 'flex' }}>
      <form
        style={{ width: '100%' }}
        className='form'
        noValidate={true}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              id='accountName'
              className={`${errors.accountName ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.accountName ? errors.accountName : ''}
              error={!!errors.accountName}
              fullWidth={true}
              label={intl.translate({ id: 'banks.bank_account_name' })}
              value={values.accountName}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id='accountNumber'
              className={`${errors.accountNumber ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.accountNumber ? errors.accountNumber : ''}
              error={!!errors.accountNumber}
              fullWidth={true}
              label={intl.translate({ id: 'banks.bank_account_number' })}
              value={values.accountNumber}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id='iban'
              className={`${errors.iban ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.iban ? errors.iban : ''}
              error={!!errors.iban}
              fullWidth={true}
              label={intl.translate({ id: 'banks.iban' })}
              value={values.iban}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id='currency'
              className={`${errors.currency ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.currency ? errors.currency : ''}
              error={!!errors.currency}
              fullWidth={true}
              label={intl.translate({ id: 'banks.currency' })}
              value={values.currency}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id='bankId'
              options={(banks && banks) || []}
              autoHighlight
              value={banks && banks.find((c: IBank) => c.id === values.bankId)}
              onChange={(_e, value: IBank) => setFieldValue('bankId', value?.id || '')}
              getOptionLabel={(option: IBank) => option.bankName}
              renderOption={(props, option: IBank) => <li {...props}>{option.bankName}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`${errors.bankId ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
                  disabled={isSubmitting ? true : false}
                  helperText={errors.bankId ? errors.bankId : ''}
                  value={banks && banks.find((c: IBank) => c.id === values.bankId)}
                  label={intl.formatMessage({ id: 'banks.bank' })}
                  variant='outlined'
                  fullWidth={true}
                  margin='normal'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id='bankBranchId'
              sx={{
                '& .MuiAutocomplete-option': {
                  fontSize: 15,
                  '& > span': {
                    marginRight: 10,
                    fontSize: 18
                  }
                }
              }}
              options={
                (bankBranches &&
                  bankBranches.sort((a, b) => (a.branchName > b.branchName ? 1 : -1))) ||
                []
              }
              autoHighlight
              value={
                (bankBranches &&
                  bankBranches.find((s: IBankBranch) => s.id === values.bankBranchId)) ||
                null
              }
              onChange={(e, value: IBankBranch) => setFieldValue('bankBranchId', value?.id || '')}
              getOptionLabel={(option: IBankBranch) => option.branchName}
              renderOption={(props, option) => <li {...props}>{option.branchName}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`${isSubmitting ? 'is-valid' : ''}`}
                  disabled={isSubmitting ? true : false}
                  value={
                    (bankBranches &&
                      bankBranches.find((s: IBankBranch) => s.id === values.bankBranchId)) ||
                    null
                  }
                  label={intl.translate({ id: 'banks.bank_branch' })}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormButtons
          saveButton={<SaveButton disabled={isSubmitting} mode={isSubmitting && 'saving'} />}
          cancelButton={<CancelButton onClick={handleClose} />}
        />
      </form>
    </Box>
  );
};

export default connector(BankAccountForm);
