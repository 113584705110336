import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { STUDENT_BEHAVIOURS_API_URL } from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';

export interface IStudentBehaviour {
  id: number;
  title: string;
  school: number;
}

interface IStudentBehaviourState {
  studentBehaviours: IStudentBehaviour[];
  phase: string;
}

type TActionAllState = IStudentBehaviourState & {
  id: number;
  activeSchool: ISchool;
  studentBehaviour: IStudentBehaviour;
  studentBehaviourInfo: Partial<IStudentBehaviour>;
};

export const actionTypes = {
  PULL_STUDENT_BEHAVIOURS: 'studentBehaviours/PULL_STUDENT_BEHAVIOURS',
  SET_STUDENT_BEHAVIOURS: 'studentBehaviours/SET_STUDENT_BEHAVIOURS',
  ADD_STUDENT_BEHAVIOUR: 'studentBehaviours/ADD_STUDENT_BEHAVIOUR',
  UPDATE_STUDENT_BEHAVIOUR: 'studentBehaviours/UPDATE_STUDENT_BEHAVIOUR',
  DELETE_STUDENT_BEHAVIOUR: 'studentBehaviours/DELETE_STUDENT_BEHAVIOUR',
  REMOVE_STUDENT_BEHAVIOUR: 'studentBehaviours/REMOVE_STUDENT_BEHAVIOUR',
  SET_STUDENT_BEHAVIOUR: 'studentBehaviours/SET_STUDENT_BEHAVIOUR',
  SET_PHASE: 'studentBehaviours/SET_PHASE'
};

export const initialState: IStudentBehaviourState = {
  studentBehaviours: [],
  phase: null
};

export const studentBehavioursSelector = createSelector(
  (state: IStudentBehaviourState) =>
    objectPath.get(state, ['studentBehaviours', 'studentBehaviours']),
  (studentBehaviours: IStudentBehaviour[]) => studentBehaviours
);
export const studentBehavioursPhaseSelector = createSelector(
  (state: IStudentBehaviourState) => objectPath.get(state, ['studentBehaviours', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentBehaviours' },
  (
    state: IStudentBehaviourState = initialState,
    action: IAction<TActionAllState>
  ): IStudentBehaviourState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_BEHAVIOURS: {
        const { studentBehaviours } = action.payload;
        return { ...state, studentBehaviours };
      }
      case actionTypes.SET_STUDENT_BEHAVIOUR: {
        const { studentBehaviour } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentBehaviours.findIndex((d) => d.id === studentBehaviour.id);
          if (index > -1) {
            draftState.studentBehaviours[index] = studentBehaviour;
          } else {
            draftState.studentBehaviours.unshift(studentBehaviour);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_BEHAVIOUR: {
        const { id } = action.payload;
        const studentBehaviours = { ...state }.studentBehaviours.filter((d) => d.id !== id);
        return { ...state, studentBehaviours };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentBehavioursActions = {
  pullStudentBehaviours: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_BEHAVIOURS,
    payload: { activeSchool }
  }),
  setStudentBehaviours: (
    studentBehaviours: IStudentBehaviour[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BEHAVIOURS,
    payload: { studentBehaviours }
  }),
  addStudentBehaviour: (
    studentBehaviourInfo: Partial<IStudentBehaviour>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_BEHAVIOUR,
    payload: { studentBehaviourInfo }
  }),
  updateStudentBehaviour: (
    studentBehaviourInfo: Partial<IStudentBehaviour>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_BEHAVIOUR,
    payload: { studentBehaviourInfo }
  }),
  deleteStudentBehaviour: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_BEHAVIOUR,
    payload: { id }
  }),
  removeStudentBehaviour: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_BEHAVIOUR,
    payload: { id }
  }),
  setStudentBehaviour: (
    studentBehaviour: IStudentBehaviour
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BEHAVIOUR,
    payload: { studentBehaviour }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_BEHAVIOURS,
    function* pullStudentBehaviourSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBehavioursActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${STUDENT_BEHAVIOURS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentBehavioursActions.setPhase('error'));
        return;
      }

      yield put(studentBehavioursActions.setStudentBehaviours(response.data));
      yield put(studentBehavioursActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_BEHAVIOUR,
    function* addStudentBehaviourSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBehavioursActions.setPhase('adding'));

      const { studentBehaviourInfo } = payload;
      const response = yield axios.post(`${STUDENT_BEHAVIOURS_API_URL}`, studentBehaviourInfo);

      if (response.status !== 201) {
        yield put(studentBehavioursActions.setPhase('error'));
        return;
      }

      yield put(studentBehavioursActions.setStudentBehaviour(response.data));
      yield put(studentBehavioursActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_BEHAVIOUR,
    function* updateStudentBehaviourSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBehavioursActions.setPhase('updating'));

      const { studentBehaviourInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_BEHAVIOURS_API_URL}/${studentBehaviourInfo.id}`,
        studentBehaviourInfo
      );

      if (response.status !== 200) {
        yield put(studentBehavioursActions.setPhase('error'));
        return;
      }

      yield put(studentBehavioursActions.setStudentBehaviour(response.data));
      yield put(studentBehavioursActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_BEHAVIOUR,
    function* deleteStudentBehaviourSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBehavioursActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_BEHAVIOURS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentBehavioursActions.setPhase('error'));
        return;
      }

      yield put(studentBehavioursActions.removeStudentBehaviour(id));
      yield put(studentBehavioursActions.setPhase('success'));
    }
  );
}
