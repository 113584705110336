import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';
import EnrollmentAgreementNew from './EnrollmentAgreementNew';
import ItemList from './items/ItemList';

const EnrollmentAgreementList = Loadable(lazy(() => import('./EnrollmentAgreementList')));

const routes = {
  path: 'agreement',
  children: [
    {
      path: '',
      element: <EnrollmentAgreementList />
    },
    {
      path: 'new',
      element: <EnrollmentAgreementNew />
    },
    {
      path: ':id',
      element: <EnrollmentAgreementList />
    },
    {
      path: ':id/:action',
      element: <EnrollmentAgreementList />
    },
    {
      path: ':id/item',
      element: <ItemList />
    },
    {
      path: ':id/item/:subaction',
      element: <ItemList />
    },
    {
      path: ':id/item/:subid/:subaction',
      element: <ItemList />
    }
  ]
};

export default routes;
