import React from 'react';
import loadable from '@loadable/component';

import reportTypesRoutes from './report-types/routes';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));
const AcademicPanel = loadable(() => import('./dashboard/index'));

const routes = {
  path: 'academic',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <AcademicPanel />
    },
    {
      path: 'dashboard',
      element: <AcademicPanel />
    },
    reportTypesRoutes
  ]
};

export default routes;
