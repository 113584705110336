import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction, TPhase } from 'store/store';
import { ENROLLMENT_AGREEMENT_API_URL, READONLY_ENROLLMENT_CONTRACT_API_URL } from 'store/ApiUrls';

export interface IReadonlyEnrollmentContract {
  id?: number;
  title?: string;
  file?: string;
  isActive?: string;
  countryCode?: string;
}

export interface IEnrollmentAgreement {
  id?: number;
  title?: string;
  readonlyContract: IReadonlyEnrollmentContract;
  showSchoolLogo?: string;
  showDadMomInfo?: string;
  showParentSign?: string;
  showStudentSign?: string;
  showApprovedBySign?: string;
  showPreparedBySign?: string;
  language?: string;
  isActive?: string;
  school: ISchool;
  showSchoolName?: string;
  showHeader?: string;
  showFooter?: string;
  footerText?: string;
  headerText?: string;
}

interface IEnrollmentAgreementState {
  enrollmentAgreements: IEnrollmentAgreement[];
  readonlyContracts: IReadonlyEnrollmentContract[];
  phase: TPhase;
}

type TActionAllState = IEnrollmentAgreementState & {
  id: number;
  activeSchool: ISchool;
  enrollmentAgreement: IEnrollmentAgreement;
  enrollmentAgreementInfo: Partial<IEnrollmentAgreement>;
};

export const actionTypes = {
  PULL_ENROLLMENT_AGREEMENTS: 'enrollmentAgreements/PULL_ENROLLMENT_AGREEMENTS',
  PULL_READONLY_CONTRACTS: 'enrollmentAgreements/PULL_READONLY_CONTRACTS',
  SET_ENROLLMENT_AGREEMENTS: 'enrollmentAgreements/SET_ENROLLMENT_AGREEMENTS',
  SET_READONLY_CONTRACTS: 'enrollmentAgreements/SET_READONLY_CONTRACTS',
  ADD_ENROLLMENT_AGREEMENT: 'enrollmentAgreements/ADD_ENROLLMENT_AGREEMENT',
  UPDATE_ENROLLMENT_AGREEMENT: 'enrollmentAgreements/UPDATE_ENROLLMENT_AGREEMENT',
  DELETE_ENROLLMENT_AGREEMENT: 'enrollmentAgreements/DELETE_ENROLLMENT_AGREEMENT',
  REMOVE_ENROLLMENT_AGREEMENT: 'enrollmentAgreements/REMOVE_ENROLLMENT_AGREEMENT',
  SET_ENROLLMENT_AGREEMENT: 'enrollmentAgreements/SET_ENROLLMENT_AGREEMENT',
  SET_PHASE: 'enrollmentAgreements/SET_PHASE'
};

export const initialState: IEnrollmentAgreementState = {
  enrollmentAgreements: [],
  readonlyContracts: [],
  phase: null
};

export const enrollmentAgreementSelector = createSelector(
  (state: IEnrollmentAgreementState) =>
    objectPath.get(state, ['enrollment', 'enrollmentAgreements', 'enrollmentAgreements']),
  (enrollmentAgreements: IEnrollmentAgreement[]) => enrollmentAgreements
);

export const readonlyContractsSelector = createSelector(
  (state: IEnrollmentAgreementState) =>
    objectPath.get(state, ['enrollment', 'enrollmentAgreements', 'readonlyContracts']),
  (readonlyContracts: IReadonlyEnrollmentContract[]) => readonlyContracts
);

export const enrollmentAgreementPhaseSelector = createSelector(
  (state: IEnrollmentAgreementState) =>
    objectPath.get(state, ['enrollment', 'enrollmentAgreements', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'enrollmentAgreements' },
  (
    state: IEnrollmentAgreementState = initialState,
    action: IAction<TActionAllState>
  ): IEnrollmentAgreementState => {
    switch (action.type) {
      case actionTypes.SET_ENROLLMENT_AGREEMENTS: {
        const { enrollmentAgreements } = action.payload;
        return { ...state, enrollmentAgreements };
      }
      case actionTypes.SET_READONLY_CONTRACTS: {
        const { readonlyContracts } = action.payload;
        return { ...state, readonlyContracts };
      }
      case actionTypes.SET_ENROLLMENT_AGREEMENT: {
        const { enrollmentAgreement } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.enrollmentAgreements.findIndex(
            (d) => d.id === enrollmentAgreement.id
          );
          if (index > -1) {
            draftState.enrollmentAgreements[index] = enrollmentAgreement;
          } else {
            draftState.enrollmentAgreements.unshift(enrollmentAgreement);
          }
        });
      }
      case actionTypes.REMOVE_ENROLLMENT_AGREEMENT: {
        const { id } = action.payload;
        const enrollmentAgreements = { ...state }.enrollmentAgreements.filter((d) => d.id !== id);
        return { ...state, enrollmentAgreements };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const enrollmentAgreementsActions = {
  pullEnrollmentAgreements: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ENROLLMENT_AGREEMENTS,
    payload: { activeSchool }
  }),
  pullReadonlyContracts: () => ({
    type: actionTypes.PULL_READONLY_CONTRACTS
  }),
  setEnrollmentAgreements: (
    enrollmentAgreements: IEnrollmentAgreement[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_AGREEMENTS,
    payload: { enrollmentAgreements }
  }),
  setReadonlyContracts: (
    readonlyContracts: IReadonlyEnrollmentContract[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_READONLY_CONTRACTS,
    payload: { readonlyContracts }
  }),
  addEnrollmentAgreement: (
    enrollmentAgreementInfo: Partial<IEnrollmentAgreement>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_ENROLLMENT_AGREEMENT,
    payload: { enrollmentAgreementInfo }
  }),
  updateEnrollmentAgreement: (
    enrollmentAgreementInfo: Partial<IEnrollmentAgreement>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_ENROLLMENT_AGREEMENT,
    payload: { enrollmentAgreementInfo }
  }),
  deleteEnrollmentAgreement: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_ENROLLMENT_AGREEMENT,
    payload: { id }
  }),
  removeEnrollmentAgreement: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_ENROLLMENT_AGREEMENT,
    payload: { id }
  }),
  setEnrollmentAgreement: (
    enrollmentAgreement: IEnrollmentAgreement
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_AGREEMENT,
    payload: { enrollmentAgreement }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_ENROLLMENT_AGREEMENTS,
    function* pullEnrollmentAgreementsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${ENROLLMENT_AGREEMENT_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(enrollmentAgreementsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementsActions.setEnrollmentAgreements(response.data));
      yield put(enrollmentAgreementsActions.setPhase('success'));
    }
  );

  yield takeLatest(actionTypes.PULL_READONLY_CONTRACTS, function* pullReadonlyContractsSaga() {
    yield put(enrollmentAgreementsActions.setPhase('loading'));

    const url = `${READONLY_ENROLLMENT_CONTRACT_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(enrollmentAgreementsActions.setPhase('error'));
      return;
    }

    yield put(enrollmentAgreementsActions.setReadonlyContracts(response.data));
    yield put(enrollmentAgreementsActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_ENROLLMENT_AGREEMENT,
    function* addEnrollmentAgreementsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementsActions.setPhase('adding'));

      const { enrollmentAgreementInfo } = payload;
      const response = yield axios.post(`${ENROLLMENT_AGREEMENT_API_URL}`, enrollmentAgreementInfo);

      if (response.status !== 200) {
        yield put(enrollmentAgreementsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementsActions.setEnrollmentAgreement(response.data));
      yield put(enrollmentAgreementsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_ENROLLMENT_AGREEMENT,
    function* updateEnrollmentAgreementsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementsActions.setPhase('updating'));

      const { enrollmentAgreementInfo } = payload;
      const response = yield axios.patch(
        `${ENROLLMENT_AGREEMENT_API_URL}/${enrollmentAgreementInfo.id}`,
        enrollmentAgreementInfo
      );

      if (response.status !== 200) {
        yield put(enrollmentAgreementsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementsActions.setEnrollmentAgreement(response.data));
      yield put(enrollmentAgreementsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_ENROLLMENT_AGREEMENT,
    function* deleteEnrollmentAgreementsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${ENROLLMENT_AGREEMENT_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(enrollmentAgreementsActions.setPhase('error'));
        return;
      }
      yield put(enrollmentAgreementsActions.removeEnrollmentAgreement(id));
      yield put(enrollmentAgreementsActions.setPhase('success'));
    }
  );
}
