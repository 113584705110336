import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IMPERSONATE_LOGS_API_URL } from 'store/ApiUrls';
import { IUser } from 'pages/account/account-types';
import { IAction } from 'store/store';

export type TPhase = null | 'loading' | 'error' | 'success';

interface IImpersonateLog {
  id: number;
  user: IUser;
  impersonateUser: IUser;
  enterTime: string;
  exitTime: string;
}

interface IImpersonateLogsState {
  impersonateLogs: IImpersonateLog[];
  phase: TPhase;
}

type TActionAllState = IImpersonateLogsState & {
  id: number;
};

export const actionTypes = {
  PULL_IMPERSONATE_LOGS: 'impersonate-logs/PULL_IMPERSONATE_LOGS',
  SET_IMPERSONATE_LOGS: 'impersonate-logs/SET_IMPERSONATE_LOGS',
  SET_PHASE: 'impersonate-logs/SET_PHASE'
};

export const initialState: IImpersonateLogsState = {
  impersonateLogs: [],
  phase: null
};

export const impersonateLogsSelector = createSelector(
  (state: IImpersonateLogsState) => objectPath.get(state, ['impersonateLogs', 'impersonateLogs']),
  (impersonateLogs: IImpersonateLog[]) => impersonateLogs
);
export const impersonateLogsPhaseSelector = createSelector(
  (state: IImpersonateLogsState) => objectPath.get(state, ['impersonateLogs', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'impersonate-logs' },
  (
    state: IImpersonateLogsState = initialState,
    action: IAction<TActionAllState>
  ): IImpersonateLogsState => {
    switch (action.type) {
      case actionTypes.SET_IMPERSONATE_LOGS: {
        const { impersonateLogs } = action.payload;
        return { ...state, impersonateLogs };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const impersonateLogsActions = {
  pullImpersonateLogs: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_IMPERSONATE_LOGS
  }),
  setImpersonateLogs: (impersonateLogs: IImpersonateLog[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_IMPERSONATE_LOGS,
    payload: { impersonateLogs }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_IMPERSONATE_LOGS, function* pullUserTypesSaga() {
    yield put(impersonateLogsActions.setPhase('loading'));

    const url = `${IMPERSONATE_LOGS_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(impersonateLogsActions.setPhase('error'));
      return;
    }

    yield put(impersonateLogsActions.setImpersonateLogs(response.data));
    yield put(impersonateLogsActions.setPhase('success'));
  });
}
