import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction, TPhase } from 'store/store';
import { PERSONNEL_CATEGORIES_API_URL } from 'store/ApiUrls';
import { IPersonnelCategory } from 'utils/shared-types';

interface IPersonnelCategoryData {
  personnelCategories: IPersonnelCategory[];
  phase: string;
}
type TActionAllState = IPersonnelCategoryData & {
  id: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_PERSONNEL_CATEGORIES: 'personnel/PULL_PERSONNEL_CATEGORIES',
  SET_PERSONNEL_CATEGORIES: 'personnel/SET_PERSONNEL_CATEGORIES',
  SET_PHASE: 'personnel/SET_PHASE'
};

export const initialState: IPersonnelCategoryData = {
  personnelCategories: [],
  phase: null
};

export const personnelCategoryDataSelector = createSelector(
  (state: IPersonnelCategoryData) =>
    objectPath.get(state, ['personnel', 'categories', 'personnelCategories']),
  (personnelCategories: IPersonnelCategory[]) => personnelCategories
);

export const reducer = persistReducer(
  { storage, key: 'personnelCategories' },
  (
    state: IPersonnelCategoryData = initialState,
    action: IAction<TActionAllState>
  ): IPersonnelCategoryData => {
    switch (action.type) {
      case actionTypes.SET_PERSONNEL_CATEGORIES: {
        const { personnelCategories } = action.payload;
        return { ...state, personnelCategories };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const personnelCategoryActions = {
  pullPersonnelCategories: (countryCode: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_PERSONNEL_CATEGORIES,
    payload: { countryCode }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  // Pull personnel categories
  yield takeLatest(
    actionTypes.PULL_PERSONNEL_CATEGORIES,
    function* pullPersonnelCategoriesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(personnelCategoryActions.setPhase('loading'));

      const { countryCode } = payload;
      const response = yield axios.get(
        `${PERSONNEL_CATEGORIES_API_URL}.json?countryCode=${countryCode}`
      );

      if (response.status !== 200) {
        yield put(personnelCategoryActions.setPhase('error'));
        return;
      }

      yield put({
        type: actionTypes.SET_PERSONNEL_CATEGORIES,
        payload: { personnelCategories: response.data }
      });
      yield put(personnelCategoryActions.setPhase('success'));
    }
  );
}
