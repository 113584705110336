import { combineReducers } from 'redux';

import {
  saga as cipActivitySagas,
  reducer as cipActivityReducer
} from 'pages/cip/activity/_store/cip-activity';

import {
  saga as cipSeasonSagas,
  reducer as cipSeasonReducer
} from 'pages/cip/season/_store/cip-season';

export const cipSagas = [cipActivitySagas(), cipSeasonSagas()];
export const cipReducer = combineReducers({
  cipActivities: cipActivityReducer,
  cipSeasons: cipSeasonReducer
});
