import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';

import { IAction } from '../../../../store/store';

import { USERS_API_URL } from '../../../../store/ApiUrls';
import { IUser } from 'pages/account/account-types';
import { ISchool } from 'pages/organization/organization-types';
import { TUserPassword } from 'store/auth';
import moment from 'moment';

export type TPhase = null | 'loading' | 'adding' | 'updating' | 'deleting' | 'error' | 'success';

interface IUsersState {
  users: IUser[];
  phase: TPhase;
}

type TActionAllState = IUsersState & {
  id: number;
  userPassword: Partial<TUserPassword>;
  user: IUser;
  school: ISchool;
  userInfo: Partial<IUser>;
};

export const actionTypes = {
  PULL_USERS: 'users/PULL_USERS',
  SET_USERS: 'users/SET_USERS',
  ADD_USER: 'users/ADD_USER',
  UPDATE_USER: 'users/UPDATE_USER',
  CREATE_PASSWORD: 'users/CREATE_PASSWORD',
  DELETE_USER: 'users/DELETE_USER',
  REMOVE_USER: 'users/REMOVE_USER',
  SET_USER: 'users/SET_USER',
  SET_PHASE: 'users/SET_PHASE'
};

export const initialState: IUsersState = {
  users: [],
  phase: null
};

export const usersSelector = createSelector(
  (state: IUsersState) => objectPath.get(state, ['users', 'list', 'users']),
  (users: IUser[]) => users
);
export const usersPhaseSelector = createSelector(
  (state: IUsersState) => objectPath.get(state, ['users', 'list', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'users' },
  (state: IUsersState = initialState, action: IAction<TActionAllState>): IUsersState => {
    switch (action.type) {
      case actionTypes.SET_USERS: {
        const { users } = action.payload;
        return { ...state, users };
      }
      case actionTypes.SET_USER: {
        const { user } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.users.findIndex((d) => d.id === user.id);
          if (index > -1) {
            draftState.users[index] = user;
          } else {
            draftState.users.unshift(user);
          }
        });
      }
      case actionTypes.REMOVE_USER: {
        const { id } = action.payload;
        const users = { ...state }.users.filter((d) => parseInt(d.uuid) !== id);
        return { ...state, users };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const usersActions = {
  pullUsers: (school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_USERS,
    payload: { school }
  }),
  setUsers: (users: IUser[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_USERS,
    payload: { users }
  }),
  addUser: (school: ISchool, userInfo: Partial<IUser>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_USER,
    payload: { school, userInfo }
  }),
  updateUser: (userInfo: Partial<IUser>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_USER,
    payload: { userInfo }
  }),
  createPassword: (
    userInfo: Partial<IUser>,
    userPassword: Partial<TUserPassword>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.CREATE_PASSWORD,
    payload: { userInfo, userPassword }
  }),
  deleteUser: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_USER,
    payload: { id }
  }),
  removeUser: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_USER,
    payload: { id }
  }),
  setUser: (user: IUser): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_USER,
    payload: { user }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_USERS,
    function* pullUsersSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(usersActions.setPhase('loading'));

      const { school } = payload;
      const url =
        school.type == 'headquarters'
          ? `${USERS_API_URL}.json?pagination=false`
          : `${USERS_API_URL}.json?pagination=false&schoolId=${school.id}`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(usersActions.setPhase('error'));
        return;
      }

      yield put(usersActions.setUsers(response.data));
      yield put(usersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_USER,
    function* addUserSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(usersActions.setPhase('adding'));

      const { school, userInfo } = payload;

      const response = yield axios.post(`${USERS_API_URL}`, {
        id: userInfo.id,
        name: userInfo.name,
        lastName: userInfo.lastName,
        email: userInfo.email,
        userType: `/api/user_types/${userInfo.userTypeId}`,
        expiresAt:
          userInfo.expiresAt == null
            ? '0000-00-00'
            : moment(userInfo.expiresAt).format('YYYY-MM-DD'),
        schoolId: school.type == 'school' ? school.id : userInfo.schoolId,
        password: 'smartclass'
      });

      if (response.status !== 200) {
        yield put(usersActions.setPhase('error'));
        return;
      }

      yield put(usersActions.setUser(response.data));
      yield put(usersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_USER,
    function* updateUserSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(usersActions.setPhase('updating'));

      const { userInfo } = payload;

      const response = yield axios.patch(`${USERS_API_URL}/${userInfo.uuid}`, {
        id: userInfo.id,
        name: userInfo.name,
        lastName: userInfo.lastName,
        email: userInfo.email,
        userType: `/api/user_types/${userInfo.userTypeId}`,
        expiresAt:
          userInfo.expiresAt == null
            ? '0000-00-00'
            : moment(userInfo.expiresAt).format('YYYY-MM-DD'),
        schoolId: userInfo.schoolId
      });

      if (response.status !== 200) {
        yield put(usersActions.setPhase('error'));
        return;
      }

      yield put(usersActions.setUser(response.data));
      yield put(usersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.CREATE_PASSWORD,
    function* createPAsswordSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(usersActions.setPhase('updating'));

      const { userInfo, userPassword } = payload;

      console.log(userInfo, userPassword);

      const response = yield axios.patch(`${USERS_API_URL}/${userInfo.uuid}`, {
        password: userPassword.newPassword
      });

      if (response.status !== 204) {
        yield put(usersActions.setPhase('error'));
        return;
      }

      //yield put(usersActions.removeUser(id));
      yield put(usersActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_USER,
    function* deleteUserSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(usersActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${USERS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(usersActions.setPhase('error'));
        return;
      }

      yield put(usersActions.removeUser(id));
      yield put(usersActions.setPhase('success'));
    }
  );
}
