import { combineReducers } from 'redux';

import {
  saga as reportTypesSagas,
  reducer as reportTypesReducer
} from 'pages/academic/report-types/_store/report-types';

import {
  saga as acReportStaticSaga,
  reducer as acReportStaticReducer
} from 'pages/academic/report-types/_store/static-text';

export const academicSagas = [reportTypesSagas(), acReportStaticSaga()];
export const academicReducer = combineReducers({
  reportTypes: reportTypesReducer,
  staticTexts: acReportStaticReducer
});
