import React from 'react';
import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const LawyerList = Loadable(lazy(() => import('./LawyerList')));
const LawyerNew = Loadable(lazy(() => import('./LawyerNew')));
const routes = {
  path: 'lawyers',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <LawyerList />
    },
    {
      path: 'new',
      element: <LawyerNew />
    },
    {
      path: ':id',
      element: <LawyerList />
    },
    {
      path: ':id/:action',
      element: <LawyerList />
    }
  ]
};

export default routes;
