import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { OPTIC_FORM_FIELD_NAMES_API_URL } from 'store/ApiUrls';

export interface IOpticFormFieldName {
  id?: number;
  fieldId?: string;
  fieldTitle?: string;
  countryCode?: string;
}

interface IOpticFormFieldNameState {
  opticFormFieldNames: IOpticFormFieldName[];
  phase: string;
}

type TActionAllState = IOpticFormFieldNameState & {
  id: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_OPTIC_FORM_FIELD_NAMES: 'opticFormFieldNames/PULL_OPTIC_FORM_FIELD_NAMES',
  SET_OPTIC_FORM_FIELD_NAMES: 'opticFormFieldNames/SET_OPTIC_FORM_FIELD_NAMES',
  SET_PHASE: 'opticFormFieldNames/SET_PHASE'
};

export const initialState: IOpticFormFieldNameState = {
  opticFormFieldNames: [],
  phase: null
};

export const opticFormFieldNamesSelector = createSelector(
  (state: IOpticFormFieldNameState) =>
    objectPath.get(state, ['exams', 'opticFormFieldNames', 'opticFormFieldNames']),
  (opticFormFieldNames: IOpticFormFieldName[]) => opticFormFieldNames
);
export const opticFormFieldNamesPhaseSelector = createSelector(
  (state: IOpticFormFieldNameState) =>
    objectPath.get(state, ['exams', 'opticFormFieldNames', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'opticFormFieldNames' },
  (
    state: IOpticFormFieldNameState = initialState,
    action: IAction<TActionAllState>
  ): IOpticFormFieldNameState => {
    switch (action.type) {
      case actionTypes.SET_OPTIC_FORM_FIELD_NAMES: {
        const { opticFormFieldNames } = action.payload;
        return { ...state, opticFormFieldNames };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const opticFormFieldNamesActions = {
  pullOpticFormFieldNames: (countryCode: string) => ({
    type: actionTypes.PULL_OPTIC_FORM_FIELD_NAMES,
    payload: { countryCode }
  }),
  setOpticFormFieldNames: (
    opticFormFieldNames: IOpticFormFieldName[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_OPTIC_FORM_FIELD_NAMES,
    payload: { opticFormFieldNames }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_OPTIC_FORM_FIELD_NAMES,
    function* pullOpticFormFieldNamesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormFieldNamesActions.setPhase('loading'));

      const { countryCode } = payload;

      const url = `${OPTIC_FORM_FIELD_NAMES_API_URL}.json?countryCode=${countryCode}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(opticFormFieldNamesActions.setPhase('error'));
        return;
      }

      yield put(opticFormFieldNamesActions.setOpticFormFieldNames(response.data));
      yield put(opticFormFieldNamesActions.setPhase('success'));
    }
  );
}
