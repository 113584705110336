import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { EXAM_TEMPLATES_API_URL } from 'store/ApiUrls';

export interface IExamTemplate {
  id: number;
  title: string;
}

interface IExamTemplateState {
  examTemplates: IExamTemplate[];
  phase: string;
}

type TActionAllState = IExamTemplateState & {
  id: number;
  examTemplate: IExamTemplate;
  examTemplateInfo: Partial<IExamTemplate>;
};

export const actionTypes = {
  PULL_EXAM_TEMPLATES: 'examTemplates/PULL_EXAM_TEMPLATES',
  SET_EXAM_TEMPLATES: 'examTemplates/SET_EXAM_TEMPLATES',
  ADD_EXAM_TEMPLATE: 'examTemplates/ADD_EXAM_TEMPLATE',
  UPDATE_EXAM_TEMPLATE: 'examTemplates/UPDATE_EXAM_TEMPLATE',
  DELETE_EXAM_TEMPLATE: 'examTemplates/DELETE_EXAM_TEMPLATE',
  REMOVE_EXAM_TEMPLATE: 'examTemplates/REMOVE_EXAM_TEMPLATE',
  SET_EXAM_TEMPLATE: 'examTemplates/SET_SMS_TEMPLATE',
  SET_PHASE: 'examTemplates/SET_PHASE'
};

export const initialState: IExamTemplateState = {
  examTemplates: [],
  phase: null
};

export const examTemplatesSelector = createSelector(
  (state: IExamTemplateState) => objectPath.get(state, ['exams', 'examTemplates', 'examTemplates']),
  (examTemplates: IExamTemplate[]) => examTemplates
);
export const examTemplatesPhaseSelector = createSelector(
  (state: IExamTemplateState) => objectPath.get(state, ['exams', 'examTemplates', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'examTemplates' },
  (
    state: IExamTemplateState = initialState,
    action: IAction<TActionAllState>
  ): IExamTemplateState => {
    switch (action.type) {
      case actionTypes.SET_EXAM_TEMPLATES: {
        const { examTemplates } = action.payload;
        return { ...state, examTemplates };
      }
      case actionTypes.SET_EXAM_TEMPLATE: {
        const { examTemplate } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.examTemplates.findIndex((d) => d.id === examTemplate.id);
          if (index > -1) {
            draftState.examTemplates[index] = examTemplate;
          } else {
            draftState.examTemplates.unshift(examTemplate);
          }
        });
      }
      case actionTypes.REMOVE_EXAM_TEMPLATE: {
        const { id } = action.payload;
        const examTemplates = { ...state }.examTemplates.filter((d) => d.id !== id);
        return { ...state, examTemplates };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const examTemplatesActions = {
  pullExamTemplates: () => ({
    type: actionTypes.PULL_EXAM_TEMPLATES
  }),
  setExamTemplates: (examTemplates: IExamTemplate[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_TEMPLATES,
    payload: { examTemplates }
  }),
  addExamTemplate: (
    examTemplateInfo: Partial<IExamTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_EXAM_TEMPLATE,
    payload: { examTemplateInfo }
  }),
  updateExamTemplate: (
    examTemplateInfo: Partial<IExamTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_EXAM_TEMPLATE,
    payload: { examTemplateInfo }
  }),
  deleteExamTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_EXAM_TEMPLATE,
    payload: { id }
  }),
  removeExamTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_EXAM_TEMPLATE,
    payload: { id }
  }),
  setExamTemplate: (examTemplate: IExamTemplate): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_TEMPLATE,
    payload: { examTemplate }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_EXAM_TEMPLATES, function* pullExamTemplatesSaga() {
    yield put(examTemplatesActions.setPhase('loading'));

    const url = `${EXAM_TEMPLATES_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(examTemplatesActions.setPhase('error'));
      return;
    }

    yield put(examTemplatesActions.setExamTemplates(response.data));
    yield put(examTemplatesActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_EXAM_TEMPLATE,
    function* addExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplatesActions.setPhase('adding'));

      const { examTemplateInfo } = payload;
      const response = yield axios.post(`${EXAM_TEMPLATES_API_URL}`, examTemplateInfo);

      if (response.status !== 200) {
        yield put(examTemplatesActions.setPhase('error'));
        return;
      }

      yield put(examTemplatesActions.setExamTemplate(response.data));
      yield put(examTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_EXAM_TEMPLATE,
    function* updateExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplatesActions.setPhase('updating'));

      const { examTemplateInfo } = payload;
      const response = yield axios.patch(
        `${EXAM_TEMPLATES_API_URL}/${examTemplateInfo.id}`,
        examTemplateInfo
      );

      if (response.status !== 200) {
        yield put(examTemplatesActions.setPhase('error'));
        return;
      }

      yield put(examTemplatesActions.setExamTemplate(response.data));
      yield put(examTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_EXAM_TEMPLATE,
    function* deleteExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplatesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${EXAM_TEMPLATES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(examTemplatesActions.setPhase('error'));
        return;
      }

      yield put(examTemplatesActions.removeExamTemplate(id));
      yield put(examTemplatesActions.setPhase('success'));
    }
  );
}
