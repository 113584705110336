import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { COURSE_VIDEOS_API_URL } from 'store/ApiUrls';
import { IUser } from 'pages/account/account-types';

export interface ICourseVideo {
  id?: number;
  groupName?: string;
  title?: string;
  thumb?: string;
  video?: string;
  videoLength?: number;
  isPreview?: string;
  shortId?: number;
  assetsZip?: string;
  userId?: number;
  courseId?: number;
  batchId?: number;
  classId?: number;
  createdAt?: string;
  updatedAt?: string;
}

interface ICourseVideoState {
  courseVideos: ICourseVideo[];
  phase: string;
}

type TActionAllState = ICourseVideoState & {
  id: number;
  activeUser: IUser;
  courseVideo: ICourseVideo;
  courseVideoInfo: Partial<ICourseVideo>;
};

export const actionTypes = {
  PULL_COURSE_VIDEOS: 'courseVideo/PULL_COURSE_VIDEOS',
  SET_COURSE_VIDEOS: 'courseVideo/SET_COURSE_VIDEOS',
  ADD_COURSE_VIDEO: 'courseVideo/ADD_COURSE_VIDEO',
  UPDATE_COURSE_VIDEO: 'courseVideo/UPDATE_COURSE_VIDEO',
  DELETE_COURSE_VIDEO: 'courseVideo/DELETE_COURSE_VIDEO',
  REMOVE_COURSE_VIDEO: 'courseVideo/REMOVE_COURSE_VIDEO',
  SET_COURSE_VIDEO: 'courseVideo/SET_COURSE_VIDEO',
  SET_PHASE: 'courseVideo/SET_PHASE'
};

export const initialState: ICourseVideoState = {
  courseVideos: [],
  phase: null
};

export const courseVideosSelector = createSelector(
  (state: ICourseVideoState) => objectPath.get(state, ['courseVideos', 'courseVideos']),
  (courseVideos: ICourseVideo[]) => courseVideos
);

export const courseVideosPhaseSelector = createSelector(
  (state: ICourseVideoState) => objectPath.get(state, ['courseVideos', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'courseVideos' },
  (
    state: ICourseVideoState = initialState,
    action: IAction<TActionAllState>
  ): ICourseVideoState => {
    switch (action.type) {
      case actionTypes.SET_COURSE_VIDEOS: {
        const { courseVideos } = action.payload;
        return { ...state, courseVideos };
      }
      case actionTypes.SET_COURSE_VIDEO: {
        const { courseVideo } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.courseVideos.findIndex((d) => d.id === courseVideo.id);
          if (index > -1) {
            draftState.courseVideos[index] = courseVideo;
          } else {
            draftState.courseVideos.unshift(courseVideo);
          }
        });
      }
      case actionTypes.REMOVE_COURSE_VIDEO: {
        const { id } = action.payload;
        const courseVideos = { ...state }.courseVideos.filter((d) => d.id !== id);
        return { ...state, courseVideos };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const courseVideosActions = {
  pullCourseVideos: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_COURSE_VIDEOS
  }),
  setCourseVideos: (courseVideos: ICourseVideo[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COURSE_VIDEOS,
    payload: { courseVideos }
  }),
  addCourseVideo: (courseVideoInfo: Partial<ICourseVideo>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_COURSE_VIDEO,
    payload: { courseVideoInfo }
  }),
  updateCourseVideo: (
    courseVideoInfo: Partial<ICourseVideo>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_COURSE_VIDEO,
    payload: { courseVideoInfo }
  }),
  deleteCourseVideo: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_COURSE_VIDEO,
    payload: { id }
  }),
  removeCourseVideo: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_COURSE_VIDEO,
    payload: { id }
  }),
  setCourseVideo: (courseVideo: ICourseVideo): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COURSE_VIDEO,
    payload: { courseVideo }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_COURSE_VIDEOS, function* pullCourseVideoSaga() {
    yield put(courseVideosActions.setPhase('loading'));

    const url = `${COURSE_VIDEOS_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(courseVideosActions.setPhase('error'));
      return;
    }

    yield put(courseVideosActions.setCourseVideos(response.data));
    yield put(courseVideosActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_COURSE_VIDEO,
    function* addCourseVideoSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseVideosActions.setPhase('adding'));

      const { courseVideoInfo } = payload;
      const response = yield axios.post(`${COURSE_VIDEOS_API_URL}`, {
        id: courseVideoInfo.id,
        groupName: courseVideoInfo.groupName,
        title: courseVideoInfo.title,
        thumb: courseVideoInfo.thumb,
        video: courseVideoInfo.video,
        videoLength: courseVideoInfo.videoLength,
        isPreview: courseVideoInfo.isPreview,
        shortId: courseVideoInfo.shortId,
        assetsZip: courseVideoInfo.assetsZip,
        userId: courseVideoInfo.userId,
        courseId: courseVideoInfo.courseId,
        batchId: courseVideoInfo.batchId,
        classId: courseVideoInfo.classId
      });

      if (response.status !== 200) {
        yield put(courseVideosActions.setPhase('error'));
        return;
      }

      yield put(courseVideosActions.setCourseVideo(response.data));
      yield put(courseVideosActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_COURSE_VIDEO,
    function* updateCourseVideoSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseVideosActions.setPhase('updating'));

      const { courseVideoInfo } = payload;
      const response = yield axios.patch(`${COURSE_VIDEOS_API_URL}/${courseVideoInfo.id}`, {
        groupName: courseVideoInfo.groupName,
        title: courseVideoInfo.title,
        thumb: courseVideoInfo.thumb,
        video: courseVideoInfo.video,
        videoLength: courseVideoInfo.videoLength,
        isPreview: courseVideoInfo.isPreview,
        shortId: courseVideoInfo.shortId,
        assetsZip: courseVideoInfo.assetsZip,
        userId: courseVideoInfo.userId,
        courseId: courseVideoInfo.courseId,
        batchId: courseVideoInfo.batchId,
        classId: courseVideoInfo.classId
      });

      if (response.status !== 200) {
        yield put(courseVideosActions.setPhase('error'));
        return;
      }

      yield put(courseVideosActions.setCourseVideo(response.data));
      yield put(courseVideosActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_COURSE_VIDEO,
    function* deleteCourseVideoSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseVideosActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${COURSE_VIDEOS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(courseVideosActions.setPhase('error'));
        return;
      }
      yield put(courseVideosActions.removeCourseVideo(id));
      yield put(courseVideosActions.setPhase('success'));
    }
  );
}
