import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { STUDENT_DOCUMENTS_API_URL } from 'store/ApiUrls';
import produce from 'immer';

export interface IStudentDocument {
  id: number;
  disciplineId?: number;
  enrollmentDocumentId?: number;
  title?: string;
  url?: string;
  fileName?: string;
  fileMime?: string;
  fileSize?: number;
  fileData?: string;
  addedBy?: string;
  addedAt?: string;
  studentId?: number;
  schoolId?: number;
}

interface IStudentDocumentState {
  studentDocuments: IStudentDocument[];
  phase: string;
}

type TActionAllState = IStudentDocumentState & {
  id: number;
  schoolId: number;
  studentId: number;
  studentDocument: IStudentDocument;
  studentDocumentInfo: Partial<IStudentDocument>;
};

export const actionTypes = {
  PULL_STUDENT_DOCUMENTS: 'studentDocuments/PULL_STUDENT_DOCUMENTS',
  SET_STUDENT_DOCUMENTS: 'studentDocuments/SET_STUDENT_DOCUMENTS',
  ADD_STUDENT_DOCUMENT: 'studentDocuments/ADD_STUDENT_DOCUMENT',
  UPDATE_STUDENT_DOCUMENT: 'studentDocuments/UPDATE_STUDENT_DOCUMENT',
  DELETE_STUDENT_DOCUMENT: 'studentDocuments/DELETE_STUDENT_DOCUMENT',
  REMOVE_STUDENT_DOCUMENT: 'studentDocuments/REMOVE_STUDENT_DOCUMENT',
  SET_STUDENT_DOCUMENT: 'studentDocuments/SET_STUDENT_DOCUMENT',
  SET_PHASE: 'studentDocuments/SET_PHASE'
};

export const initialState: IStudentDocumentState = {
  studentDocuments: [],
  phase: null
};

export const studentDocumentsSelector = createSelector(
  (state: IStudentDocumentState) =>
    objectPath.get(state, ['students', 'studentDocuments', 'studentDocuments']),
  (studentDocuments: IStudentDocument[]) => studentDocuments
);

export const studentDocumentsPhaseSelector = createSelector(
  (state: IStudentDocumentState) =>
    objectPath.get(state, ['students', 'studentDocuments', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentDocuments' },
  (
    state: IStudentDocumentState = initialState,
    action: IAction<TActionAllState>
  ): IStudentDocumentState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_DOCUMENTS: {
        const { studentDocuments } = action.payload;
        return { ...state, studentDocuments };
      }
      case actionTypes.SET_STUDENT_DOCUMENT: {
        const { studentDocument } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentDocuments.findIndex((d) => d.id === studentDocument.id);
          if (index > -1) {
            draftState.studentDocuments[index] = studentDocument;
          } else {
            draftState.studentDocuments.unshift(studentDocument);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_DOCUMENT: {
        const { id } = action.payload;
        const studentDocuments = { ...state }.studentDocuments.filter((d) => d.id !== id);
        return { ...state, studentDocuments };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentDocumentsActions = {
  pullStudentDocuments: (
    studentId: number,
    schoolId: number
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_DOCUMENTS,
    payload: { studentId, schoolId }
  }),
  setStudentDocuments: (
    studentDocuments: IStudentDocument[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_DOCUMENTS,
    payload: { studentDocuments }
  }),
  addStudentDocument: (
    studentDocumentInfo: Partial<IStudentDocument>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_DOCUMENT,
    payload: { studentDocumentInfo }
  }),
  updateStudentDocument: (
    studentDocumentInfo: Partial<IStudentDocument>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_DOCUMENT,
    payload: { studentDocumentInfo }
  }),
  deleteStudentDocument: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_DOCUMENT,
    payload: { id }
  }),
  removeStudentDocument: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_DOCUMENT,
    payload: { id }
  }),
  setStudentDocument: (studentDocument: IStudentDocument): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_DOCUMENT,
    payload: { studentDocument }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_DOCUMENTS,
    function* pullStudentDocumentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDocumentsActions.setPhase('loading'));

      const { studentId, schoolId } = payload;
      const url = `${STUDENT_DOCUMENTS_API_URL}.json?studentId=${studentId}&schoolId=${schoolId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(studentDocumentsActions.setStudentDocuments(response.data));
      yield put(studentDocumentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_DOCUMENT,
    function* addStudentDocumentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDocumentsActions.setPhase('adding'));

      const { studentDocumentInfo } = payload;
      const response = yield axios.post(`${STUDENT_DOCUMENTS_API_URL}`, studentDocumentInfo);

      if (response.status !== 201) {
        yield put(studentDocumentsActions.setPhase('adding-error'));
        return;
      }

      yield put(studentDocumentsActions.setStudentDocument(response.data));
      yield put(studentDocumentsActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_DOCUMENT,
    function* updateStudentDocumentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDocumentsActions.setPhase('adding'));

      const { studentDocumentInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_DOCUMENTS_API_URL}/${studentDocumentInfo.id}`,
        studentDocumentInfo
      );

      if (response.status !== 200) {
        yield put(studentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(studentDocumentsActions.setStudentDocument(response.data));
      yield put(studentDocumentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_DOCUMENT,
    function* deleteStudentDocumentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentDocumentsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_DOCUMENTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(studentDocumentsActions.removeStudentDocument(id));
      yield put(studentDocumentsActions.setPhase('success'));
    }
  );
}
