import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { GUARDIANS_API_URL } from 'store/ApiUrls';
import { IGuardian } from 'pages/guardians/_store/types';

export type TPhase = null | 'loading' | 'adding' | 'updating' | 'deleting' | 'error' | 'success';

interface IGuardianState {
  guardians: IGuardian[];
  phase: TPhase;
}

type TActionAllState = IGuardianState & {
  id: number;
  activeSchool: ISchool;
  guardian: IGuardian;
  guardianInfo: Partial<IGuardian>;
};

export const actionTypes = {
  PULL_GUARDIANS: 'guardians/PULL_GUARDIANS',
  SET_GUARDIANS: 'guardians/SET_GUARDIANS',
  ADD_GUARDIAN: 'guardians/ADD_GUARDIAN',
  UPDATE_GUARDIAN: 'guardians/UPDATE_GUARDIAN',
  DELETE_GUARDIAN: 'guardians/DELETE_GUARDIAN',
  REMOVE_GUARDIAN: 'guardians/REMOVE_GUARDIAN',
  SET_GUARDIAN: 'guardians/SET_GUARDIAN',
  SET_PHASE: 'guardians/SET_PHASE'
};

export const initialState: IGuardianState = {
  guardians: [],
  phase: null
};

export const guardiansSelector = createSelector(
  (state: IGuardianState) => objectPath.get(state, ['guardians', 'guardians']),
  (guardians: IGuardian[]) => guardians
);
export const guardiansPhaseSelector = createSelector(
  (state: IGuardianState) => objectPath.get(state, ['guardians', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'guardians' },
  (state: IGuardianState = initialState, action: IAction<TActionAllState>): IGuardianState => {
    switch (action.type) {
      case actionTypes.SET_GUARDIANS: {
        const { guardians } = action.payload;
        return { ...state, guardians };
      }
      case actionTypes.SET_GUARDIAN: {
        const { guardian } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.guardians.findIndex((d) => d.id === guardian.id);
          if (index > -1) {
            draftState.guardians[index] = guardian;
          } else {
            draftState.guardians.unshift(guardian);
          }
        });
      }
      case actionTypes.REMOVE_GUARDIAN: {
        const { id } = action.payload;
        const guardians = { ...state }.guardians.filter((d) => d.id !== id);
        return { ...state, guardians };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const guardiansActions = {
  pullGuardians: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_GUARDIANS,
    payload: { activeSchool }
  }),
  setGuardians: (guardians: IGuardian[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_GUARDIANS,
    payload: { guardians }
  }),
  addGuardian: (guardianInfo: Partial<IGuardian>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_GUARDIAN,
    payload: { guardianInfo }
  }),
  updateGuardian: (guardianInfo: Partial<IGuardian>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_GUARDIAN,
    payload: { guardianInfo }
  }),
  deleteGuardian: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_GUARDIAN,
    payload: { id }
  }),
  removeGuardian: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_GUARDIAN,
    payload: { id }
  }),
  setGuardian: (guardian: IGuardian): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_GUARDIAN,
    payload: { guardian }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_GUARDIANS,
    function* pullGuardiansSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(guardiansActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${GUARDIANS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(guardiansActions.setPhase('error'));
        return;
      }

      yield put(guardiansActions.setGuardians(response.data));
      yield put(guardiansActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_GUARDIAN,
    function* addGuardiansSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(guardiansActions.setPhase('adding'));

      const { guardianInfo } = payload;
      const response = yield axios.post(`${GUARDIANS_API_URL}`, guardianInfo);

      if (response.status !== 201) {
        yield put(guardiansActions.setPhase('error'));
        return;
      }

      yield put(guardiansActions.setGuardian(response.data));
      yield put(guardiansActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_GUARDIAN,
    function* updateGuardiansSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(guardiansActions.setPhase('updating'));

      const { guardianInfo } = payload;
      const response = yield axios.patch(`${GUARDIANS_API_URL}/${guardianInfo.id}`, guardianInfo);

      if (response.status !== 200) {
        yield put(guardiansActions.setPhase('error'));
        return;
      }

      yield put(guardiansActions.setGuardian(response.data));
      yield put(guardiansActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_GUARDIAN,
    function* deleteGuardiansSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(guardiansActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${GUARDIANS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(guardiansActions.setPhase('error'));
        return;
      }

      yield put(guardiansActions.removeGuardian(id));
      yield put(guardiansActions.setPhase('success'));
    }
  );
}
