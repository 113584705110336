import React from 'react';

import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const CourseVideoList = Loadable(lazy(() => import('./CourseVideoList')));
const CourseVideoNew = Loadable(lazy(() => import('./CourseVideoNew')));
const routes = {
  path: 'video',
  children: [
    {
      path: '',
      element: <CourseVideoList />
    },
    {
      path: 'new',
      element: <CourseVideoNew />
    },
    {
      path: ':id',
      element: <CourseVideoList />
    },
    {
      path: ':id/:action',
      element: <CourseVideoList />
    }
  ]
};

export default routes;
