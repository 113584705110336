import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const ImpersonateLogs = Loadable(lazy(() => import('./ImpersonateLogs')));

const routes = {
  path: 'impersonate-log',
  children: [
    {
      path: '',
      element: <ImpersonateLogs />
    }
  ]
};

export default routes;
