import React from 'react';

import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const StudentBehaviourList = Loadable(lazy(() => import('./StudentBehaviourList')));
const StudentBehaviourNew = Loadable(lazy(() => import('./StudentBehaviourNew')));

const routes = {
  path: 'student-behaviour',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <StudentBehaviourList />
    },
    {
      path: 'new',
      element: <StudentBehaviourNew />
    },
    {
      path: ':id',
      element: <StudentBehaviourList />
    },
    {
      path: ':id/:action',
      element: <StudentBehaviourList />
    }
  ]
};

export default routes;
