import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';
import NewUser from './NewUser';

const UserList = Loadable(lazy(() => import('./UserList')));

const routes = {
  path: '',
  children: [
    {
      path: '',
      element: <UserList />
    },
    {
      path: 'new',
      element: <NewUser />
    },
    {
      path: ':id',
      element: <UserList />
    },
    {
      path: ':id/:action',
      element: <UserList />
    }
  ]
};

export default routes;
