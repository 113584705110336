import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import {
  CREATE_SHORT_LINK_URL,
  FORMS_API_URL,
  FORM_SETTINGS_API_URL,
  updateApiUrl
} from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';
import { IForm } from './data-types';
import { TLang } from 'utils/shared-types';

interface IFormState {
  forms: IForm[];
  phase: string;
}

type TActionAllState = IFormState & {
  id: number;
  activeSchool: ISchool;
  formUrl: string;
  actionType: string;
  lang: TLang;
  form: IForm;
  formInfo: Partial<IForm>;
};

export const actionTypes = {
  PULL_FORMS: 'forms/PULL_FORMS',
  SET_FORMS: 'forms/SET_FORMS',
  CREATE_PUBLIC_LINK: 'forms/CREATE_PUBLIC_LINK',
  REMOVE_PUBLIC_LINK: 'forms/REMOVE_PUBLIC_LINK',
  ADD_FORM: 'forms/ADD_FORM',
  UPDATE_FORM: 'forms/UPDATE_FORM',
  DELETE_FORM: 'forms/DELETE_FORM',
  REMOVE_FORM: 'forms/REMOVE_FORM',
  SET_FORM: 'forms/SET_FORM',
  SET_PHASE: 'forms/SET_PHASE'
};

export const initialState: IFormState = {
  forms: [],
  phase: null
};

export const formsSelector = createSelector(
  (state: IFormState) => objectPath.get(state, ['forms', 'forms']),
  (forms: IForm[]) => forms
);
export const formsPhaseSelector = createSelector(
  (state: IFormState) => objectPath.get(state, ['forms', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'forms' },
  (state: IFormState = initialState, action: IAction<TActionAllState>): IFormState => {
    switch (action.type) {
      case actionTypes.SET_FORMS: {
        const { forms } = action.payload;
        return { ...state, forms };
      }
      case actionTypes.SET_FORM: {
        const { form } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.forms.findIndex((d) => d.id === form.id);
          if (index > -1) {
            draftState.forms[index] = form;
          } else {
            draftState.forms.unshift(form);
          }
        });
      }
      case actionTypes.REMOVE_FORM: {
        const { id } = action.payload;
        const forms = { ...state }.forms.filter((d) => d.id !== id);
        return { ...state, forms };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const formsActions = {
  pullForms: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_FORMS,
    payload: { activeSchool }
  }),
  setForms: (forms: IForm[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_FORMS,
    payload: { forms }
  }),
  createPublicLink: (
    lang: TLang,
    formUrl: string,
    formInfo: Partial<IForm>,
    actionType: string,
    activeSchool: ISchool,
    id: number
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.CREATE_PUBLIC_LINK,
    payload: { lang, formUrl, formInfo, actionType, activeSchool, id }
  }),
  removePublicLink: (
    formInfo: Partial<IForm>,
    activeSchool: ISchool
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_PUBLIC_LINK,
    payload: { formInfo, activeSchool }
  }),
  addForm: (formInfo: Partial<IForm>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_FORM,
    payload: { formInfo }
  }),
  updateForm: (formInfo: Partial<IForm>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_FORM,
    payload: { formInfo }
  }),
  deleteForm: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_FORM,
    payload: { id }
  }),
  removeForm: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_FORM,
    payload: { id }
  }),
  setForm: (form: IForm): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_FORM,
    payload: { form }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_FORMS,
    function* pullFormsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${FORMS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      yield put(formsActions.setForms(response.data));
      yield put(formsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.CREATE_PUBLIC_LINK,
    function* createPublicLinkSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('loading'));

      const { lang, formUrl, formInfo, actionType, activeSchool, id } = payload;

      const createShortLinkUrl = updateApiUrl(CREATE_SHORT_LINK_URL, {
        lang: lang
      });

      const response = yield axios.post(
        createShortLinkUrl + '/' + formUrl + '/' + formInfo?.formType + '/' + id.toString()
      );

      if (response.status !== 200) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      if (response.data) {
        if (actionType === 'create') {
          const responseFormSetting = yield axios.post(`${FORM_SETTINGS_API_URL}`, {
            id: null,
            publicLink: response.data,
            form: `/api/forms/${formInfo.id}`,
            school: `/api/schools/${activeSchool.id}`,
            showHeader: '0',
            showSchoolLogo: '0',
            showSchoolName: '0',
            showFormName: '0',
            showCountdown: '0',
            showTotalApplicationCount: '0',
            showFooter: '0',
            settings: ''
          });

          if (responseFormSetting.status !== 201) {
            yield put(formsActions.setPhase('error'));
            return;
          }
        } else {
          const responseFormSetting = yield axios.patch(
            `${FORM_SETTINGS_API_URL}/${formInfo?.formSetting.id}`,
            {
              publicLink: response.data
            }
          );

          if (responseFormSetting.status !== 200) {
            yield put(formsActions.setPhase('error'));
            return;
          }
        }
        yield put(formsActions.pullForms(activeSchool));
      }
    }
  );

  yield takeLatest(
    actionTypes.REMOVE_PUBLIC_LINK,
    function* removePublicLinkSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('loading'));

      const { formInfo, activeSchool } = payload;

      const response = yield axios.patch(`${FORM_SETTINGS_API_URL}/${formInfo?.formSetting.id}`, {
        publicLink: ''
      });

      if (response.status !== 200) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      yield put(formsActions.pullForms(activeSchool));
    }
  );

  yield takeLatest(
    actionTypes.ADD_FORM,
    function* addFormSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('adding'));

      const { formInfo } = payload;
      const response = yield axios.post(`${FORMS_API_URL}`, formInfo);

      if (response.status !== 200) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      yield put(formsActions.setForm(response.data));
      yield put(formsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_FORM,
    function* updateFormSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('updating'));

      const { formInfo } = payload;
      const response = yield axios.patch(`${FORMS_API_URL}/${formInfo.id}`, formInfo);

      if (response.status !== 200) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      yield put(formsActions.setForm(response.data));
      yield put(formsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_FORM,
    function* deleteFormSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(formsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${FORMS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(formsActions.setPhase('error'));
        return;
      }

      yield put(formsActions.removeForm(id));
      yield put(formsActions.setPhase('success'));
    }
  );
}
