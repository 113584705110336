import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { DISCOUNTS_API_URL } from 'store/ApiUrls';
import { IDiscount } from './data-types';
import moment from 'moment';

interface IDiscountState {
  discounts: IDiscount[];
  allDiscounts: IDiscount[];
  phase: string;
}

type TActionAllState = IDiscountState & {
  id: number;
  activeSchool: ISchool;
  discount: IDiscount;
  discountInfo: Partial<IDiscount>;
};

export const actionTypes = {
  PULL_DISCOUNTS: 'discounts/PULL_DISCOUNTS',
  PULL_ALL_DISCOUNTS: 'discounts/PULL_ALL_DISCOUNTS',
  SET_DISCOUNTS: 'discounts/SET_DISCOUNTS',
  SET_ALL_DISCOUNTS: 'discounts/SET_ALL_DISCOUNTS',
  ADD_DISCOUNT: 'discounts/ADD_DISCOUNT',
  UPDATE_DISCOUNT: 'discounts/UPDATE_DISCOUNT',
  DELETE_DISCOUNT: 'discounts/DELETE_DISCOUNT',
  REMOVE_DISCOUNT: 'discounts/REMOVE_DISCOUNT',
  SET_DISCOUNT: 'discounts/SET_DISCOUNT',
  SET_PHASE: 'discounts/SET_PHASE'
};

export const initialState: IDiscountState = {
  discounts: [],
  allDiscounts: [],
  phase: null
};

export const discountsSelector = createSelector(
  (state: IDiscountState) => objectPath.get(state, ['discounts', 'discounts']),
  (discounts: IDiscount[]) => discounts
);

export const allDiscountsSelector = createSelector(
  (state: IDiscountState) => objectPath.get(state, ['discounts', 'allDiscounts']),
  (allDiscounts: IDiscount[]) => allDiscounts
);

export const discountsPhaseSelector = createSelector(
  (state: IDiscountState) => objectPath.get(state, ['discounts', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'discounts' },
  (state: IDiscountState = initialState, action: IAction<TActionAllState>): IDiscountState => {
    switch (action.type) {
      case actionTypes.SET_DISCOUNTS: {
        const { discounts } = action.payload;
        return { ...state, discounts };
      }
      case actionTypes.SET_ALL_DISCOUNTS: {
        const { allDiscounts } = action.payload;
        return { ...state, allDiscounts };
      }
      case actionTypes.SET_DISCOUNT: {
        const { discount } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.discounts.findIndex((d) => d.id === discount.id);
          if (index > -1) {
            draftState.discounts[index] = discount;
          } else {
            draftState.discounts.unshift(discount);
          }
        });
      }
      case actionTypes.REMOVE_DISCOUNT: {
        const { id } = action.payload;
        const discounts = { ...state }.discounts.filter((d) => d.id !== id);
        return { ...state, discounts };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const discountsActions = {
  pullDiscounts: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_DISCOUNTS,
    payload: { activeSchool }
  }),
  pullAllDiscounts: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ALL_DISCOUNTS
  }),
  setDiscounts: (discounts: IDiscount[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DISCOUNTS,
    payload: { discounts }
  }),
  setAllDiscounts: (allDiscounts: IDiscount[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ALL_DISCOUNTS,
    payload: { allDiscounts }
  }),
  addDiscount: (discountInfo: Partial<IDiscount>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_DISCOUNT,
    payload: { discountInfo }
  }),
  updateDiscount: (discountInfo: Partial<IDiscount>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_DISCOUNT,
    payload: { discountInfo }
  }),
  deleteDiscount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_DISCOUNT,
    payload: { id }
  }),
  removeDiscount: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_DISCOUNT,
    payload: { id }
  }),
  setDiscount: (discount: IDiscount): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DISCOUNT,
    payload: { discount }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_DISCOUNTS,
    function* pullDiscountsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${DISCOUNTS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(discountsActions.setPhase('error'));
        return;
      }

      yield put(discountsActions.setDiscounts(response.data));
      yield put(discountsActions.setPhase('success'));
    }
  );

  yield takeLatest(actionTypes.PULL_ALL_DISCOUNTS, function* pullAllDiscountsSaga() {
    yield put(discountsActions.setPhase('loading'));

    const url = `${DISCOUNTS_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(discountsActions.setPhase('error'));
      return;
    }

    yield put(discountsActions.setAllDiscounts(response.data));
    yield put(discountsActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_DISCOUNT,
    function* addDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountsActions.setPhase('adding'));

      const { discountInfo } = payload;

      const response = yield axios.post(`${DISCOUNTS_API_URL}`, {
        id: discountInfo?.id,
        autoApply: discountInfo?.autoApply,
        courseId: discountInfo?.courseId,
        discountAmount: discountInfo?.discountAmount,
        discountName: discountInfo?.discountName,
        discountPercentage: discountInfo?.discountPercentage,
        independent: discountInfo?.independent,
        isActive: discountInfo?.isActive,
        school: discountInfo?.school,
        startDate: discountInfo?.startDate
          ? moment(discountInfo?.startDate).format('YYYY-MM-DD')
          : '0000-00-00',
        endDate: discountInfo?.endDate
          ? moment(discountInfo?.endDate).format('YYYY-MM-DD')
          : '0000-00-00'
      });

      if (response.status !== 200) {
        yield put(discountsActions.setPhase('error'));
        return;
      }

      yield put(discountsActions.setDiscount(response.data));
      yield put(discountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_DISCOUNT,
    function* updateDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountsActions.setPhase('updating'));

      const { discountInfo } = payload;
      const response = yield axios.patch(`${DISCOUNTS_API_URL}/${discountInfo.id}`, {
        autoApply: discountInfo?.autoApply,
        courseId: discountInfo?.courseId,
        discountAmount: discountInfo?.discountAmount,
        discountName: discountInfo?.discountName,
        discountPercentage: discountInfo?.discountPercentage,
        independent: discountInfo?.independent,
        isActive: discountInfo?.isActive,
        startDate: discountInfo?.startDate
          ? moment(discountInfo?.startDate).format('YYYY-MM-DD')
          : '0000-00-00',
        endDate: discountInfo?.endDate
          ? moment(discountInfo?.endDate).format('YYYY-MM-DD')
          : '0000-00-00'
      });

      if (response.status !== 200) {
        yield put(discountsActions.setPhase('error'));
        return;
      }

      yield put(discountsActions.setDiscount(response.data));
      yield put(discountsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_DISCOUNT,
    function* deleteDiscountSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${DISCOUNTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(discountsActions.setPhase('error'));
        return;
      }
      yield put(discountsActions.removeDiscount(id));
      yield put(discountsActions.setPhase('success'));
    }
  );
}
