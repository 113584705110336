import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { HOMEWORKS_API_URL } from 'store/ApiUrls';

export interface IHomework {
  id?: number;
  title?: string;
  explanation?: string;
  homeworkType?: string;
  docs?: string;
  courseId?: number;
  branchId?: string;
  classId?: string;
  curriculumId?: string;
  batchId?: number;
  classesss?: number;
  studentId?: string;
  teacherId?: number;
  objectiveId?: string;
  evalType?: string;
  onlineReturn?: string;
  publishDate?: string;
  published?: string;
  questionOn?: string;
  ratingOn?: string;
  completionTime?: string;
  dueDate?: string;
  creator?: string;
  resultPublished?: string;
  resultPublishDate?: string;
  resultPublisher?: string;
  schoolId?: number;
}

interface IHomeworkState {
  homeworks: IHomework[];
  phase: string;
}

type TActionAllState = IHomeworkState & {
  id: number;
  activeSchool: ISchool;
  homework: IHomework;
  homeworkInfo: Partial<IHomework>;
};

export const actionTypes = {
  PULL_HOMEWORKS: 'homeworks/PULL_HOMEWORKS',
  SET_HOMEWORKS: 'homeworks/SET_HOMEWORKS',
  ADD_HOMEWORK: 'homeworks/ADD_HOMEWORK',
  UPDATE_HOMEWORK: 'homeworks/UPDATE_HOMEWORK',
  DELETE_HOMEWORK: 'homeworks/DELETE_HOMEWORK',
  REMOVE_HOMEWORK: 'homeworks/REMOVE_HOMEWORK',
  SET_HOMEWORK: 'homeworks/SET_HOMEWORK',
  SET_PHASE: 'homeworks/SET_PHASE'
};

export const initialState: IHomeworkState = {
  homeworks: [],
  phase: null
};

export const homeworksSelector = createSelector(
  (state: IHomeworkState) => objectPath.get(state, ['homeworks', 'homeworks']),
  (homeworks: IHomework[]) => homeworks
);

export const homeworksPhaseSelector = createSelector(
  (state: IHomeworkState) => objectPath.get(state, ['homeworks', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'homeworks' },
  (state: IHomeworkState = initialState, action: IAction<TActionAllState>): IHomeworkState => {
    switch (action.type) {
      case actionTypes.SET_HOMEWORKS: {
        const { homeworks } = action.payload;
        return { ...state, homeworks };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const homeworksActions = {
  pullHomeworks: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_HOMEWORKS,
    payload: { activeSchool }
  }),
  setHomeworks: (homeworks: IHomework[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_HOMEWORKS,
    payload: { homeworks }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_HOMEWORKS,
    function* pullHomeworksSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(homeworksActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${HOMEWORKS_API_URL}.json?schoolId=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(homeworksActions.setPhase('error'));
        return;
      }

      yield put(homeworksActions.setHomeworks(response.data));
      yield put(homeworksActions.setPhase('success'));
    }
  );
}
