import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const CipSeasonList = Loadable(lazy(() => import('./CipSeasonList')));
const CipSeasonNew = Loadable(lazy(() => import('./CipSeasonNew')));
const routes = {
  path: 'season',
  children: [
    {
      path: '',
      element: <CipSeasonList />
    },
    {
      path: 'new',
      element: <CipSeasonNew />
    },
    {
      path: ':id',
      element: <CipSeasonList />
    },
    {
      path: ':id/:action',
      element: <CipSeasonList />
    }
  ]
};

export default routes;
