import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { DELETED_PROMISSORIES_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { IUser } from 'pages/account/account-types';
import moment from 'moment';

export interface IDeletedPromissoryNote {
  id: number;
  promissoryId: number;
  promissoryNo?: number;
  studentId: number;
  title?: string;
  address?: string;
  promissoryDate?: string;
  amount?: number;
  promissoryOrder?: number;
  numberOfPromissory?: number;
  isPaid?: string;
  returnDate?: string;
  lawyer?: string;
  school: number;
  deletedPerson?: string;
  deletedDate?: string;
  courseId?: number;
}

interface IDeletedPromissoryNoteState {
  deletedPromissoryNotes: IDeletedPromissoryNote[];
  phase: string;
}

type TActionAllState = IDeletedPromissoryNoteState & {
  id: number;
  studentId: number;
  user: IUser;
  deletedPromissoryNote: IDeletedPromissoryNote;
  deletedPromissoryNoteInfos: Partial<IDeletedPromissoryNote[]>;
};

export const actionTypes = {
  PULL_DELETED_PROMISSORY_NOTES: 'deletedPromissoryNotes/PULL_DELETED_PROMISSORY_NOTES',
  SET_DELETED_PROMISSORY_NOTES: 'deletedPromissoryNotes/SET_DELETED_PROMISSORY_NOTES',
  ADD_DELETED_PROMISSORY_NOTE: 'deletedPromissoryNotes/ADD_PROMISSORY_NOTE',
  DELETE_DELETED_PROMISSORY_NOTE: 'deletedPromissoryNotes/ADD_DELETED_PROMISSORY_NOTE',
  REMOVE_DELETED_PROMISSORY_NOTE: 'deletedPromissoryNotes/REMOVE_DELETED_PROMISSORY_NOTE',
  SET_DELETED_PROMISSORY_NOTE: 'deletedPromissoryNotes/SET_DELETED_PROMISSORY_NOTE',
  SET_PHASE: 'deletedPromissoryNotes/SET_PHASE'
};

export const initialState: IDeletedPromissoryNoteState = {
  deletedPromissoryNotes: [],
  phase: null
};

export const deletedPromissoryNotesSelector = createSelector(
  (state: IDeletedPromissoryNoteState) =>
    objectPath.get(state, ['students', 'deletedPromissoryNotes', 'deletedPromissoryNotes']),
  (deletedPromissoryNotes: IDeletedPromissoryNote[]) => deletedPromissoryNotes
);

export const deletedPromissoryNotesPhaseSelector = createSelector(
  (state: IDeletedPromissoryNoteState) =>
    objectPath.get(state, ['students', 'deletedPromissoryNotes', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'deletedPromissoryNotes' },
  (
    state: IDeletedPromissoryNoteState = initialState,
    action: IAction<TActionAllState>
  ): IDeletedPromissoryNoteState => {
    switch (action.type) {
      case actionTypes.SET_DELETED_PROMISSORY_NOTES: {
        const { deletedPromissoryNotes } = action.payload;
        return { ...state, deletedPromissoryNotes };
      }
      case actionTypes.SET_DELETED_PROMISSORY_NOTE: {
        const { deletedPromissoryNote } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.deletedPromissoryNotes.findIndex(
            (d) => d.id === deletedPromissoryNote.id
          );
          if (index > -1) {
            draftState.deletedPromissoryNotes[index] = deletedPromissoryNote;
          } else {
            draftState.deletedPromissoryNotes.unshift(deletedPromissoryNote);
          }
        });
      }
      case actionTypes.REMOVE_DELETED_PROMISSORY_NOTE: {
        const { id } = action.payload;
        const deletedPromissoryNotes = { ...state }.deletedPromissoryNotes.filter(
          (d) => d.id !== id
        );
        return { ...state, deletedPromissoryNotes };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const deletedPromissoryNotesActions = {
  pullDeletedPromissoryNotes: (studentId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_DELETED_PROMISSORY_NOTES,
    payload: { studentId }
  }),
  setDeletedPromissoryNotes: (
    deletedPromissoryNotes: IDeletedPromissoryNote[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DELETED_PROMISSORY_NOTES,
    payload: { deletedPromissoryNotes }
  }),
  addDeletedPromissoryNote: (
    deletedPromissoryNoteInfos: Partial<IDeletedPromissoryNote[]>,
    user: IUser
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_DELETED_PROMISSORY_NOTE,
    payload: { deletedPromissoryNoteInfos, user }
  }),
  deleteDeletedPromissoryNote: (
    deletedPromissoryNoteInfos: Partial<IDeletedPromissoryNote[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_DELETED_PROMISSORY_NOTE,
    payload: { deletedPromissoryNoteInfos }
  }),
  removeDeletedPromissoryNote: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_DELETED_PROMISSORY_NOTE,
    payload: { id }
  }),
  setDeletedPromissoryNote: (
    deletedPromissoryNote: IDeletedPromissoryNote
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DELETED_PROMISSORY_NOTE,
    payload: { deletedPromissoryNote }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_DELETED_PROMISSORY_NOTES,
    function* pullDeletedPromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(deletedPromissoryNotesActions.setPhase('loading'));

      const { studentId } = payload;
      const url = `${DELETED_PROMISSORIES_API_URL}.json?studentId=${studentId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(deletedPromissoryNotesActions.setPhase('error'));
        return;
      }

      yield put(deletedPromissoryNotesActions.setDeletedPromissoryNotes(response.data));
      yield put(deletedPromissoryNotesActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_DELETED_PROMISSORY_NOTE,
    function* addDeletedPromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(deletedPromissoryNotesActions.setPhase('adding'));

      const { deletedPromissoryNoteInfos, user } = payload;

      try {
        for (let i = 0; i < deletedPromissoryNoteInfos.length; i++) {
          const response = yield axios.post(`${DELETED_PROMISSORIES_API_URL}`, {
            promissoryId: deletedPromissoryNoteInfos[i].id,
            studentId: deletedPromissoryNoteInfos[i].studentId,
            title: deletedPromissoryNoteInfos[i].title,
            address: deletedPromissoryNoteInfos[i].address,
            promissoryNo: deletedPromissoryNoteInfos[i].promissoryNo,
            promissoryDate: deletedPromissoryNoteInfos[i].promissoryDate,
            amount: deletedPromissoryNoteInfos[i].amount.toString(),
            promissoryOrder: deletedPromissoryNoteInfos[i].promissoryOrder,
            numberOfPromissory: deletedPromissoryNoteInfos[i].numberOfPromissory,
            isPaid: deletedPromissoryNoteInfos[i].isPaid,
            school: deletedPromissoryNoteInfos[i].school,
            courseId: deletedPromissoryNoteInfos[i].courseId,
            returnDate: deletedPromissoryNoteInfos[i].returnDate,
            lawyer: deletedPromissoryNoteInfos[i].lawyer,
            deletedPerson: user?.uuid,
            deletedDate: moment(new Date()).format('YYYY-MM-DD')
          });

          if (response.status === 201) {
            yield put(deletedPromissoryNotesActions.setDeletedPromissoryNote(response.data));
          }
        }

        yield put(deletedPromissoryNotesActions.setPhase('adding-success'));
      } catch (error) {
        // Update phase
        yield put(deletedPromissoryNotesActions.setPhase('adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.DELETE_DELETED_PROMISSORY_NOTE,
    function* deleteDeletedPromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(deletedPromissoryNotesActions.setPhase('loading'));

      const { deletedPromissoryNoteInfos } = payload;
      try {
        for (let i = 0; i < deletedPromissoryNoteInfos.length; i++) {
          const response = yield axios.delete(
            `${DELETED_PROMISSORIES_API_URL}/${deletedPromissoryNoteInfos[i].id}`
          );

          if (response.status === 204) {
            yield put(
              deletedPromissoryNotesActions.removeDeletedPromissoryNote(
                deletedPromissoryNoteInfos[i].id
              )
            );
          }
        }
        yield put(deletedPromissoryNotesActions.setPhase('deleting-success'));
      } catch (error) {
        yield put(deletedPromissoryNotesActions.setPhase('deleting-error'));
      }
    }
  );
}
