import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { SUBJECT_BRANCHES_API_URL } from 'store/ApiUrls';

export interface ISubjectBranch {
  id?: number;
  subjectName?: string;
  subjectShortName?: string;
  subjectColor?: string;
  countryCode?: string;
}

interface ISubjectBranchState {
  subjectBranches: ISubjectBranch[];
  phase: string;
}

type TActionAllState = ISubjectBranchState & {
  id: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_SUBJECT_BRANCHES: 'subjectBranches/PULL_SUBJECT_BRANCHES',
  SET_SUBJECT_BRANCHES: 'subjectBranches/SET_SUBJECT_BRANCHES',
  SET_PHASE: 'subjectBranches/SET_PHASE'
};

export const initialState: ISubjectBranchState = {
  subjectBranches: [],
  phase: null
};

export const subjectBranchesSelector = createSelector(
  (state: ISubjectBranchState) => objectPath.get(state, ['subjectBranches', 'subjectBranches']),
  (subjectBranches: ISubjectBranch[]) => subjectBranches
);
export const subjectBranchPhaseSelector = createSelector(
  (state: ISubjectBranchState) => objectPath.get(state, ['subjectBranches', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'subjectBranches' },
  (
    state: ISubjectBranchState = initialState,
    action: IAction<TActionAllState>
  ): ISubjectBranchState => {
    switch (action.type) {
      case actionTypes.SET_SUBJECT_BRANCHES: {
        const { subjectBranches } = action.payload;
        return { ...state, subjectBranches };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const subjectBranchesActions = {
  pullSubjectBranches: (countryCode: string) => ({
    type: actionTypes.PULL_SUBJECT_BRANCHES,
    payload: { countryCode }
  }),
  setSubjectBranches: (subjectBranches: ISubjectBranch[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SUBJECT_BRANCHES,
    payload: { subjectBranches }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_SUBJECT_BRANCHES,
    function* pullSubjectBranchesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(subjectBranchesActions.setPhase('loading'));

      const { countryCode } = payload;
      const url = `${SUBJECT_BRANCHES_API_URL}.json?countryCode=${countryCode}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(subjectBranchesActions.setPhase('error'));
        return;
      }

      yield put(subjectBranchesActions.setSubjectBranches(response.data));
      yield put(subjectBranchesActions.setPhase('success'));
    }
  );
}
