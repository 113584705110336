import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { INVOICE_DEFINITIONS_API_URL } from 'store/ApiUrls';

export interface IInvoiceDefinition {
  id: number;
  invoiceName?: string;
  paperSize?: string;
  fontSize?: string;
  institutionInfo?: string;
  customerInfo?: string;
  invoiceDate?: string;
  explanation?: string;
  price?: string;
  amount?: string;
  amountText?: string;
  total?: string;
  kdv?: string;
  grandTotal?: string;
  image?: string;
  schoolId?: number;
}

interface IInvoiceDefinitionState {
  invoiceDefinitions: IInvoiceDefinition[];
  phase: string;
}

type TActionAllState = IInvoiceDefinitionState & {
  id: number;
  activeSchool: ISchool;
  invoice: IInvoiceDefinition;
  invoiceInfo: Partial<IInvoiceDefinition>;
};

export const actionTypes = {
  PULL_INVOICE_DEFINITIONS: 'invoiceDefinitions/PULL_INVOICES',
  SET_INVOICE_DEFINITIONS: 'invoiceDefinitions/SET_INVOICES',
  SET_PHASE: 'invoiceDefinitions/SET_PHASE'
};

export const initialState: IInvoiceDefinitionState = {
  invoiceDefinitions: [],
  phase: null
};

export const invoiceDefinitionsSelector = createSelector(
  (state: IInvoiceDefinitionState) =>
    objectPath.get(state, ['invoiceDefinitions', 'invoiceDefinitions']),
  (invoiceDefinitions: IInvoiceDefinition[]) => invoiceDefinitions
);

export const invoiceDefinitionsPhaseSelector = createSelector(
  (state: IInvoiceDefinitionState) => objectPath.get(state, ['invoiceDefinitions', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'invoiceDefinitions' },
  (
    state: IInvoiceDefinitionState = initialState,
    action: IAction<TActionAllState>
  ): IInvoiceDefinitionState => {
    switch (action.type) {
      case actionTypes.SET_INVOICE_DEFINITIONS: {
        const { invoiceDefinitions } = action.payload;
        return { ...state, invoiceDefinitions };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const invoiceDefinitionActions = {
  pullInvoices: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_INVOICE_DEFINITIONS,
    payload: { activeSchool }
  }),
  setInvoices: (invoiceDefinitions: IInvoiceDefinition[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_INVOICE_DEFINITIONS,
    payload: { invoiceDefinitions }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_INVOICE_DEFINITIONS,
    function* pullInvoiceDefinitionsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(invoiceDefinitionActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${INVOICE_DEFINITIONS_API_URL}.json?schoolId=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(invoiceDefinitionActions.setPhase('error'));
        return;
      }

      yield put(invoiceDefinitionActions.setInvoices(response.data));
      yield put(invoiceDefinitionActions.setPhase('success'));
    }
  );
}
