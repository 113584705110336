import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { MESSAGE_PARAMETERS_API_URL } from 'store/ApiUrls';

export interface IMessageParameter {
  id?: number;
  variable?: string;
  explanation?: string;
  countryCode?: string;
}

interface IMessageParameterState {
  messageParameters: IMessageParameter[];
  phase: string;
}

type TActionAllState = IMessageParameterState & {
  id: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_MESSAGE_PARAMETERS: 'messageParameters/PULL_MESSAGE_PARAMETERS',
  SET_MESSAGE_PARAMETERS: 'messageParameters/SET_MESSAGE_PARAMETERS',
  SET_PHASE: 'messageParameters/SET_PHASE'
};

export const initialState: IMessageParameterState = {
  messageParameters: [],
  phase: null
};

export const messageParametersSelector = createSelector(
  (state: IMessageParameterState) =>
    objectPath.get(state, ['sms', 'messageParameters', 'messageParameters']),
  (messageParameters: IMessageParameter[]) => messageParameters
);

export const messageParametersPhaseSelector = createSelector(
  (state: IMessageParameterState) => objectPath.get(state, ['sms', 'messageParameters', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'messageParameters' },
  (
    state: IMessageParameterState = initialState,
    action: IAction<TActionAllState>
  ): IMessageParameterState => {
    switch (action.type) {
      case actionTypes.SET_MESSAGE_PARAMETERS: {
        const { messageParameters } = action.payload;
        return { ...state, messageParameters };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const messageParametersActions = {
  pullMessageParameters: (countryCode: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_MESSAGE_PARAMETERS,
    payload: { countryCode }
  }),
  setMessageParameters: (
    messageParameters: IMessageParameter[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_MESSAGE_PARAMETERS,
    payload: { messageParameters }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_MESSAGE_PARAMETERS,
    function* pullMessageParameterSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(messageParametersActions.setPhase('loading'));

      const { countryCode } = payload;
      const url = `${MESSAGE_PARAMETERS_API_URL}.json?countryCode=${countryCode}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(messageParametersActions.setPhase('error'));
        return;
      }

      yield put(messageParametersActions.setMessageParameters(response.data));
      yield put(messageParametersActions.setPhase('success'));
    }
  );
}
