import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { COMPANIES_API_URL } from 'store/ApiUrls';

export type TPhase = null | 'loading' | 'adding' | 'updating' | 'deleting' | 'error' | 'success';

export interface ICompany {
  id: number;
  companyName: string;
  officialName: string;
  companyPhone: string;
  companyFax: string;
  email: string;
  taxOffice: string;
  taxNumber: string;
  webAddress: string;
  companyLogo: string;
  address: string;
  stateId: number;
  cityId: number;
  zipCode: string;
  countryId: string;
}

interface ICompanysState {
  companies: ICompany[];
  phase: TPhase;
}

type TActionAllState = ICompanysState & {
  id: number;
  company: ICompany;
  companyInfo: Partial<ICompany>;
};

export const actionTypes = {
  PULL_COMPANIES: 'companies/PULL_COMPANIES',
  SET_COMPANIES: 'companies/SET_COMPANIES',
  ADD_COMPANY: 'companies/ADD_COMPANY',
  UPDATE_COMPANY: 'companies/UPDATE_COMPANY',
  DELETE_COMPANY: 'companies/DELETE_COMPANY',
  REMOVE_COMPANY: 'companies/REMOVE_COMPANY',
  SET_COMPANY: 'companies/SET_COMPANY',
  SET_PHASE: 'companies/SET_PHASE'
};

export const initialState: ICompanysState = {
  companies: [],
  phase: null
};

export const companySelector = createSelector(
  (state: ICompanysState) => objectPath.get(state, ['companies', 'companies']),
  (companies: ICompany[]) => companies
);
export const companyPhaseSelector = createSelector(
  (state: ICompanysState) => objectPath.get(state, ['companies', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'companies' },
  (state: ICompanysState = initialState, action: IAction<TActionAllState>): ICompanysState => {
    switch (action.type) {
      case actionTypes.SET_COMPANIES: {
        const { companies } = action.payload;
        return { ...state, companies };
      }
      case actionTypes.SET_COMPANY: {
        const { company } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.companies.findIndex((d) => d.id === company.id);
          if (index > -1) {
            draftState.companies[index] = company;
          } else {
            draftState.companies.unshift(company);
          }
        });
      }
      case actionTypes.REMOVE_COMPANY: {
        const { id } = action.payload;
        const companies = { ...state }.companies.filter((d) => d.id !== id);
        return { ...state, companies };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const companyActions = {
  pullCompanies: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_COMPANIES
  }),
  setCompanies: (companies: ICompany[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COMPANIES,
    payload: { companies }
  }),
  addCompany: (companyInfo: Partial<ICompany>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_COMPANY,
    payload: { companyInfo }
  }),
  updateCompany: (companyInfo: Partial<ICompany>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_COMPANY,
    payload: { companyInfo }
  }),
  deleteCompany: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_COMPANY,
    payload: { id }
  }),
  removeCompany: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_COMPANY,
    payload: { id }
  }),
  setCompany: (company: ICompany): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COMPANY,
    payload: { company }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_COMPANIES, function* pullCompanySaga() {
    yield put(companyActions.setPhase('loading'));

    const url = `${COMPANIES_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(companyActions.setPhase('error'));
      return;
    }

    yield put(companyActions.setCompanies(response.data));
    yield put(companyActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_COMPANY,
    function* addCompanySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(companyActions.setPhase('adding'));

      const { companyInfo } = payload;
      const response = yield axios.post(`${COMPANIES_API_URL}`, companyInfo);

      if (response.status !== 200) {
        yield put(companyActions.setPhase('error'));
        return;
      }

      yield put(companyActions.setCompany(response.data));
      yield put(companyActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_COMPANY,
    function* updateCompanySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(companyActions.setPhase('updating'));

      const { companyInfo } = payload;
      const response = yield axios.patch(`${COMPANIES_API_URL}/${companyInfo.id}`, companyInfo);

      if (response.status !== 200) {
        yield put(companyActions.setPhase('error'));
        return;
      }

      yield put(companyActions.setCompany(response.data));
      yield put(companyActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_COMPANY,
    function* deleteCompanySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(companyActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${COMPANIES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(companyActions.setPhase('error'));
        return;
      }

      yield put(companyActions.removeCompany(id));
      yield put(companyActions.setPhase('success'));
    }
  );
}
