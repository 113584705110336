import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { EXAM_SCORE_CATEGORIES_API_URL } from 'store/ApiUrls';

export interface IExamScoreCategory {
  id: number;
  pointCategory: string;
  central: string;
  school: ISchool;
}

interface IExamScoreCategoryState {
  scoreCategories: IExamScoreCategory[];
  phase: string;
}

type TActionAllState = IExamScoreCategoryState & {
  id: number;
  activeSchool: ISchool;
  scoreCategory: IExamScoreCategory;
  scoreCategoryInfo: Partial<IExamScoreCategory>;
};

export const actionTypes = {
  PULL_EXAM_SCORE_CATEGORIES: 'scoreCategories/PULL_EXAM_SCORE_CATEGORIES',
  SET_EXAM_SCORE_CATEGORIES: 'scoreCategories/SET_EXAM_SCORE_CATEGORIES',
  ADD_EXAM_SCORE_CATEGORY: 'scoreCategories/ADD_EXAM_SCORE_CATEGORY',
  UPDATE_EXAM_SCORE_CATEGORY: 'scoreCategories/UPDATE_EXAM_SCORE_CATEGORY',
  DELETE_EXAM_SCORE_CATEGORY: 'scoreCategories/DELETE_EXAM_SCORE_CATEGORY',
  REMOVE_EXAM_SCORE_CATEGORY: 'scoreCategories/REMOVE_EXAM_SCORE_CATEGORY',
  SET_EXAM_SCORE_CATEGORY: 'scoreCategories/SET_SMS_TEMPLATE',
  SET_PHASE: 'scoreCategories/SET_PHASE'
};

export const initialState: IExamScoreCategoryState = {
  scoreCategories: [],
  phase: null
};

export const scoreCategoriesSelector = createSelector(
  (state: IExamScoreCategoryState) =>
    objectPath.get(state, ['exams', 'scoreCategories', 'scoreCategories']),
  (scoreCategories: IExamScoreCategory[]) => scoreCategories
);
export const scoreCategoriesPhaseSelector = createSelector(
  (state: IExamScoreCategoryState) => objectPath.get(state, ['exams', 'scoreCategories', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'scoreCategories' },
  (
    state: IExamScoreCategoryState = initialState,
    action: IAction<TActionAllState>
  ): IExamScoreCategoryState => {
    switch (action.type) {
      case actionTypes.SET_EXAM_SCORE_CATEGORIES: {
        const { scoreCategories } = action.payload;
        return { ...state, scoreCategories };
      }
      case actionTypes.SET_EXAM_SCORE_CATEGORY: {
        const { scoreCategory } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.scoreCategories.findIndex((d) => d.id === scoreCategory.id);
          if (index > -1) {
            draftState.scoreCategories[index] = scoreCategory;
          } else {
            draftState.scoreCategories.unshift(scoreCategory);
          }
        });
      }
      case actionTypes.REMOVE_EXAM_SCORE_CATEGORY: {
        const { id } = action.payload;
        const scoreCategories = { ...state }.scoreCategories.filter((d) => d.id !== id);
        return { ...state, scoreCategories };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const scoreCategoriesActions = {
  pullScoreCategories: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_EXAM_SCORE_CATEGORIES,
    payload: { activeSchool }
  }),
  setScoreCategories: (
    scoreCategories: IExamScoreCategory[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_SCORE_CATEGORIES,
    payload: { scoreCategories }
  }),
  addScoreCategory: (
    scoreCategoryInfo: Partial<IExamScoreCategory>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_EXAM_SCORE_CATEGORY,
    payload: { scoreCategoryInfo }
  }),
  updateScoreCategory: (
    scoreCategoryInfo: Partial<IExamScoreCategory>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_EXAM_SCORE_CATEGORY,
    payload: { scoreCategoryInfo }
  }),
  deleteScoreCategory: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_EXAM_SCORE_CATEGORY,
    payload: { id }
  }),
  removeScoreCategory: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_EXAM_SCORE_CATEGORY,
    payload: { id }
  }),
  setScoreCategory: (scoreCategory: IExamScoreCategory): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_SCORE_CATEGORY,
    payload: { scoreCategory }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_EXAM_SCORE_CATEGORIES,
    function* pullScoreCategorySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scoreCategoriesActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${EXAM_SCORE_CATEGORIES_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(scoreCategoriesActions.setPhase('error'));
        return;
      }

      yield put(scoreCategoriesActions.setScoreCategories(response.data));
      yield put(scoreCategoriesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_EXAM_SCORE_CATEGORY,
    function* addScoreCategorySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scoreCategoriesActions.setPhase('adding'));

      const { scoreCategoryInfo } = payload;
      const response = yield axios.post(`${EXAM_SCORE_CATEGORIES_API_URL}`, scoreCategoryInfo);

      if (response.status !== 200) {
        yield put(scoreCategoriesActions.setPhase('error'));
        return;
      }

      yield put(scoreCategoriesActions.setScoreCategory(response.data));
      yield put(scoreCategoriesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_EXAM_SCORE_CATEGORY,
    function* updateScoreCategoryeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scoreCategoriesActions.setPhase('updating'));

      const { scoreCategoryInfo } = payload;
      const response = yield axios.patch(
        `${EXAM_SCORE_CATEGORIES_API_URL}/${scoreCategoryInfo.id}`,
        scoreCategoryInfo
      );

      if (response.status !== 200) {
        yield put(scoreCategoriesActions.setPhase('error'));
        return;
      }

      yield put(scoreCategoriesActions.setScoreCategory(response.data));
      yield put(scoreCategoriesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_EXAM_SCORE_CATEGORY,
    function* deleteScoreCategorySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(scoreCategoriesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${EXAM_SCORE_CATEGORIES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(scoreCategoriesActions.setPhase('error'));
        return;
      }

      yield put(scoreCategoriesActions.removeScoreCategory(id));
      yield put(scoreCategoriesActions.setPhase('success'));
    }
  );
}
