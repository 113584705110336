import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';
import ReportTypeNew from './ReportTypeNew';

const ReportTypeList = Loadable(lazy(() => import('./ReportTypeList')));
const StaticText = Loadable(lazy(() => import('./statics/StaticText')));

const routes = {
  path: 'report-types',
  children: [
    {
      path: '',
      element: <ReportTypeList />
    },
    {
      path: 'new',
      element: <ReportTypeNew />
    },
    {
      path: ':id',
      element: <ReportTypeList />
    },
    {
      path: ':id/:action',
      element: <ReportTypeList />
    },
    {
      path: ':id/static-text',
      element: <StaticText />
    },
    {
      path: ':id/static-text/:subaction',
      element: <StaticText />
    },
    {
      path: ':id/static-text/:subid/:subaction',
      element: <StaticText />
    }
  ]
};

export default routes;
