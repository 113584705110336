import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialog';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Page } from 'layout/Page';
import useTranslation from 'hooks/useTranslation';
import { useSubheader } from 'contexts/SubheaderContext';
import { IBreadcrumb } from 'pages/admin/menus/menu-types';
import ItemForm from './ItemForm';
import { AppDispatch, RootState } from 'store/store';
import {
  enrollmentAgreementItemsSelector,
  enrollmentAgreementItemsPhaseSelector,
  enrollmentAgreementItemsActions,
  IEnrollmentAgreementItem
} from './_store/enrollment-agreement-item';
import { ISchool } from 'pages/organization/organization-types';
import { userActiveSchoolSelector } from 'store/user';
import { ConnectedProps, connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import SchoostDialog from 'components/SchoostDialog';

const mapStateToProps = (state: RootState) => ({
  activeSchool: userActiveSchoolSelector(state),
  enrollmentAgreementItems: enrollmentAgreementItemsSelector(state),
  phase: enrollmentAgreementItemsPhaseSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  pullEnrollmentAgreementItems: (activeSchool: ISchool, enrollmentAgreementId: number) =>
    dispatch(
      enrollmentAgreementItemsActions.pullEnrollmentAgreementItems(
        activeSchool,
        enrollmentAgreementId
      )
    ),

  deleteEnrollmentAgreementItem: (id: number) =>
    dispatch(enrollmentAgreementItemsActions.deleteEnrollmentAgreementItem(id))
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TEnrollmentAgreementItemsProps = PropsFromRedux;

const ItemList: React.FC<TEnrollmentAgreementItemsProps> = (props) => {
  const {
    enrollmentAgreementItems,
    phase,
    activeSchool,
    deleteEnrollmentAgreementItem,
    pullEnrollmentAgreementItems
  } = props;
  const intl = useTranslation();
  const navigate = useNavigate();
  const subheader = useSubheader();
  const { id, subaction, subid } = useParams();

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const pageTitle = intl.translate({ id: 'enrollment.agreements.contract_items' });

  const additionalInfoText = (infoText: string) => {
    if (infoText === 'feeInfo') {
      return intl.formatMessage({ id: 'enrollment.agreements.fee_info' });
    } else if (infoText === 'discountInfo') {
      return intl.formatMessage({ id: 'enrollment.agreements.discount_info' });
    } else if (infoText === 'installmentInfo') {
      return intl.formatMessage({ id: 'enrollment.agreements.installment_info' });
    } else if (infoText === 'invoiceInfo') {
      return intl.formatMessage({ id: 'enrollment.agreements.invoice_info' });
    } else if (infoText === 'scholarshipInfo') {
      return intl.formatMessage({ id: 'enrollment.agreements.scholarship_info' });
    } else {
      return null;
    }
  };

  const handleCloseConfirm = () => {
    navigate(`/enrollment/agreement/${id}/item`);
    setShowConfirmDialog(false);
  };

  const handleDeleteConfirm = () => {
    deleteEnrollmentAgreementItem(parseInt(subid));
  };

  React.useEffect(() => {
    setShowConfirmDialog(subid && subaction === 'delete' ? true : false);

    const isDeleted =
      enrollmentAgreementItems &&
      enrollmentAgreementItems.length &&
      enrollmentAgreementItems.findIndex((g) => g.id === parseInt(subid));

    if (isDeleted === -1 && showConfirmDialog) {
      handleCloseConfirm();
    }
  }, [subid, subaction, phase, enrollmentAgreementItems, showConfirmDialog, setShowConfirmDialog]);

  React.useEffect(() => {
    const breadcrumbs: IBreadcrumb[] = [];
    breadcrumbs.push({ title: 'enrollment.agreements', url: '/enrollment/agreement' });
    breadcrumbs.push({ title: pageTitle, url: '/enrollment/agreement/new', original: true });
    subheader.setBreadcrumbs(breadcrumbs);
  }, []);

  React.useEffect(() => {
    pullEnrollmentAgreementItems(activeSchool, parseInt(id));
  }, []);

  return (
    <Page title={`${pageTitle}`}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          marginTop: '15px'
        }}
      >
        <Card sx={{ width: '100%' }}>
          <CardHeader
            title={pageTitle}
            action={
              <Button
                onClick={() => navigate(`/enrollment/agreement/${id}/item/add`)}
                startIcon={<FontAwesomeIcon className='fa-w-12' icon={faPlus} fontSize={10} />}
              >
                {intl.translate({ id: 'app.add' })}
              </Button>
            }
          />

          <Divider />
          <CardContent>
            {phase === 'loading' ? (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '15px',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <List>
                {enrollmentAgreementItems &&
                  enrollmentAgreementItems.map((item: IEnrollmentAgreementItem) => (
                    <ListItem
                      key={item.id}
                      secondaryAction={
                        <React.Fragment>
                          <IconButton
                            sx={{ p: 1 }}
                            size='small'
                            color='primary'
                            component={RouterLink}
                            to={`${item.id}/edit`}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </IconButton>
                          <IconButton
                            sx={{ p: 1 }}
                            size='small'
                            color='secondary'
                            component={RouterLink}
                            to={`${item.id}/delete`}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </IconButton>
                        </React.Fragment>
                      }
                    >
                      <ListItemIcon>
                        <ListItemText primary={item?.itemNo} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {item?.itemText}
                          </ReactMarkdown>
                        }
                        secondary={additionalInfoText(item?.additionalInfo)}
                      />
                    </ListItem>
                  ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Box>

      {subaction === 'add' && (
        <SchoostDialog
          title={intl.formatMessage({ id: 'app.add' })}
          isOpen={true}
          dividers={true}
          handleClose={() => navigate(`/enrollment/agreement/${id}/item`)}
        >
          <ItemForm
            actionType='add'
            handleClose={() => navigate(`/enrollment/agreement/${id}/item`)}
          />
        </SchoostDialog>
      )}

      {subaction === 'edit' && (
        <SchoostDialog
          title={intl.formatMessage({ id: 'app.edit' })}
          isOpen={true}
          dividers={true}
          handleClose={() => navigate(`/enrollment/agreement/${id}/item`)}
        >
          <ItemForm
            actionType='edit'
            handleClose={() => navigate(`/enrollment/agreement/${id}/item`)}
          />
        </SchoostDialog>
      )}

      <ConfirmDialog
        handleClose={handleCloseConfirm}
        handleConfirm={handleDeleteConfirm}
        isOpen={showConfirmDialog}
        phase={phase}
      />
    </Page>
  );
};

export default connector(ItemList);
