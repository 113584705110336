import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { RECEIPTS_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';

export interface IReceipt {
  id?: number;
  receiptNumber?: number;
  studentId?: number;
  receiptDate?: string;
  receiptAmount?: number;
  paymentId?: string;
  school?: number;
  courseId?: number;
}

interface IReceiptState {
  receipts: IReceipt[];
  phase: string;
}

type TActionAllState = IReceiptState & {
  id: number;
  school: ISchool;
  studentId: number;
  receipt: IReceipt;
  receiptInfo: Partial<IReceipt>;
  receiptInfos: Partial<IReceipt[]>;
};

export const actionTypes = {
  PULL_RECEIPTS: 'receipts/PULL_RECEIPTS',
  SET_RECEIPTS: 'receipts/SET_RECEIPTS',
  ADD_RECEIPT: 'receipts/ADD_RECEIPT',
  ADD_BULK_RECEIPT: 'receipts/ADD_BULK_RECEIPT',
  UPDATE_RECEIPT: 'receipts/UPDATE_RECEIPT',
  DELETE_RECEIPT: 'receipts/DELETE_RECEIPT',
  REMOVE_RECEIPT: 'receipts/REMOVE_RECEIPT',
  SET_RECEIPT: 'receipts/SET_RECEIPT',
  SET_PHASE: 'receipts/SET_PHASE'
};

export const initialState: IReceiptState = {
  receipts: [],
  phase: null
};

export const receiptsSelector = createSelector(
  (state: IReceiptState) => objectPath.get(state, ['receipts', 'receipts']),
  (receipts: IReceipt[]) => receipts
);

export const receiptsPhaseSelector = createSelector(
  (state: IReceiptState) => objectPath.get(state, ['receipts', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'receipts' },
  (state: IReceiptState = initialState, action: IAction<TActionAllState>): IReceiptState => {
    switch (action.type) {
      case actionTypes.SET_RECEIPTS: {
        const { receipts } = action.payload;
        return { ...state, receipts };
      }
      case actionTypes.SET_RECEIPT: {
        const { receipt } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.receipts.findIndex((d) => d.id === receipt.id);
          if (index > -1) {
            draftState.receipts[index] = receipt;
          } else {
            draftState.receipts.unshift(receipt);
          }
        });
      }
      case actionTypes.REMOVE_RECEIPT: {
        const { id } = action.payload;
        const receipts = { ...state }.receipts.filter((d) => d.id !== id);
        return { ...state, receipts };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const receiptsActions = {
  pullReceipts: (school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_RECEIPTS,
    payload: { school }
  }),
  setReceipts: (receipts: IReceipt[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_RECEIPTS,
    payload: { receipts }
  }),
  addReceipt: (receiptInfo: Partial<IReceipt>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_RECEIPT,
    payload: { receiptInfo }
  }),
  addBulkReceipt: (receiptInfos: Partial<IReceipt[]>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_BULK_RECEIPT,
    payload: { receiptInfos }
  }),
  updateReceipt: (receiptInfo: Partial<IReceipt>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_RECEIPT,
    payload: { receiptInfo }
  }),
  deleteReceipt: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_RECEIPT,
    payload: { id }
  }),
  removeReceipt: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_RECEIPT,
    payload: { id }
  }),
  setReceipt: (receipt: IReceipt): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_RECEIPT,
    payload: { receipt }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_RECEIPTS,
    function* pullReceiptsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(receiptsActions.setPhase('loading'));

      const { school } = payload;
      const url = `${RECEIPTS_API_URL}.json?school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(receiptsActions.setPhase('error'));
        return;
      }

      yield put(receiptsActions.setReceipts(response.data));
      yield put(receiptsActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_RECEIPT,
    function* addReceiptsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(receiptsActions.setPhase('adding'));

      const { receiptInfo } = payload;
      const response = yield axios.post(`${RECEIPTS_API_URL}`, receiptInfo);

      if (response.status !== 200) {
        yield put(receiptsActions.setPhase('error'));
        return;
      }

      yield put(receiptsActions.setReceipt(response.data));
      yield put(receiptsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_BULK_RECEIPT,
    function* addBulkReceiptsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(receiptsActions.setPhase('bulk-adding-loading'));

      const { receiptInfos } = payload;

      try {
        for (let i = 0; i < receiptInfos.length; i++) {
          const response = yield axios.post(`${RECEIPTS_API_URL}`, receiptInfos[i]);

          yield put(receiptsActions.setReceipt(response.data));
        }

        yield put(receiptsActions.setPhase('bulk-adding-success'));
      } catch {
        yield put(receiptsActions.setPhase('bulk-adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_RECEIPT,
    function* updateReceiptsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(receiptsActions.setPhase('adding'));

      const { receiptInfo } = payload;
      const response = yield axios.patch(`${RECEIPTS_API_URL}/${receiptInfo.id}`, receiptInfo);

      if (response.status !== 200) {
        yield put(receiptsActions.setPhase('error'));
        return;
      }

      yield put(receiptsActions.setReceipt(response.data));
      yield put(receiptsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_RECEIPT,
    function* deleteReceiptsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(receiptsActions.setPhase('loading'));

      const { id } = payload;
      const response = yield axios.delete(`${RECEIPTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(receiptsActions.setPhase('deleting-error'));
        return;
      }

      yield put(receiptsActions.removeReceipt(id));
      yield put(receiptsActions.setPhase('deleting-success'));
    }
  );
}
