import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction, TPhase } from 'store/store';
import { PERSONNEL_DEPARTMENTS_API_URL } from 'store/ApiUrls';
import { IPersonnelDepartment } from 'utils/shared-types';

interface IPersonnelDepartmentData {
  personnelDepartments: IPersonnelDepartment[];
  phase: string;
}
type TActionAllState = IPersonnelDepartmentData & {
  id: number;
  countryCode: string;
};

export const actionTypes = {
  PULL_PERSONNEL_DEPARTMENTS: 'personnel/PULL_PERSONNEL_DEPARTMENTS',
  SET_PERSONNEL_DEPARTMENTS: 'personnel/SET_PERSONNEL_DEPARTMENTS',
  SET_PHASE: 'personnel/SET_PHASE'
};

export const initialState: IPersonnelDepartmentData = {
  personnelDepartments: [],
  phase: null
};

export const personnelDepartmentsDataSelector = createSelector(
  (state: IPersonnelDepartmentData) =>
    objectPath.get(state, ['personnel', 'departments', 'personnelDepartments']),
  (personnelDepartments: IPersonnelDepartment[]) => personnelDepartments
);

export const reducer = persistReducer(
  { storage, key: 'personnelDepartments' },
  (
    state: IPersonnelDepartmentData = initialState,
    action: IAction<TActionAllState>
  ): IPersonnelDepartmentData => {
    switch (action.type) {
      case actionTypes.SET_PERSONNEL_DEPARTMENTS: {
        const { personnelDepartments } = action.payload;
        return { ...state, personnelDepartments };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const personnelDepartmentActions = {
  pullPersonnelDepartments: (countryCode: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_PERSONNEL_DEPARTMENTS,
    payload: { countryCode }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  // Pull personnel departments
  yield takeLatest(
    actionTypes.PULL_PERSONNEL_DEPARTMENTS,
    function* pullPersonnelDepatmentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(personnelDepartmentActions.setPhase('loading'));

      const { countryCode } = payload;
      const response = yield axios.get(
        `${PERSONNEL_DEPARTMENTS_API_URL}.json?countryCode=${countryCode}`
      );

      if (response.status !== 200) {
        yield put(personnelDepartmentActions.setPhase('error'));
        return;
      }

      yield put({
        type: actionTypes.SET_PERSONNEL_DEPARTMENTS,
        payload: { personnelDepartments: response.data }
      });
      yield put(personnelDepartmentActions.setPhase('success'));
    }
  );
}
