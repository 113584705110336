import axios from 'axios';
import { delay, put } from 'redux-saga/effects';

import { IAction } from 'store/store';
import { MAILS_API_URL } from 'store/ApiUrls';

import { phases } from '../phases';
import { TActionAllState } from '../types';
import { mailActions } from '../mailActions';
import { actionTypes } from '../actionTypes';

export function* pullEMails({ payload }: IAction<Partial<TActionAllState>>) {
  let factor = 1;

  while (true) {
    yield put(mailActions.setPhase(phases.MAILS_PULLING));

    const { page, user, section } = payload;
    let pullEmailUrl = `${MAILS_API_URL}.jsonld?user=${user.id}&order%5Bemail.sentAt%5D=desc&page=${page}`;
    if (section === 'inbox') {
      pullEmailUrl += `&isSender=false&isTo=true&isTrashed=false`;
    } else if (section === 'sent') {
      pullEmailUrl += `&isSender=true&isTo=false&isTrashed=false`;
    } else if (section === 'starred') {
      pullEmailUrl += `&isTrashed=false&isStarred=true`;
    } else if (section === 'drafts') {
      pullEmailUrl += `&isTrashed=false&isDraft=true`;
    } else if (section === 'trash') {
      pullEmailUrl += `&isTrashed=true&isDraft=false`;
    }

    const { status, data } = yield axios.get(pullEmailUrl);

    if (status !== 200) {
      yield put(mailActions.setPhase(phases.MAILS_PULLING_ERROR));
    } else {
      yield put({
        type: actionTypes.UPDATE_MAILS_IN_STORE,
        payload: { mails: data['hydra:member'], total: data['hydra:totalItems'] }
      });
      yield put(mailActions.setPhase(phases.MAILS_PULLING_SUCCESSFUL));
    }

    // Add a delay here so we can continuously pull emails behind the scene
    // use an increasing delay so that if the user is not active keep updating infrequently
    yield delay(factor * 30 * 1000);

    // Reset the factor after every 100 increment
    factor = factor > 100 ? 1 : factor + 1;
  }
}
