import { combineReducers } from 'redux';

import {
  saga as scoreCategorySagas,
  reducer as scoreCategoryReducer
} from 'pages/exam/score-template/_store/score-template';

import {
  saga as examTemplateSagas,
  reducer as examTemplateReducer
} from 'pages/exam/exam-template/_store/exam-template';

import {
  saga as examTemplateSubjectSaga,
  reducer as examTemplateSubjectReducer
} from 'pages/exam/exam-template/_store/exam-template-subject';

import {
  saga as opticFormTemplateSaga,
  reducer as opticFormTemplateReducer
} from 'pages/exam/optic-form-template/_store/optic-form-template';

import {
  saga as opticFormTemplateFieldSaga,
  reducer as opticFormTemplateFieldReducer
} from 'pages/exam/optic-form-template/_store/optic-form-template-field';

import {
  saga as opticFormFieldNameSaga,
  reducer as opticFormFieldNameReducer
} from 'pages/exam/optic-form-field-name/_store/optic-form-field-name';

export const examSagas = [
  scoreCategorySagas(),
  examTemplateSagas(),
  examTemplateSubjectSaga(),
  opticFormTemplateSaga(),
  opticFormTemplateFieldSaga(),
  opticFormFieldNameSaga()
];
export const examReducer = combineReducers({
  scoreCategories: scoreCategoryReducer,
  examTemplates: examTemplateReducer,
  examTemplateSubjects: examTemplateSubjectReducer,
  opticFormTemplates: opticFormTemplateReducer,
  opticFormTemplateFields: opticFormTemplateFieldReducer,
  opticFormFieldNames: opticFormFieldNameReducer
});
