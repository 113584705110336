import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool, ISeason } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { ACCOUNTING_SETTINGS_API_URL } from 'store/ApiUrls';

export interface IFeeSetting {
  id: number;
  maxIndependentDiscount: number;
  minEnrollmentFee: number;
  maxInstallmentCount: number;
  minDownPayment: number;
  minInstallmentAmount: number;
  maxInstallmentDate: string;
  courseId: number;
  school: ISchool;
  season: ISeason;
}

interface IFeeSettingState {
  feeSettings: IFeeSetting[];
  phase: string;
}

type TActionAllState = IFeeSettingState & {
  id: number;
  activeSchool: ISchool;
  activeSeason: ISeason;
  feeSetting: IFeeSetting;
  feeSettingInfo: Partial<IFeeSetting>;
};

export const actionTypes = {
  PULL_FEE_SETTINGS: 'feeSettings/PULL_FEE_SETTINGS',
  SET_FEE_SETTINGS: 'feeSettings/SET_FEE_SETTINGS',
  ADD_FEE_SETTING: 'feeSettings/ADD_FEE_SETTING',
  UPDATE_FEE_SETTING: 'feeSettings/UPDATE_FEE_SETTING',
  DELETE_FEE_SETTING: 'feeSettings/DELETE_FEE_SETTING',
  REMOVE_FEE_SETTING: 'feeSettings/REMOVE_FEE_SETTING',
  SET_FEE_SETTING: 'feeSettings/SET_FEE_SETTING',
  SET_PHASE: 'feeSettings/SET_PHASE'
};

export const initialState: IFeeSettingState = {
  feeSettings: [],
  phase: null
};

export const feeSettingsSelector = createSelector(
  (state: IFeeSettingState) => objectPath.get(state, ['feeSettings', 'feeSettings']),
  (feeSettings: IFeeSetting[]) => feeSettings
);
export const feeSettingsPhaseSelector = createSelector(
  (state: IFeeSettingState) => objectPath.get(state, ['feeSettings', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'feeSettings' },
  (state: IFeeSettingState = initialState, action: IAction<TActionAllState>): IFeeSettingState => {
    switch (action.type) {
      case actionTypes.SET_FEE_SETTINGS: {
        const { feeSettings } = action.payload;
        return { ...state, feeSettings };
      }
      case actionTypes.SET_FEE_SETTING: {
        const { feeSetting } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.feeSettings.findIndex((d) => d.id === feeSetting.id);
          if (index > -1) {
            draftState.feeSettings[index] = feeSetting;
          } else {
            draftState.feeSettings.unshift(feeSetting);
          }
        });
      }
      case actionTypes.REMOVE_FEE_SETTING: {
        const { id } = action.payload;
        const feeSettings = { ...state }.feeSettings.filter((d) => d.id !== id);
        return { ...state, feeSettings };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const feeSettingsActions = {
  pullFeeSettings: (
    activeSchool: ISchool,
    activeSeason: ISeason
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_FEE_SETTINGS,
    payload: { activeSchool, activeSeason }
  }),
  setFeeSettings: (feeSettings: IFeeSetting[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_FEE_SETTINGS,
    payload: { feeSettings }
  }),
  addFeeSetting: (feeSettingInfo: Partial<IFeeSetting>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_FEE_SETTING,
    payload: { feeSettingInfo }
  }),
  updateFeeSetting: (feeSettingInfo: Partial<IFeeSetting>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_FEE_SETTING,
    payload: { feeSettingInfo }
  }),
  deleteFeeSetting: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_FEE_SETTING,
    payload: { id }
  }),
  removeFeeSetting: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_FEE_SETTING,
    payload: { id }
  }),
  setFeeSetting: (feeSetting: IFeeSetting): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_FEE_SETTING,
    payload: { feeSetting }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_FEE_SETTINGS,
    function* pullFeeSettingsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(feeSettingsActions.setPhase('loading'));

      const { activeSchool, activeSeason } = payload;
      const url = `${ACCOUNTING_SETTINGS_API_URL}.json?school=${activeSchool.id}&season=${activeSeason.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(feeSettingsActions.setPhase('error'));
        return;
      }

      yield put(feeSettingsActions.setFeeSettings(response.data));
      yield put(feeSettingsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_FEE_SETTING,
    function* addFeeSettingSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(feeSettingsActions.setPhase('adding'));

      const { feeSettingInfo } = payload;

      const response = yield axios.post(`${ACCOUNTING_SETTINGS_API_URL}`, feeSettingInfo);

      if (response.status !== 200) {
        yield put(feeSettingsActions.setPhase('error'));
        return;
      }

      yield put(feeSettingsActions.setFeeSetting(response.data));
      yield put(feeSettingsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_FEE_SETTING,
    function* updateFeeSettingSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(feeSettingsActions.setPhase('updating'));

      const { feeSettingInfo } = payload;

      const response = yield axios.patch(`${ACCOUNTING_SETTINGS_API_URL}/${feeSettingInfo.id}`, {
        maxIndependentDiscount: feeSettingInfo?.maxIndependentDiscount.toString(),
        minEnrollmentFee: feeSettingInfo?.minEnrollmentFee.toString(),
        maxInstallmentCount: feeSettingInfo?.maxInstallmentCount,
        minDownPayment: feeSettingInfo?.minDownPayment.toString(),
        minInstallmentAmount: feeSettingInfo?.minInstallmentAmount.toString(),
        maxInstallmentDate: feeSettingInfo?.maxInstallmentDate
      });

      if (response.status !== 200) {
        yield put(feeSettingsActions.setPhase('error'));
        return;
      }

      yield put(feeSettingsActions.setFeeSetting(response.data));
      yield put(feeSettingsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_FEE_SETTING,
    function* deleteFeeSettingSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(feeSettingsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.patch(`${ACCOUNTING_SETTINGS_API_URL}/${id}`, {
        maxIndependentDiscount: '0',
        minEnrollmentFee: '0',
        maxInstallmentCount: 0,
        minDownPayment: '0',
        minInstallmentAmount: '0',
        maxInstallmentDate: '0000-00-00'
      });

      if (response.status !== 200) {
        yield put(feeSettingsActions.setPhase('error'));
        return;
      }

      yield put(feeSettingsActions.setFeeSetting(response.data));
      yield put(feeSettingsActions.setPhase('deleted-success'));
    }
  );
}
