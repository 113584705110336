import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool, ISeason } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { INVOICES_API_URL } from 'store/ApiUrls';

export interface IInvoice {
  id: number;
  studentId?: number;
  invoiceSerial?: string;
  invoiceNumber?: string;
  invoiceTitle?: string;
  invoiceAddress?: string;
  invoiceVd?: string;
  invoiceVno?: string;
  invoiceDate?: string;
  invoiceAmount?: number;
  explanation?: string;
  cancellation?: string;
  isActive?: string;
  seasonId?: number;
  schoolId?: number;
}

interface IInvoiceState {
  invoices: IInvoice[];
  addedInvoice: IInvoice;
  phase: string;
}

type TActionAllState = IInvoiceState & {
  id: number;
  activeSchool: ISchool;
  activeSeason: ISeason;
  studentId: number;
  invoice: IInvoice;
  invoiceInfo: Partial<IInvoice>;
};

export const actionTypes = {
  PULL_INVOICES: 'invoices/PULL_INVOICES',
  SET_INVOICES: 'invoices/SET_INVOICES',
  ADD_INVOICE: 'invoices/ADD_INVOICE',
  UPDATE_INVOICE: 'invoices/UPDATE_INVOICE',
  DELETE_INVOICE: 'invoices/DELETE_INVOICE',
  REMOVE_INVOICE: 'invoices/REMOVE_INVOICE',
  SET_INVOICE: 'invoices/SET_INVOICE',
  SET_ADDED_INVOICE: 'invoices/SET_ADDED_INVOICE',
  SET_PHASE: 'invoices/SET_PHASE'
};

export const initialState: IInvoiceState = {
  invoices: [],
  addedInvoice: null,
  phase: null
};

export const invoicesSelector = createSelector(
  (state: IInvoiceState) => objectPath.get(state, ['students', 'invoices', 'invoices']),
  (invoices: IInvoice[]) => invoices
);

export const addedInvoiceSelector = createSelector(
  (state: IInvoiceState) => objectPath.get(state, ['students', 'invoices', 'addedInvoice']),
  (addedInvoice: IInvoice) => addedInvoice
);

export const invoicesPhaseSelector = createSelector(
  (state: IInvoiceState) => objectPath.get(state, ['students', 'invoices', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'invoices' },
  (state: IInvoiceState = initialState, action: IAction<TActionAllState>): IInvoiceState => {
    switch (action.type) {
      case actionTypes.SET_INVOICES: {
        const { invoices } = action.payload;
        return { ...state, invoices };
      }
      case actionTypes.SET_INVOICE: {
        const { invoice } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.invoices.findIndex((d) => d.id === invoice.id);
          if (index > -1) {
            draftState.invoices[index] = invoice;
          } else {
            draftState.invoices.unshift(invoice);
          }
        });
      }
      case actionTypes.REMOVE_INVOICE: {
        const { id } = action.payload;
        const invoices = { ...state }.invoices.filter((d) => d.id !== id);
        return { ...state, invoices };
      }
      case actionTypes.SET_ADDED_INVOICE: {
        const { addedInvoice } = action.payload;
        return { ...state, addedInvoice };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const invoicesActions = {
  pullInvoices: (
    studentId: number,
    activeSchool: ISchool,
    activeSeason: ISeason
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_INVOICES,
    payload: { studentId, activeSchool, activeSeason }
  }),
  setInvoices: (invoices: IInvoice[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_INVOICES,
    payload: { invoices }
  }),
  addInvoice: (invoiceInfo: Partial<IInvoice>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_INVOICE,
    payload: { invoiceInfo }
  }),
  updateInvoice: (invoiceInfo: Partial<IInvoice>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_INVOICE,
    payload: { invoiceInfo }
  }),
  deleteInvoice: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_INVOICE,
    payload: { id }
  }),
  removeInvoice: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_INVOICE,
    payload: { id }
  }),
  setInvoice: (invoice: IInvoice): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_INVOICE,
    payload: { invoice }
  }),
  setAddedInvoice: (addedInvoice: IInvoice): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ADDED_INVOICE,
    payload: { addedInvoice }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_INVOICES,
    function* pullInvoicesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(invoicesActions.setPhase('loading'));

      const { studentId, activeSchool, activeSeason } = payload;
      const url = `${INVOICES_API_URL}.json?schoolId=${activeSchool.id}&studentId=${studentId}&seasonId=${activeSeason.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(invoicesActions.setPhase('error'));
        return;
      }

      yield put(invoicesActions.setInvoices(response.data));
      yield put(invoicesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_INVOICE,
    function* addInvoicesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(invoicesActions.setPhase('invoice-adding'));

      const { invoiceInfo } = payload;
      const response = yield axios.post(`${INVOICES_API_URL}`, {
        id: null,
        studentId: invoiceInfo.studentId,
        invoiceSerial: invoiceInfo.invoiceSerial,
        invoiceNumber: invoiceInfo.invoiceNumber,
        invoiceTitle: invoiceInfo.invoiceTitle,
        invoiceAddress: invoiceInfo.invoiceAddress,
        invoiceVd: invoiceInfo.invoiceVd,
        invoiceVno: invoiceInfo.invoiceVno,
        explanation: invoiceInfo.explanation,
        schoolId: invoiceInfo.schoolId,
        seasonId: invoiceInfo.seasonId,
        invoiceAmount: invoiceInfo.invoiceAmount.toString(),
        invoiceDate: invoiceInfo.invoiceDate,
        isActive: '1'
      });

      if (response.status !== 201) {
        yield put(invoicesActions.setPhase('invoice-adding-error'));
        return;
      }

      yield put(invoicesActions.setInvoice(response.data));
      yield put(invoicesActions.setAddedInvoice(response.data));
      yield put(invoicesActions.setPhase('invoice-adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_INVOICE,
    function* updateInvoicesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(invoicesActions.setPhase('updating'));

      const { invoiceInfo } = payload;
      const response = yield axios.patch(`${INVOICES_API_URL}/${invoiceInfo.id}`, invoiceInfo);

      if (response.status !== 200) {
        yield put(invoicesActions.setPhase('error'));
        return;
      }

      yield put(invoicesActions.setInvoice(response.data));
      yield put(invoicesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_INVOICE,
    function* deleteInvoicesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(invoicesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${INVOICES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(invoicesActions.setPhase('error'));
        return;
      }
      yield put(invoicesActions.removeInvoice(id));
      yield put(invoicesActions.setPhase('success'));
    }
  );
}
