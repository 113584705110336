import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction, TPhase } from 'store/store';
import { ENROLLMENT_AGREEMENT_ITEM_API_URL } from 'store/ApiUrls';

export interface IEnrollmentAgreementItem {
  id?: number;
  itemNo?: number;
  itemText: string;
  additionalInfo?: string;
  enrollmentAgreementsId?: number;
  school: ISchool;
}

interface IEnrollmentAgreementItemState {
  enrollmentAgreementItems: IEnrollmentAgreementItem[];
  phase: TPhase;
}

type TActionAllState = IEnrollmentAgreementItemState & {
  id: number;
  enrollmentAgreementId: number;
  activeSchool: ISchool;
  enrollmentAgreementItem: IEnrollmentAgreementItem;
  enrollmentAgreementItemInfo: Partial<IEnrollmentAgreementItem>;
};

export const actionTypes = {
  PULL_ENROLLMENT_AGREEMENT_ITEMS: 'enrollmentAgreementItems/PULL_ENROLLMENT_AGREEMENT_ITEMS',
  SET_ENROLLMENT_AGREEMENT_ITEMS: 'enrollmentAgreementItems/SET_ENROLLMENT_AGREEMENT_ITEMS',
  ADD_ENROLLMENT_AGREEMENT_ITEM: 'enrollmentAgreementItems/ADD_ENROLLMENT_AGREEMENT_ITEM',
  UPDATE_ENROLLMENT_AGREEMENT_ITEM: 'enrollmentAgreementItems/UPDATE_ENROLLMENT_AGREEMENT_ITEM',
  DELETE_ENROLLMENT_AGREEMENT_ITEM: 'enrollmentAgreementItems/DELETE_ENROLLMENT_AGREEMENT_ITEM',
  REMOVE_ENROLLMENT_AGREEMENT_ITEM: 'enrollmentAgreementItems/REMOVE_ENROLLMENT_AGREEMENT_ITEM',
  SET_ENROLLMENT_AGREEMENT_ITEM: 'enrollmentAgreementItems/SET_ENROLLMENT_AGREEMENT_ITEM',
  SET_PHASE: 'enrollmentAgreementItems/SET_PHASE'
};

export const initialState: IEnrollmentAgreementItemState = {
  enrollmentAgreementItems: [],
  phase: null
};

export const enrollmentAgreementItemsSelector = createSelector(
  (state: IEnrollmentAgreementItemState) =>
    objectPath.get(state, ['enrollment', 'enrollmentAgreementItems', 'enrollmentAgreementItems']),
  (enrollmentAgreementItems: IEnrollmentAgreementItem[]) => enrollmentAgreementItems
);

export const enrollmentAgreementItemsPhaseSelector = createSelector(
  (state: IEnrollmentAgreementItemState) =>
    objectPath.get(state, ['enrollment', 'enrollmentAgreementItems', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'enrollmentAgreements' },
  (
    state: IEnrollmentAgreementItemState = initialState,
    action: IAction<TActionAllState>
  ): IEnrollmentAgreementItemState => {
    switch (action.type) {
      case actionTypes.SET_ENROLLMENT_AGREEMENT_ITEMS: {
        const { enrollmentAgreementItems } = action.payload;
        return { ...state, enrollmentAgreementItems };
      }
      case actionTypes.SET_ENROLLMENT_AGREEMENT_ITEM: {
        const { enrollmentAgreementItem } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.enrollmentAgreementItems.findIndex(
            (d) => d.id === enrollmentAgreementItem.id
          );
          if (index > -1) {
            draftState.enrollmentAgreementItems[index] = enrollmentAgreementItem;
          } else {
            draftState.enrollmentAgreementItems.unshift(enrollmentAgreementItem);
          }
        });
      }
      case actionTypes.REMOVE_ENROLLMENT_AGREEMENT_ITEM: {
        const { id } = action.payload;
        const enrollmentAgreementItems = { ...state }.enrollmentAgreementItems.filter(
          (d) => d.id !== id
        );
        return { ...state, enrollmentAgreementItems };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const enrollmentAgreementItemsActions = {
  pullEnrollmentAgreementItems: (
    activeSchool: ISchool,
    enrollmentAgreementId: number
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ENROLLMENT_AGREEMENT_ITEMS,
    payload: { activeSchool, enrollmentAgreementId }
  }),
  setEnrollmentAgreementItems: (
    enrollmentAgreementItems: IEnrollmentAgreementItem[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_AGREEMENT_ITEMS,
    payload: { enrollmentAgreementItems }
  }),
  addEnrollmentAgreementItem: (
    enrollmentAgreementItemInfo: Partial<IEnrollmentAgreementItem>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_ENROLLMENT_AGREEMENT_ITEM,
    payload: { enrollmentAgreementItemInfo }
  }),
  updateEnrollmentAgreementItem: (
    enrollmentAgreementItemInfo: Partial<IEnrollmentAgreementItem>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_ENROLLMENT_AGREEMENT_ITEM,
    payload: { enrollmentAgreementItemInfo }
  }),
  deleteEnrollmentAgreementItem: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_ENROLLMENT_AGREEMENT_ITEM,
    payload: { id }
  }),
  removeEnrollmentAgreementItem: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_ENROLLMENT_AGREEMENT_ITEM,
    payload: { id }
  }),
  setEnrollmentAgreementItem: (
    enrollmentAgreementItem: IEnrollmentAgreementItem
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_AGREEMENT_ITEM,
    payload: { enrollmentAgreementItem }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_ENROLLMENT_AGREEMENT_ITEMS,
    function* pullEnrollmentAgreementItemsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementItemsActions.setPhase('loading'));

      const { activeSchool, enrollmentAgreementId } = payload;
      const url = `${ENROLLMENT_AGREEMENT_ITEM_API_URL}.json?school=${activeSchool.id}&enrollmentAgreementsId=${enrollmentAgreementId}&order%5BitemNo%5D=asc&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(enrollmentAgreementItemsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementItemsActions.setEnrollmentAgreementItems(response.data));
      yield put(enrollmentAgreementItemsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_ENROLLMENT_AGREEMENT_ITEM,
    function* addEnrollmentAgreementItemsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementItemsActions.setPhase('loading'));

      const { enrollmentAgreementItemInfo } = payload;
      const response = yield axios.post(
        `${ENROLLMENT_AGREEMENT_ITEM_API_URL}`,
        enrollmentAgreementItemInfo
      );

      if (response.status !== 201) {
        yield put(enrollmentAgreementItemsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementItemsActions.setEnrollmentAgreementItem(response.data));
      yield put(enrollmentAgreementItemsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_ENROLLMENT_AGREEMENT_ITEM,
    function* updateEnrollmentAgreementItemsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementItemsActions.setPhase('updating'));

      const { enrollmentAgreementItemInfo } = payload;
      const response = yield axios.patch(
        `${ENROLLMENT_AGREEMENT_ITEM_API_URL}/${enrollmentAgreementItemInfo.id}`,
        enrollmentAgreementItemInfo
      );

      if (response.status !== 200) {
        yield put(enrollmentAgreementItemsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentAgreementItemsActions.setEnrollmentAgreementItem(response.data));
      yield put(enrollmentAgreementItemsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_ENROLLMENT_AGREEMENT_ITEM,
    function* deleteEnrollmentAgreementItemsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentAgreementItemsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${ENROLLMENT_AGREEMENT_ITEM_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(enrollmentAgreementItemsActions.setPhase('error'));
        return;
      }
      yield put(enrollmentAgreementItemsActions.removeEnrollmentAgreementItem(id));
      yield put(enrollmentAgreementItemsActions.setPhase('success'));
    }
  );
}
