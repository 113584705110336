import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { Autocomplete, Box, Divider, Grid, TextField } from '@mui/material';

import { AppDispatch, RootState } from 'store/store';
import { userActiveSchoolSelector, userSchoolsSelector } from 'store/user';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { CancelButton, FormButtons, SaveButton } from 'utils/ActionLinks';
import { TActionType } from 'utils/shared-types';
import { ISchool } from 'pages/organization/organization-types';
import { usersActions, usersPhaseSelector, usersSelector } from './_store/user-list';
import { IUser } from 'pages/account/account-types';
import { userTypesActions, userTypesSelector } from '../types/_store/user-types';
import { IUserType } from '../types/user-types-type';
import getFlatSchools from 'utils/getFlatSchools';
import { DatePicker } from '@mui/x-date-pickers';

const mapStateToProps = (state: RootState) => ({
  activeSchool: userActiveSchoolSelector(state),
  users: usersSelector(state),
  userTypes: userTypesSelector(state),
  schools: userSchoolsSelector(state),
  phase: usersPhaseSelector(state)
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addUser: (activeSchool: ISchool, userInfo: Partial<IUser>) =>
    dispatch(usersActions.addUser(activeSchool, userInfo)),
  updateUser: (userInfo: Partial<IUser>) => dispatch(usersActions.updateUser(userInfo)),
  pullUserTypes: () => dispatch(userTypesActions.pullUserTypes())
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type TFormProps = PropsFromRedux & {
  actionType: TActionType;
  sideForm?: boolean;
  handleClose?: any;
};

const UserForm = (props: TFormProps) => {
  const {
    schools,
    actionType,
    sideForm,
    users,
    phase,
    handleClose,
    activeSchool,
    userTypes,
    pullUserTypes,
    addUser,
    updateUser
  } = props;
  const { id } = useParams();
  const intl = useTranslation();
  const [allSchools, setAllSchools] = React.useState([]);

  const { showSnackbar } = useSnackbar();

  const userInfo: IUser = users.find((u: IUser) => u.id === parseInt(id));

  const initialFormValues: Partial<IUser> = {
    id: (actionType === 'new' && null) || userInfo?.id || null,
    uuid: (actionType === 'new' && '') || userInfo?.uuid || '',
    name: (actionType === 'new' && '') || userInfo?.name || '',
    lastName: (actionType === 'new' && '') || userInfo?.lastName || '',
    email: (actionType === 'new' && '') || userInfo?.email || '',
    userTypeId: (actionType === 'new' && 0) || userInfo?.userType?.id || 0,
    schoolId: (actionType === 'new' && 0) || userInfo?.schoolId || 0,
    expiresAt:
      (actionType === 'new' && null) || (userInfo?.expiresAt != '0000-00-00' && null) || null
  };
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    initialValues,
    errors,
    isSubmitting,
    setSubmitting,
    status,
    setStatus
  } = useFormik({
    initialValues: initialFormValues,
    validate: (values) => validateForm(values),
    onSubmit: (values) => submitForm(values)
  });
  const validateForm = (values: Partial<IUser>) => {
    const errors: { [key: string]: any } = {};

    const nonEmptyFields = ['name', 'lastName', 'email', 'userTypeId'];
    if (activeSchool.type !== 'school') {
      nonEmptyFields.push('schoolId');
    }

    nonEmptyFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = intl.translate({ id: 'app.cannot_be_empty' });
      }
    });

    return errors;
  };

  const submitForm = (values: Partial<IUser>) => {
    setStatus('submitted');

    if (values !== initialValues) {
      setSubmitting(true);

      if (actionType === 'add') {
        addUser(activeSchool, values);
      } else {
        updateUser(values);
      }
    }
  };
  React.useEffect(() => {
    setStatus('notSubmitted');
  }, []);

  React.useEffect(() => {
    setSubmitting(false);

    if (status === 'submitted' && phase === 'success') {
      showSnackbar({
        message: intl.translate({ id: 'app.saved' }),
        open: true
      });

      if (!sideForm) {
        setTimeout(() => {
          handleClose();
        }, 500);
      }
    }
  }, [status, phase, setSubmitting, handleClose]);

  React.useEffect(() => {
    if (userTypes.length === 0) {
      pullUserTypes();
    }
  }, []);

  React.useEffect(() => {
    const fSchools: ISchool[] = [];
    const flatSchools = getFlatSchools(schools);
    flatSchools?.map((school: ISchool) => {
      if (school.isActive) fSchools.push(school);
    });
    setAllSchools(fSchools);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <form
        style={{ width: '100%' }}
        className='form'
        noValidate={true}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              id='name'
              className={`${errors.name ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.name ? errors.name : ''}
              error={!!errors.name}
              fullWidth={true}
              label={intl.translate({ id: 'user.name' })}
              value={values.name}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='lastName'
              className={`${errors.lastName ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.lastName ? errors.lastName : ''}
              error={!!errors.lastName}
              fullWidth={true}
              label={intl.translate({ id: 'user.lastname' })}
              value={values.lastName}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id='email'
              className={`${errors.email ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
              disabled={isSubmitting ? true : false}
              helperText={errors.email ? errors.email : ''}
              error={!!errors.email}
              fullWidth={true}
              label={intl.translate({ id: 'email.email' })}
              value={values.email}
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id='userTypeId'
              options={(userTypes && userTypes) || []}
              autoHighlight
              fullWidth
              value={(userTypes && userTypes?.find((p) => p.id === values.userTypeId)) || null}
              onChange={(_e, value: any) => setFieldValue('userTypeId', value?.id || '')}
              getOptionLabel={(option: IUserType) => option.userType}
              renderOption={(props, option: IUserType) => <li {...props}>{option.userType}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`${errors.userTypeId ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
                  disabled={isSubmitting ? true : false}
                  helperText={errors.userTypeId || ''}
                  label={intl.translate({ id: 'user.type' })}
                  value={(userTypes && userTypes?.find((p) => p.id === values.userTypeId)) || null}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label={intl.translate({ id: 'user.expiration_date' })}
              value={values.expiresAt}
              onChange={(value) => setFieldValue('expiresAt', value)}
              renderInput={(params) => <TextField id='expiresAt' fullWidth {...params} />}
            />
          </Grid>
          {activeSchool.type == 'headquarters' && (
            <Grid item xs={12} md={12}>
              <Autocomplete
                id='schoolId'
                options={(allSchools && allSchools) || []}
                autoHighlight
                fullWidth
                value={(allSchools && allSchools?.find((p) => p.id === values.schoolId)) || null}
                onChange={(_e, value: any) => setFieldValue('schoolId', value?.id || '')}
                getOptionLabel={(option: ISchool) => option.title}
                renderOption={(props, option: ISchool) => <li {...props}>{option.title}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={`${errors.schoolId ? 'is-invalid' : isSubmitting ? 'is-valid' : ''}`}
                    disabled={isSubmitting ? true : false}
                    helperText={errors.schoolId || ''}
                    label={intl.translate({ id: 'campuses_schools' })}
                    value={(userTypes && userTypes?.find((p) => p.id === values.schoolId)) || null}
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <Divider />
        <FormButtons
          saveButton={<SaveButton />}
          cancelButton={<CancelButton onClick={handleClose} />}
        />
      </form>
    </Box>
  );
};

export default connector(UserForm);
