import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { ADMISSION_INTERVIEWS_API_URL } from 'store/ApiUrls';

export interface IAdmissionInterview {
  id?: number;
  nameLastname?: string;
  citizenshipNumber?: string;
  cellPhone?: string;
  emailAddress?: string;
  courseName?: string;
  message?: string;
  occupation?: number;
  workAddress?: string;
  parentStatus?: string;
  fatherNameLastname?: string;
  fatherCitizenshipNumber?: string;
  isFatherDead?: string;
  fatherCellPhone?: string;
  fatherHomePhone?: string;
  fatherWorkPhone?: string;
  fatherEmailAddress?: string;
  fatherHomeAddress?: string;
  fatherGraduationStatus?: number;
  fatherOccupation?: number;
  fatherJobTitle?: string;
  fatherWorkPlace?: string;
  fatherWorkAddress?: string;
  motherNameLastname?: string;
  motherCitizenshipNumber?: string;
  isMotherDead?: string;
  motherCellPhone?: string;
  motherHomePhone?: string;
  motherWorkPhone?: string;
  motherEmailAddress?: string;
  motherHomeAddress?: string;
  motherGraduationStatus?: number;
  motherOccupation?: number;
  motherJobTitle?: string;
  motherWorkPlace?: string;
  motherWorkAddress?: string;
  address?: string;
  province?: number;
  district?: number;
  whereHeard?: string;
  filledBy?: 'dad';
  filledAt?: string;
  filledThrough?: 'tablet';
  interviewType?: number;
  reference?: string;
  school?: number;
}

interface IAdmissionInterviewState {
  admissionInterviews: IAdmissionInterview[];
  phase: string;
}

type TActionAllState = IAdmissionInterviewState & {
  id: number;
  activeSchool: ISchool;
  admissionInterview: IAdmissionInterview;
  admissionInterviewInfo: Partial<IAdmissionInterview>;
};

export const actionTypes = {
  PULL_ADMISSION_INTERVIEWS: 'admissionInterviews/PULL_ADMISSION_INTERVIEWS',
  SET_ADMISSION_INTERVIEWS: 'admissionInterviews/SET_ADMISSION_INTERVIEWS',
  SET_PHASE: 'admissionInterviews/SET_PHASE'
};

export const initialState: IAdmissionInterviewState = {
  admissionInterviews: [],
  phase: null
};

export const admissionInterviewsSelector = createSelector(
  (state: IAdmissionInterviewState) =>
    objectPath.get(state, ['admissionInterviews', 'admissionInterviews']),
  (admissionInterviews: IAdmissionInterview[]) => admissionInterviews
);

export const admissionInterviewsPhaseSelector = createSelector(
  (state: IAdmissionInterviewState) => objectPath.get(state, ['admissionInterviews', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'admissionInterviews' },
  (
    state: IAdmissionInterviewState = initialState,
    action: IAction<TActionAllState>
  ): IAdmissionInterviewState => {
    switch (action.type) {
      case actionTypes.SET_ADMISSION_INTERVIEWS: {
        const { admissionInterviews } = action.payload;
        return { ...state, admissionInterviews };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const admissionInterviewsActions = {
  pullAdmissionInterviews: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ADMISSION_INTERVIEWS,
    payload: { activeSchool }
  }),
  setAdmissionInterviews: (
    admissionInterviews: IAdmissionInterview[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ADMISSION_INTERVIEWS,
    payload: { admissionInterviews }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_ADMISSION_INTERVIEWS,
    function* pullAdmissionInterviewsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(admissionInterviewsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${ADMISSION_INTERVIEWS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(admissionInterviewsActions.setPhase('error'));
        return;
      }

      yield put(admissionInterviewsActions.setAdmissionInterviews(response.data));
      yield put(admissionInterviewsActions.setPhase('success'));
    }
  );
}
