import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { PAYMENTS_REPORTS_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';

export interface IPaymentReport {
  id?: number;
  previousMonth?: number;
  thisMonth?: number;
  nextMonth?: number;
  studentId?: number;
  paymentId?: number;
  installmentDate?: string;
  createdAt?: string;
  school?: number;
  courseId?: number;
}

interface IPaymentReportState {
  paymentReports: IPaymentReport[];
  phase: string;
}

type TActionAllState = IPaymentReportState & {
  id: number;
  school: ISchool;
  studentId: number;
  paymentReport: IPaymentReport;
  paymentReportInfo: Partial<IPaymentReport>;
  paymentReportInfos: Partial<IPaymentReport[]>;
};

export const actionTypes = {
  PULL_PAYMENT_REPORTS: 'paymentReports/PULL_PAYMENT_REPORTS',
  SET_PAYMENT_REPORTS: 'paymentReports/SET_PAYMENT_REPORTS',
  ADD_PAYMENT_REPORT: 'paymentReports/ADD_PAYMENT_REPORT',
  ADD_BULK_PAYMENT_REPORT: 'paymentReports/ADD_BULK_PAYMENT_REPORT',
  UPDATE_PAYMENT_REPORT: 'paymentReports/UPDATE_PAYMENT_REPORT',
  DELETE_PAYMENT_REPORT: 'paymentReports/DELETE_PAYMENT_REPORT',
  REMOVE_PAYMENT_REPORT: 'paymentReports/REMOVE_PAYMENT_REPORT',
  SET_PAYMENT_REPORT: 'paymentReports/SET_PAYMENT_REPORT',
  SET_PHASE: 'paymentReports/SET_PHASE'
};

export const initialState: IPaymentReportState = {
  paymentReports: [],
  phase: null
};

export const paymentReportsSelector = createSelector(
  (state: IPaymentReportState) => objectPath.get(state, ['paymentReports', 'paymentReports']),
  (paymentReports: IPaymentReport[]) => paymentReports
);

export const paymentReportsPhaseSelector = createSelector(
  (state: IPaymentReportState) => objectPath.get(state, ['paymentReports', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'paymentReports' },
  (
    state: IPaymentReportState = initialState,
    action: IAction<TActionAllState>
  ): IPaymentReportState => {
    switch (action.type) {
      case actionTypes.SET_PAYMENT_REPORTS: {
        const { paymentReports } = action.payload;
        return { ...state, paymentReports };
      }
      case actionTypes.SET_PAYMENT_REPORT: {
        const { paymentReport } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.paymentReports.findIndex((d) => d.id === paymentReport.id);
          if (index > -1) {
            draftState.paymentReports[index] = paymentReport;
          } else {
            draftState.paymentReports.unshift(paymentReport);
          }
        });
      }
      case actionTypes.REMOVE_PAYMENT_REPORT: {
        const { id } = action.payload;
        const paymentReports = { ...state }.paymentReports.filter((d) => d.id !== id);
        return { ...state, paymentReports };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const paymentReportsActions = {
  pullPaymentReports: (studentId: number, school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_PAYMENT_REPORTS,
    payload: { studentId, school }
  }),
  setPaymentReports: (paymentReports: IPaymentReport[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PAYMENT_REPORTS,
    payload: { paymentReports }
  }),
  addPaymentReport: (
    paymentReportInfo: Partial<IPaymentReport>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_PAYMENT_REPORT,
    payload: { paymentReportInfo }
  }),
  addBulkPaymentReport: (
    paymentReportInfos: Partial<IPaymentReport[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_BULK_PAYMENT_REPORT,
    payload: { paymentReportInfos }
  }),
  updatePaymentReport: (
    paymentReportInfo: Partial<IPaymentReport>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_PAYMENT_REPORT,
    payload: { paymentReportInfo }
  }),
  deletePaymentReport: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_PAYMENT_REPORT,
    payload: { id }
  }),
  removePaymentReport: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_PAYMENT_REPORT,
    payload: { id }
  }),
  setPaymentReport: (paymentReport: IPaymentReport): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PAYMENT_REPORT,
    payload: { paymentReport }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_PAYMENT_REPORTS,
    function* pullPaymentReportsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(paymentReportsActions.setPhase('loading'));

      const { studentId, school } = payload;
      const url = `${PAYMENTS_REPORTS_API_URL}.json?studentId=${studentId}&schoolId=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(paymentReportsActions.setPhase('error'));
        return;
      }

      yield put(paymentReportsActions.setPaymentReports(response.data));
      yield put(paymentReportsActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_PAYMENT_REPORT,
    function* addPaymentReportsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(paymentReportsActions.setPhase('adding'));

      const { paymentReportInfo } = payload;
      const response = yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, paymentReportInfo);

      if (response.status !== 200) {
        yield put(paymentReportsActions.setPhase('error'));
        return;
      }

      yield put(paymentReportsActions.setPaymentReport(response.data));
      yield put(paymentReportsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_BULK_PAYMENT_REPORT,
    function* addBulkPaymentReportsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(paymentReportsActions.setPhase('bulk-adding-loading'));

      const { paymentReportInfos } = payload;

      try {
        for (let i = 0; i < paymentReportInfos.length; i++) {
          const response = yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, paymentReportInfos[i]);

          yield put(paymentReportsActions.setPaymentReport(response.data));
        }

        yield put(paymentReportsActions.setPhase('bulk-adding-success'));
      } catch {
        yield put(paymentReportsActions.setPhase('bulk-adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_PAYMENT_REPORT,
    function* updatePaymentReportsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(paymentReportsActions.setPhase('adding'));

      const { paymentReportInfo } = payload;
      const response = yield axios.patch(
        `${PAYMENTS_REPORTS_API_URL}/${paymentReportInfo.id}`,
        paymentReportInfo
      );

      if (response.status !== 200) {
        yield put(paymentReportsActions.setPhase('error'));
        return;
      }

      yield put(paymentReportsActions.setPaymentReport(response.data));
      yield put(paymentReportsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_PAYMENT_REPORT,
    function* deletePaymentReportsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(paymentReportsActions.setPhase('loading'));

      const { id } = payload;
      const response = yield axios.delete(`${PAYMENTS_REPORTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(paymentReportsActions.setPhase('deleting-error'));
        return;
      }

      yield put(paymentReportsActions.removePaymentReport(id));
      yield put(paymentReportsActions.setPhase('deleting-success'));
    }
  );
}
