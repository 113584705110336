import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { SMS_TEMPLATES_API_URL } from 'store/ApiUrls';

export interface ISmsTemplate {
  id: number;
  templateName: string;
  templateMessage: string;
  isActive: string;
  school: ISchool;
}

interface ISmsTemplateState {
  smsTemplates: ISmsTemplate[];
  phase: string;
}

type TActionAllState = ISmsTemplateState & {
  id: number;
  activeSchool: ISchool;
  smsTemplate: ISmsTemplate;
  smsTemplateInfo: Partial<ISmsTemplate>;
};

export const actionTypes = {
  PULL_SMS_TEMPLATES: 'smsTemplates/PULL_SMS_TEMPLATES',
  SET_SMS_TEMPLATES: 'smsTemplates/SET_SMS_TEMPLATES',
  ADD_SMS_TEMPLATE: 'smsTemplates/ADD_SMS_TEMPLATE',
  UPDATE_SMS_TEMPLATE: 'smsTemplates/UPDATE_SMS_TEMPLATE',
  DELETE_SMS_TEMPLATE: 'smsTemplates/DELETE_SMS_TEMPLATE',
  REMOVE_SMS_TEMPLATE: 'smsTemplates/REMOVE_SMS_TEMPLATE',
  SET_SMS_TEMPLATE: 'smsTemplates/SET_SMS_TEMPLATE',
  SET_PHASE: 'smsTemplates/SET_PHASE'
};

export const initialState: ISmsTemplateState = {
  smsTemplates: [],
  phase: null
};

export const smsTemplatesSelector = createSelector(
  (state: ISmsTemplateState) => objectPath.get(state, ['sms', 'smsTemplates', 'smsTemplates']),
  (smsTemplates: ISmsTemplate[]) => smsTemplates
);
export const smsTemplatesPhaseSelector = createSelector(
  (state: ISmsTemplateState) => objectPath.get(state, ['sms', 'smsTemplates', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'smsTemplates' },
  (
    state: ISmsTemplateState = initialState,
    action: IAction<TActionAllState>
  ): ISmsTemplateState => {
    switch (action.type) {
      case actionTypes.SET_SMS_TEMPLATES: {
        const { smsTemplates } = action.payload;
        return { ...state, smsTemplates };
      }
      case actionTypes.SET_SMS_TEMPLATE: {
        const { smsTemplate } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.smsTemplates.findIndex((d) => d.id === smsTemplate.id);
          if (index > -1) {
            draftState.smsTemplates[index] = smsTemplate;
          } else {
            draftState.smsTemplates.unshift(smsTemplate);
          }
        });
      }
      case actionTypes.REMOVE_SMS_TEMPLATE: {
        const { id } = action.payload;
        const smsTemplates = { ...state }.smsTemplates.filter((d) => d.id !== id);
        return { ...state, smsTemplates };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const smsTemplatesActions = {
  pullSmsTemplates: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_SMS_TEMPLATES,
    payload: { activeSchool }
  }),
  setSmsTemplates: (smsTemplates: ISmsTemplate[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SMS_TEMPLATES,
    payload: { smsTemplates }
  }),
  addSmsTemplate: (smsTemplateInfo: Partial<ISmsTemplate>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_SMS_TEMPLATE,
    payload: { smsTemplateInfo }
  }),
  updateSmsTemplate: (
    smsTemplateInfo: Partial<ISmsTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_SMS_TEMPLATE,
    payload: { smsTemplateInfo }
  }),
  deleteSmsTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_SMS_TEMPLATE,
    payload: { id }
  }),
  removeSmsTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_SMS_TEMPLATE,
    payload: { id }
  }),
  setSmsTemplate: (smsTemplate: ISmsTemplate): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_SMS_TEMPLATE,
    payload: { smsTemplate }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_SMS_TEMPLATES,
    function* pullSmsTemplatesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(smsTemplatesActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${SMS_TEMPLATES_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(smsTemplatesActions.setPhase('error'));
        return;
      }

      yield put(smsTemplatesActions.setSmsTemplates(response.data));
      yield put(smsTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_SMS_TEMPLATE,
    function* addSmsTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(smsTemplatesActions.setPhase('adding'));

      const { smsTemplateInfo } = payload;
      const response = yield axios.post(`${SMS_TEMPLATES_API_URL}`, smsTemplateInfo);

      if (response.status !== 200) {
        yield put(smsTemplatesActions.setPhase('error'));
        return;
      }

      yield put(smsTemplatesActions.setSmsTemplate(response.data));
      yield put(smsTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_SMS_TEMPLATE,
    function* updateSmsTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(smsTemplatesActions.setPhase('updating'));

      const { smsTemplateInfo } = payload;
      const response = yield axios.patch(
        `${SMS_TEMPLATES_API_URL}/${smsTemplateInfo.id}`,
        smsTemplateInfo
      );

      if (response.status !== 200) {
        yield put(smsTemplatesActions.setPhase('error'));
        return;
      }

      yield put(smsTemplatesActions.setSmsTemplate(response.data));
      yield put(smsTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.REMOVE_SMS_TEMPLATE,
    function* deleteSmsTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(smsTemplatesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${SMS_TEMPLATES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(smsTemplatesActions.setPhase('error'));
        return;
      }

      yield put(smsTemplatesActions.removeSmsTemplate(id));
      yield put(smsTemplatesActions.setPhase('success'));
    }
  );
}
