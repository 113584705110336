import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { CIP_ACTIVITIES_API_URL } from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';

export interface ICipActivity {
  id?: number;
  title?: string;
  school?: ISchool;
}

interface ICipActivityState {
  cipActivities: ICipActivity[];
  phase: string;
}

type TActionAllState = ICipActivityState & {
  id: number;
  school: ISchool;
  cipActivity: ICipActivity;
  cipActivityInfo: Partial<ICipActivity>;
};

export const actionTypes = {
  PULL_CIP_ACTIVITIES: 'cipActivities/PULL_CIP_ACTIVITIES',
  SET_CIP_ACTIVITIES: 'cipActivities/SET_CIP_ACTIVITIES',
  ADD_CIP_ACTIVITY: 'cipActivities/ADD_CIP_ACTIVITY',
  UPDATE_CIP_ACTIVITY: 'cipActivities/UPDATE_CIP_ACTIVITY',
  DELETE_CIP_ACTIVITY: 'cipActivities/DELETE_CIP_ACTIVITY',
  REMOVE_CIP_ACTIVITY: 'cipActivities/REMOVE_CIP_ACTIVITY',
  SET_CIP_ACTIVITY: 'cipActivities/SET_CIP_ACTIVITY',
  SET_PHASE: 'cipActivities/SET_PHASE'
};

export const initialState: ICipActivityState = {
  cipActivities: [],
  phase: null
};

export const cipActivitiesSelector = createSelector(
  (state: ICipActivityState) => objectPath.get(state, ['cip', 'cipActivities', 'cipActivities']),
  (cipActivities: ICipActivity[]) => cipActivities
);
export const cipActivitiesPhaseSelector = createSelector(
  (state: ICipActivityState) => objectPath.get(state, ['cip', 'cipActivities', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'cipActivities' },
  (
    state: ICipActivityState = initialState,
    action: IAction<TActionAllState>
  ): ICipActivityState => {
    switch (action.type) {
      case actionTypes.SET_CIP_ACTIVITIES: {
        const { cipActivities } = action.payload;
        return { ...state, cipActivities };
      }
      case actionTypes.SET_CIP_ACTIVITY: {
        const { cipActivity } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.cipActivities.findIndex((d) => d.id === cipActivity.id);
          if (index > -1) {
            draftState.cipActivities[index] = cipActivity;
          } else {
            draftState.cipActivities.unshift(cipActivity);
          }
        });
      }
      case actionTypes.REMOVE_CIP_ACTIVITY: {
        const { id } = action.payload;
        const cipActivities = { ...state }.cipActivities.filter((d) => d.id !== id);
        return { ...state, cipActivities };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const cipActivitiesActions = {
  pullCipActivities: (school: ISchool) => ({
    type: actionTypes.PULL_CIP_ACTIVITIES,
    payload: { school }
  }),
  setCipActivities: (cipActivities: ICipActivity[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_CIP_ACTIVITIES,
    payload: { cipActivities }
  }),
  addCipActivity: (cipActivityInfo: Partial<ICipActivity>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_CIP_ACTIVITY,
    payload: { cipActivityInfo }
  }),
  updateCipActivity: (
    cipActivityInfo: Partial<ICipActivity>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_CIP_ACTIVITY,
    payload: { cipActivityInfo }
  }),
  deleteCipActivity: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_CIP_ACTIVITY,
    payload: { id }
  }),
  removeCipActivity: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_CIP_ACTIVITY,
    payload: { id }
  }),
  setCipActivity: (cipActivity: ICipActivity): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_CIP_ACTIVITY,
    payload: { cipActivity }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_CIP_ACTIVITIES,
    function* pullCipActivitiesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipActivitiesActions.setPhase('loading'));

      const { school } = payload;
      const url = `${CIP_ACTIVITIES_API_URL}.json?school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(cipActivitiesActions.setPhase('error'));
        return;
      }

      yield put(cipActivitiesActions.setCipActivities(response.data));
      yield put(cipActivitiesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_CIP_ACTIVITY,
    function* addCipActivitySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipActivitiesActions.setPhase('adding'));

      const { cipActivityInfo } = payload;
      const response = yield axios.post(`${CIP_ACTIVITIES_API_URL}`, cipActivityInfo);

      if (response.status !== 201) {
        yield put(cipActivitiesActions.setPhase('error'));
        return;
      }

      yield put(cipActivitiesActions.setCipActivity(response.data));
      yield put(cipActivitiesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_CIP_ACTIVITY,
    function* updateCipActivitySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipActivitiesActions.setPhase('updating'));

      const { cipActivityInfo } = payload;
      const response = yield axios.patch(
        `${CIP_ACTIVITIES_API_URL}/${cipActivityInfo.id}`,
        cipActivityInfo
      );

      if (response.status !== 200) {
        yield put(cipActivitiesActions.setPhase('error'));
        return;
      }

      yield put(cipActivitiesActions.setCipActivity(response.data));
      yield put(cipActivitiesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_CIP_ACTIVITY,
    function* deleteCipActivitySaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(cipActivitiesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${CIP_ACTIVITIES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(cipActivitiesActions.setPhase('error'));
        return;
      }

      yield put(cipActivitiesActions.removeCipActivity(id));
      yield put(cipActivitiesActions.setPhase('success'));
    }
  );
}
