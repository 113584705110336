import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const CipActivityList = Loadable(lazy(() => import('./CipActivityList')));
const CipActivityNew = Loadable(lazy(() => import('./CipActivityNew')));
const routes = {
  path: 'activity',
  children: [
    {
      path: '',
      element: <CipActivityList />
    },
    {
      path: 'new',
      element: <CipActivityNew />
    },
    {
      path: ':id',
      element: <CipActivityList />
    },
    {
      path: ':id/:action',
      element: <CipActivityList />
    }
  ]
};

export default routes;
