import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { EXAM_TEMPLATE_SUBJECTS_API_URL } from 'store/ApiUrls';
import { IExamTemplate } from './exam-template';
import { ISubjectBranch } from 'pages/subject-branch/_store/subject-branch';

export interface IExamTemplateSubject {
  id?: number;
  subject?: ISubjectBranch;
  numberOfQuestion?: number;
  examTemplateId?: number;
}

interface IExamTemplateSubjectState {
  examTemplateSubjects: IExamTemplateSubject[];
  phase: string;
}

type TActionAllState = IExamTemplateSubjectState & {
  id: number;
  examTemplateId: number;
  examTemplateSubject: IExamTemplateSubject;
  examTemplateSubjectInfo: Partial<IExamTemplateSubject>;
};

export const actionTypes = {
  PULL_EXAM_TEMPLATE_SUBJECTS: 'examTemplateSubjects/PULL_EXAM_TEMPLATE_SUBJECTS',
  SET_EXAM_TEMPLATE_SUBJECTS: 'examTemplateSubjects/SET_EXAM_TEMPLATE_SUBJECTS',
  ADD_EXAM_TEMPLATE_SUBJECT: 'examTemplateSubjects/ADD_EXAM_TEMPLATE_SUBJECT',
  UPDATE_EXAM_TEMPLATE_SUBJECT: 'examTemplateSubjects/UPDATE_EXAM_TEMPLATE_SUBJECT',
  DELETE_EXAM_TEMPLATE_SUBJECT: 'examTemplateSubjects/DELETE_EXAM_TEMPLATE_SUBJECT',
  REMOVE_EXAM_TEMPLATE_SUBJECT: 'examTemplateSubjects/REMOVE_EXAM_TEMPLATE_SUBJECT',
  SET_EXAM_TEMPLATE_SUBJECT: 'examTemplateSubjects/SET_EXAM_TEMPLATE_SUBJECT',
  SET_PHASE: 'examTemplateSubjects/SET_PHASE'
};

export const initialState: IExamTemplateSubjectState = {
  examTemplateSubjects: [],
  phase: null
};

export const examTemplateSubjectsSelector = createSelector(
  (state: IExamTemplateSubjectState) =>
    objectPath.get(state, ['exams', 'examTemplateSubjects', 'examTemplateSubjects']),
  (examTemplateSubjects: IExamTemplate[]) => examTemplateSubjects
);
export const examTemplateSubjectsPhaseSelector = createSelector(
  (state: IExamTemplateSubjectState) =>
    objectPath.get(state, ['exams', 'examTemplateSubjects', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'examTemplateSubjects' },
  (
    state: IExamTemplateSubjectState = initialState,
    action: IAction<TActionAllState>
  ): IExamTemplateSubjectState => {
    switch (action.type) {
      case actionTypes.SET_EXAM_TEMPLATE_SUBJECTS: {
        const { examTemplateSubjects } = action.payload;
        return { ...state, examTemplateSubjects };
      }
      case actionTypes.SET_EXAM_TEMPLATE_SUBJECT: {
        const { examTemplateSubject } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.examTemplateSubjects.findIndex(
            (d) => d.id === examTemplateSubject.id
          );
          if (index > -1) {
            draftState.examTemplateSubjects[index] = examTemplateSubject;
          } else {
            draftState.examTemplateSubjects.unshift(examTemplateSubject);
          }
        });
      }
      case actionTypes.REMOVE_EXAM_TEMPLATE_SUBJECT: {
        const { id } = action.payload;
        const examTemplateSubjects = { ...state }.examTemplateSubjects.filter((d) => d.id !== id);
        return { ...state, examTemplateSubjects };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const examTemplateSubjectsActions = {
  pullExamTemplateSubjects: (examTemplateId: number) => ({
    type: actionTypes.PULL_EXAM_TEMPLATE_SUBJECTS,
    payload: { examTemplateId }
  }),
  setExamTemplateSubjects: (
    examTemplateSubjects: IExamTemplateSubject[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_TEMPLATE_SUBJECTS,
    payload: { examTemplateSubjects }
  }),
  addExamTemplateSubject: (
    examTemplateSubjectInfo: Partial<IExamTemplateSubject>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_EXAM_TEMPLATE_SUBJECT,
    payload: { examTemplateSubjectInfo }
  }),
  updateExamTemplateSubject: (
    examTemplateSubjectInfo: Partial<IExamTemplateSubject>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_EXAM_TEMPLATE_SUBJECT,
    payload: { examTemplateSubjectInfo }
  }),
  deleteExamTemplateSubject: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_EXAM_TEMPLATE_SUBJECT,
    payload: { id }
  }),
  removeExamTemplateSubject: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_EXAM_TEMPLATE_SUBJECT,
    payload: { id }
  }),
  setExamTemplateSubject: (
    examTemplateSubject: IExamTemplateSubject
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_EXAM_TEMPLATE_SUBJECT,
    payload: { examTemplateSubject }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_EXAM_TEMPLATE_SUBJECTS,
    function* pullExamTemplatesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplateSubjectsActions.setPhase('loading'));

      const { examTemplateId } = payload;
      const url = `${EXAM_TEMPLATE_SUBJECTS_API_URL}.json?examTemplateId=${examTemplateId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(examTemplateSubjectsActions.setPhase('error'));
        return;
      }

      yield put(examTemplateSubjectsActions.setExamTemplateSubjects(response.data));
      yield put(examTemplateSubjectsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_EXAM_TEMPLATE_SUBJECT,
    function* addExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplateSubjectsActions.setPhase('adding'));

      const { examTemplateSubjectInfo } = payload;
      const response = yield axios.post(
        `${EXAM_TEMPLATE_SUBJECTS_API_URL}`,
        examTemplateSubjectInfo
      );

      if (response.status !== 201) {
        yield put(examTemplateSubjectsActions.setPhase('error'));
        return;
      }

      yield put(examTemplateSubjectsActions.setExamTemplateSubject(response.data));
      yield put(examTemplateSubjectsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_EXAM_TEMPLATE_SUBJECT,
    function* updateExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplateSubjectsActions.setPhase('updating'));

      const { examTemplateSubjectInfo } = payload;
      const response = yield axios.patch(
        `${EXAM_TEMPLATE_SUBJECTS_API_URL}/${examTemplateSubjectInfo.id}`,
        examTemplateSubjectInfo
      );

      if (response.status !== 200) {
        yield put(examTemplateSubjectsActions.setPhase('error'));
        return;
      }

      yield put(examTemplateSubjectsActions.setExamTemplateSubject(response.data));
      yield put(examTemplateSubjectsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_EXAM_TEMPLATE_SUBJECT,
    function* deleteExamTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(examTemplateSubjectsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${EXAM_TEMPLATE_SUBJECTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(examTemplateSubjectsActions.setPhase('error'));
        return;
      }

      yield put(examTemplateSubjectsActions.removeExamTemplateSubject(id));
      yield put(examTemplateSubjectsActions.setPhase('success'));
    }
  );
}
