import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { ENROLLMENT_DOCUMENTS_API_URL } from 'store/ApiUrls';

export interface IEnrollmentDocument {
  id: number;
  documentTitle: string;
  isActive: string;
  school: ISchool;
}

interface IEnrollmentDocumentState {
  enrollmentDocuments: IEnrollmentDocument[];
  phase: string;
}

type TActionAllState = IEnrollmentDocumentState & {
  id: number;
  activeSchool: ISchool;
  enrollmentDocument: IEnrollmentDocument;
  enrollmentDocumentInfo: Partial<IEnrollmentDocument>;
};

export const actionTypes = {
  PULL_ENROLLMENT_DOCUMENTS: 'enrollmentDocuments/PULL_ENROLLMENT_DOCUMENTS',
  SET_ENROLLMENT_DOCUMENTS: 'enrollmentDocuments/SET_ENROLLMENT_DOCUMENTS',
  ADD_ENROLLMENT_DOCUMENT: 'enrollmentDocuments/ADD_ENROLLMENT_DOCUMENT',
  UPDATE_ENROLLMENT_DOCUMENT: 'enrollmentDocuments/UPDATE_ENROLLMENT_DOCUMENT',
  DELETE_ENROLLMENT_DOCUMENT: 'enrollmentDocuments/DELETE_ENROLLMENT_DOCUMENT',
  REMOVE_ENROLLMENT_DOCUMENT: 'enrollmentDocuments/REMOVE_ENROLLMENT_DOCUMENT',
  SET_ENROLLMENT_DOCUMENT: 'enrollmentDocuments/SET_ENROLLMENT_DOCUMENT',
  SET_PHASE: 'enrollmentDocuments/SET_PHASE'
};

export const initialState: IEnrollmentDocumentState = {
  enrollmentDocuments: [],
  phase: null
};

export const enrollmentDocumentsSelector = createSelector(
  (state: IEnrollmentDocumentState) =>
    objectPath.get(state, ['enrollment', 'enrollmentDocuments', 'enrollmentDocuments']),
  (enrollmentDocuments: IEnrollmentDocument[]) => enrollmentDocuments
);
export const enrollmentDocumentsPhaseSelector = createSelector(
  (state: IEnrollmentDocumentState) =>
    objectPath.get(state, ['enrollment', 'enrollmentDocuments', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'enrollmentDocuments' },
  (
    state: IEnrollmentDocumentState = initialState,
    action: IAction<TActionAllState>
  ): IEnrollmentDocumentState => {
    switch (action.type) {
      case actionTypes.SET_ENROLLMENT_DOCUMENTS: {
        const { enrollmentDocuments } = action.payload;
        return { ...state, enrollmentDocuments };
      }
      case actionTypes.SET_ENROLLMENT_DOCUMENT: {
        const { enrollmentDocument } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.enrollmentDocuments.findIndex(
            (d) => d.id === enrollmentDocument.id
          );
          if (index > -1) {
            draftState.enrollmentDocuments[index] = enrollmentDocument;
          } else {
            draftState.enrollmentDocuments.unshift(enrollmentDocument);
          }
        });
      }
      case actionTypes.REMOVE_ENROLLMENT_DOCUMENT: {
        const { id } = action.payload;
        const enrollmentDocuments = { ...state }.enrollmentDocuments.filter((d) => d.id !== id);
        return { ...state, enrollmentDocuments };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const enrollmentDocumentsActions = {
  pullEnrollmentDocuments: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ENROLLMENT_DOCUMENTS,
    payload: { activeSchool }
  }),
  setEnrollmentDocuments: (
    enrollmentDocuments: IEnrollmentDocument[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_DOCUMENTS,
    payload: { enrollmentDocuments }
  }),
  addEnrollmentDocument: (
    enrollmentDocumentInfo: Partial<IEnrollmentDocument>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_ENROLLMENT_DOCUMENT,
    payload: { enrollmentDocumentInfo }
  }),
  updateEnrollmentDocument: (
    enrollmentDocumentInfo: Partial<IEnrollmentDocument>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_ENROLLMENT_DOCUMENT,
    payload: { enrollmentDocumentInfo }
  }),
  deleteEnrollmentDocument: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_ENROLLMENT_DOCUMENT,
    payload: { id }
  }),
  removeEnrollmentDocument: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_ENROLLMENT_DOCUMENT,
    payload: { id }
  }),
  setEnrollmentDocument: (
    enrollmentDocument: IEnrollmentDocument
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ENROLLMENT_DOCUMENT,
    payload: { enrollmentDocument }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_ENROLLMENT_DOCUMENTS,
    function* pullEnrollmentDocumentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentDocumentsActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${ENROLLMENT_DOCUMENTS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(enrollmentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentDocumentsActions.setEnrollmentDocuments(response.data));
      yield put(enrollmentDocumentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_ENROLLMENT_DOCUMENT,
    function* addEnrollmentDocumentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentDocumentsActions.setPhase('adding'));

      const { enrollmentDocumentInfo } = payload;
      const response = yield axios.post(`${ENROLLMENT_DOCUMENTS_API_URL}`, enrollmentDocumentInfo);

      if (response.status !== 200) {
        yield put(enrollmentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentDocumentsActions.setEnrollmentDocument(response.data));
      yield put(enrollmentDocumentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_ENROLLMENT_DOCUMENT,
    function* updateEnrollmentDocumentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentDocumentsActions.setPhase('updating'));

      const { enrollmentDocumentInfo } = payload;
      const response = yield axios.patch(
        `${ENROLLMENT_DOCUMENTS_API_URL}/${enrollmentDocumentInfo.id}`,
        enrollmentDocumentInfo
      );

      if (response.status !== 200) {
        yield put(enrollmentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentDocumentsActions.setEnrollmentDocument(response.data));
      yield put(enrollmentDocumentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_ENROLLMENT_DOCUMENT,
    function* deleteEnrollmentDocumentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(enrollmentDocumentsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${ENROLLMENT_DOCUMENTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(enrollmentDocumentsActions.setPhase('error'));
        return;
      }

      yield put(enrollmentDocumentsActions.removeEnrollmentDocument(id));
      yield put(enrollmentDocumentsActions.setPhase('success'));
    }
  );
}
