import { combineReducers } from 'redux';

import {
  saga as smsTemplateSagas,
  reducer as smsTemplateReducer
} from 'pages/sms/template/_store/sms-template';

import {
  saga as messageParameterSagas,
  reducer as messageParameterReducer
} from './message-parameter';

export const smsSagas = [smsTemplateSagas(), messageParameterSagas()];
export const smsReducer = combineReducers({
  smsTemplates: smsTemplateReducer,
  messageParameters: messageParameterReducer
});
