import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import {
  INSTALLMENTS_API_URL,
  PAYMENTS_API_URL,
  PAYMENTS_REPORTS_API_URL,
  RECEIPTS_API_URL
} from 'store/ApiUrls';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IReceipt } from 'pages/receipt/_store/receipt';
import { Iinstallment } from 'pages/installment/_store/installment';
import moment from 'moment';
import { IPaymentReport } from 'pages/reports/payment-report/_store/payment-report';

export interface IStudentPayment {
  id?: number;
  studentId?: number;
  schoolId?: number;
  paymentAmount?: number;
  paymentDate?: string;
  paymentType?: number;
  bankId?: number;
  userId?: string;
  description?: string;
  invoiceId?: number;
  installmentId?: string;
  isDownPayment?: string;
  isRefund?: number;
  createdAt?: string;
  committer?: string;
  updatedAt?: string;
  lastUpdater?: string;
  courseId?: number;
}

interface IStudentPaymentState {
  studentPayments: IStudentPayment[];
  phase: string;
}

type TActionAllState = IStudentPaymentState & {
  id: number;
  school: ISchool;
  studentId: number;
  receipts: IReceipt[];
  paymentReports: IPaymentReport[];
  installments: Iinstallment[];
  studentPayment: IStudentPayment;
  studentPaymentInfo: Partial<IStudentPayment>;
  studentPaymentInfos: Partial<IStudentPayment[]>;
};

export const actionTypes = {
  PULL_STUDENT_PAYMENTS: 'studentPayments/PULL_STUDENT_PAYMENTS',
  SET_STUDENT_PAYMENTS: 'studentPayments/SET_STUDENT_PAYMENTS',
  ADD_STUDENT_PAYMENT: 'studentPayments/ADD_STUDENT_PAYMENT',
  ADD_STUDENT_PAYMENT_REFUND: 'studentPayments/ADD_STUDENT_PAYMENT_REFUND',
  ADD_STUDENT_BULK_PAYMENT: 'studentPayments/ADD_STUDENT_BULK_PAYMENT',
  UPDATE_STUDENT_PAYMENT: 'studentPayments/UPDATE_STUDENT_PAYMENT',
  DELETE_STUDENT_PAYMENT: 'studentPayments/DELETE_STUDENT_PAYMENT',
  REMOVE_STUDENT_PAYMENT: 'studentPayments/REMOVE_STUDENT_PAYMENT',
  SET_STUDENT_PAYMENT: 'studentPayments/SET_STUDENT_PAYMENT',
  SET_PHASE: 'studentPayments/SET_PHASE'
};

export const initialState: IStudentPaymentState = {
  studentPayments: [],
  phase: null
};

export const studentPaymentsSelector = createSelector(
  (state: IStudentPaymentState) =>
    objectPath.get(state, ['students', 'studentPayments', 'studentPayments']),
  (studentPayments: IStudentPayment[]) => studentPayments
);

export const studentPaymentsPhaseSelector = createSelector(
  (state: IStudentPaymentState) => objectPath.get(state, ['students', 'studentPayments', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentPayments' },
  (
    state: IStudentPaymentState = initialState,
    action: IAction<TActionAllState>
  ): IStudentPaymentState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_PAYMENTS: {
        const { studentPayments } = action.payload;
        return { ...state, studentPayments };
      }
      case actionTypes.SET_STUDENT_PAYMENT: {
        const { studentPayment } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentPayments.findIndex((d) => d.id === studentPayment.id);
          if (index > -1) {
            draftState.studentPayments[index] = studentPayment;
          } else {
            draftState.studentPayments.unshift(studentPayment);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_PAYMENT: {
        const { id } = action.payload;
        const studentPayments = { ...state }.studentPayments.filter((d) => d.id !== id);
        return { ...state, studentPayments };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentPaymentsActions = {
  pullStudentPayments: (studentId: number, school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_PAYMENTS,
    payload: { studentId, school }
  }),
  setStudentPayments: (studentPayments: IStudentPayment[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_PAYMENTS,
    payload: { studentPayments }
  }),
  addStudentPayment: (
    studentPaymentInfo: Partial<IStudentPayment>,
    receipts: IReceipt[],
    installments: Partial<Iinstallment[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_PAYMENT,
    payload: { studentPaymentInfo, receipts, installments }
  }),
  addStudentPaymentRefund: (
    studentPaymentInfo: Partial<IStudentPayment>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_PAYMENT_REFUND,
    payload: { studentPaymentInfo }
  }),
  addStudentBulkPayment: (
    studentPaymentInfos: Partial<IStudentPayment[]>,
    receipts: IReceipt[],
    installments: Iinstallment[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_BULK_PAYMENT,
    payload: { studentPaymentInfos, receipts, installments }
  }),
  updateStudentPayment: (
    studentPaymentInfo: Partial<IStudentPayment>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_PAYMENT,
    payload: { studentPaymentInfo }
  }),
  deleteStudentPayment: (
    studentPaymentInfo: IStudentPayment,
    installments: Iinstallment[],
    receipts: IReceipt[],
    paymentReports: IPaymentReport[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_PAYMENT,
    payload: { studentPaymentInfo, installments, receipts, paymentReports }
  }),
  removeStudentPayment: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_PAYMENT,
    payload: { id }
  }),
  setStudentPayment: (studentPayment: IStudentPayment): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_PAYMENT,
    payload: { studentPayment }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_PAYMENTS,
    function* pullStudentPaymentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('loading'));

      const { studentId, school } = payload;
      const url = `${PAYMENTS_API_URL}.json?studentId=${studentId}&schoolId=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentPaymentsActions.setPhase('error'));
        return;
      }

      yield put(studentPaymentsActions.setStudentPayments(response.data));
      yield put(studentPaymentsActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_PAYMENT_REFUND,
    function* addStudentPaymentRefundSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('adding-loading'));

      const { studentPaymentInfo } = payload;
      const response = yield axios.post(`${PAYMENTS_API_URL}`, {
        id: null,
        schoolId: studentPaymentInfo.schoolId,
        studentId: studentPaymentInfo.studentId,
        paymentAmount: '-' + studentPaymentInfo.paymentAmount.toString(),
        paymentDate: moment(studentPaymentInfo.paymentDate).format('YYYY-MM-DD HH:mm:ss'),
        description: studentPaymentInfo.description,
        paymentType: studentPaymentInfo.paymentType,
        bankId: 0,
        installmentId: '0',
        userId: studentPaymentInfo.userId,
        isDownPayment: '0',
        createdAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        committer: studentPaymentInfo.committer,
        courseId: studentPaymentInfo.courseId,
        isRefund: studentPaymentInfo.isRefund
      });

      if (response.status !== 201) {
        yield put(studentPaymentsActions.setPhase('adding-error'));
        return;
      }

      yield put(studentPaymentsActions.setStudentPayment(response.data));
      yield put(studentPaymentsActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_PAYMENT,
    function* addStudentPaymentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('adding-loading'));

      const { studentPaymentInfo, receipts, installments } = payload;
      const response = yield axios.post(`${PAYMENTS_API_URL}`, studentPaymentInfo);

      if (response.status === 201) {
        const maxReceiptNo = Math.max.apply(
          null,
          receipts.map((r) => r.receiptNumber)
        );

        const newReceiptNo = maxReceiptNo
          ? maxReceiptNo + 1
          : studentPaymentInfo.schoolId * 100000 + 1;

        for (let i = 0; i < installments.length; i++) {
          yield axios.patch(`${INSTALLMENTS_API_URL}/${installments[i].id}`, {
            isDonePayment: installments[i].isDonePayment
          });

          if (
            moment(installments[i]?.installmentDate).format('YYYY-MM') <
            moment(new Date()).format('YYYY-MM')
          ) {
            yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
              id: null,
              studentId: studentPaymentInfo.studentId,
              paymentId: response.data.id,
              installmentDate: studentPaymentInfo.paymentDate,
              createdAt: studentPaymentInfo.createdAt,
              school: studentPaymentInfo.schoolId,
              courseId: studentPaymentInfo.courseId,
              previousMonth: installments[i]?.amount.toString(),
              thisMonth: '0',
              nextMonth: '0'
            });
          } else if (
            moment(installments[i]?.installmentDate).format('YYYY-MM') >
            moment(new Date()).format('YYYY-MM')
          ) {
            yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
              id: null,
              studentId: studentPaymentInfo.studentId,
              paymentId: response.data.id,
              installmentDate: studentPaymentInfo.paymentDate,
              createdAt: studentPaymentInfo.createdAt,
              school: studentPaymentInfo.schoolId,
              courseId: studentPaymentInfo.courseId,
              nextMonth: installments[i]?.amount.toString(),
              previousMonth: '0',
              thisMonth: '0'
            });
          } else {
            yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
              id: null,
              studentId: studentPaymentInfo.studentId,
              paymentId: response.data.id,
              installmentDate: studentPaymentInfo.paymentDate,
              createdAt: studentPaymentInfo.createdAt,
              school: studentPaymentInfo.schoolId,
              courseId: studentPaymentInfo.courseId,
              thisMonth: installments[i]?.amount.toString(),
              previousMonth: '0',
              nextMonth: '0'
            });
          }
        }

        yield axios.post(`${RECEIPTS_API_URL}`, {
          id: null,
          receiptNumber: newReceiptNo,
          studentId: studentPaymentInfo.studentId,
          receiptDate: studentPaymentInfo.paymentDate,
          receiptAmount: studentPaymentInfo.paymentAmount.toString(),
          paymentId: response.data.id.toString(),
          school: studentPaymentInfo.schoolId,
          courseId: studentPaymentInfo.courseId
        });
      } else {
        yield put(studentPaymentsActions.setPhase('adding-error'));
        return;
      }

      yield put(studentPaymentsActions.setStudentPayment(response.data));
      yield put(studentPaymentsActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_BULK_PAYMENT,
    function* addStudentBulkPaymentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('adding-loading'));

      const { studentPaymentInfos, receipts, installments } = payload;

      try {
        for (let i = 0; i < studentPaymentInfos.length; i++) {
          const response = yield axios.post(`${PAYMENTS_API_URL}`, {
            id: null,
            schoolId: studentPaymentInfos[i].schoolId,
            studentId: studentPaymentInfos[i].studentId,
            paymentAmount: studentPaymentInfos[i].paymentAmount.toString(),
            paymentDate: studentPaymentInfos[i].paymentDate,
            description: studentPaymentInfos[i].description,
            paymentType: studentPaymentInfos[i].paymentType,
            bankId: studentPaymentInfos[i].bankId,
            installmentId: studentPaymentInfos[i].installmentId,
            userId: studentPaymentInfos[i].userId,
            isDownPayment: studentPaymentInfos[i].isDownPayment,
            createdAt: studentPaymentInfos[i].createdAt,
            committer: studentPaymentInfos[i].committer,
            courseId: studentPaymentInfos[i].courseId,
            isRefund: 0
          });

          if (response.status === 201) {
            yield put(studentPaymentsActions.setStudentPayment(response.data));

            const installmentInfo = installments.find(
              (row) => row.id === parseInt(studentPaymentInfos[i].installmentId)
            );
            const maxReceiptNo = Math.max.apply(
              null,
              receipts.map((r) => r.receiptNumber)
            );

            const newReceiptNo = maxReceiptNo
              ? maxReceiptNo + i + 1
              : studentPaymentInfos[i].schoolId * 100000 + i + 1;

            yield axios.patch(`${INSTALLMENTS_API_URL}/${studentPaymentInfos[i].installmentId}`, {
              isDonePayment: 1
            });

            if (
              moment(installmentInfo?.installmentDate).format('YYYY-MM') <
              moment(new Date()).format('YYYY-MM')
            ) {
              yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
                id: null,
                studentId: studentPaymentInfos[i].studentId,
                paymentId: response.data.id,
                installmentDate: studentPaymentInfos[i].paymentDate,
                createdAt: studentPaymentInfos[i].createdAt,
                school: studentPaymentInfos[i].schoolId,
                courseId: studentPaymentInfos[i].courseId,
                previousMonth: studentPaymentInfos[i].paymentAmount.toString(),
                thisMonth: '0',
                nextMonth: '0'
              });
            } else if (
              moment(installmentInfo?.installmentDate).format('YYYY-MM') >
              moment(new Date()).format('YYYY-MM')
            ) {
              yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
                id: null,
                studentId: studentPaymentInfos[i].studentId,
                paymentId: response.data.id,
                installmentDate: studentPaymentInfos[i].paymentDate,
                createdAt: studentPaymentInfos[i].createdAt,
                school: studentPaymentInfos[i].schoolId,
                courseId: studentPaymentInfos[i].courseId,
                nextMonth: studentPaymentInfos[i].paymentAmount.toString(),
                previousMonth: '0',
                thisMonth: '0'
              });
            } else {
              yield axios.post(`${PAYMENTS_REPORTS_API_URL}`, {
                id: null,
                studentId: studentPaymentInfos[i].studentId,
                paymentId: response.data.id,
                installmentDate: studentPaymentInfos[i].paymentDate,
                createdAt: studentPaymentInfos[i].createdAt,
                school: studentPaymentInfos[i].schoolId,
                courseId: studentPaymentInfos[i].courseId,
                thisMonth: studentPaymentInfos[i].paymentAmount.toString(),
                previousMonth: '0',
                nextMonth: '0'
              });
            }

            yield axios.post(`${RECEIPTS_API_URL}`, {
              id: null,
              receiptNumber: newReceiptNo,
              studentId: studentPaymentInfos[i].studentId,
              receiptDate: studentPaymentInfos[i].paymentDate,
              receiptAmount: studentPaymentInfos[i].paymentAmount.toString(),
              paymentId: response.data.id.toString(),
              school: studentPaymentInfos[i].schoolId,
              courseId: studentPaymentInfos[i].courseId
            });
          }
        }

        yield put(studentPaymentsActions.setPhase('adding-success'));
      } catch {
        yield put(studentPaymentsActions.setPhase('adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_PAYMENT,
    function* updateStudentPaymentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('updating'));

      const { studentPaymentInfo } = payload;
      const response = yield axios.patch(`${PAYMENTS_API_URL}/${studentPaymentInfo.id}`, {
        bankId: studentPaymentInfo?.bankId,
        description: studentPaymentInfo?.description,
        paymentDate: moment(studentPaymentInfo?.paymentDate).format('YYYY-MM-DD HH:mm:ss'),
        paymentType: studentPaymentInfo?.paymentType,
        lastUpdater: studentPaymentInfo?.lastUpdater,
        updatedAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        invoiceId: studentPaymentInfo?.invoiceId || 0
      });

      if (response.status !== 200) {
        yield put(studentPaymentsActions.setPhase('updating-error'));
        return;
      }

      yield put(studentPaymentsActions.setStudentPayment(response.data));
      yield put(studentPaymentsActions.setPhase('updating-success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_PAYMENT,
    function* deleteStudentPaymentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentPaymentsActions.setPhase('loading'));

      const { studentPaymentInfo, installments, receipts, paymentReports } = payload;

      if (studentPaymentInfo?.isRefund === 1) {
        const response = yield axios.delete(`${PAYMENTS_API_URL}/${studentPaymentInfo.id}`);

        if (response.status !== 204) {
          yield put(studentPaymentsActions.setPhase('deleting-error'));
          return;
        }

        yield put(studentPaymentsActions.removeStudentPayment(studentPaymentInfo.id));
        yield put(studentPaymentsActions.setPhase('deleting-success'));
      } else {
        const response = yield axios.delete(`${PAYMENTS_API_URL}/${studentPaymentInfo.id}`);

        if (response.status === 204) {
          for (let i = 0; i < installments.length; i++) {
            yield axios.patch(`${INSTALLMENTS_API_URL}/${installments[i].id}`, {
              isDonePayment: installments[i].isDonePayment
            });
          }

          for (let r = 0; r < receipts.length; r++) {
            yield axios.delete(`${RECEIPTS_API_URL}/${receipts[r].id}`);
          }

          for (let p = 0; p < paymentReports.length; p++) {
            yield axios.delete(`${PAYMENTS_REPORTS_API_URL}/${paymentReports[p].id}`);
          }

          yield put(studentPaymentsActions.removeStudentPayment(studentPaymentInfo.id));
          yield put(studentPaymentsActions.setPhase('deleting-success'));
        } else {
          yield put(studentPaymentsActions.setPhase('deleting-error'));
          return;
        }
      }
    }
  );
}
