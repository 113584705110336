import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const ExamTemplateList = Loadable(lazy(() => import('./ExamTemplateList')));
const ExamTemplateNew = Loadable(lazy(() => import('./ExamTemplateNew')));
const ExamTemplateSubject = Loadable(lazy(() => import('./ExamTemplateSubject')));
const routes = {
  path: 'templates',
  children: [
    {
      path: '',
      element: <ExamTemplateList />
    },
    {
      path: 'new',
      element: <ExamTemplateNew />
    },
    {
      path: ':id',
      element: <ExamTemplateList />
    },
    {
      path: ':id/:action',
      element: <ExamTemplateList />
    },
    {
      path: ':id/subjects',
      element: <ExamTemplateSubject />
    },
    {
      path: ':id/subjects/:subaction',
      element: <ExamTemplateSubject />
    },
    {
      path: ':id/subjects/:subid/:subaction',
      element: <ExamTemplateSubject />
    }
  ]
};

export default routes;
