import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { STUDENT_FEES_API_URL } from 'store/ApiUrls';
import produce from 'immer';

export interface IStudentFee {
  id?: number;
  studentId?: number;
  feeName?: string;
  feeAmount?: number;
  school?: ISchool;
  courseId?: number;
}

interface IStudentFeeState {
  studentFees: IStudentFee[];
  phase: string;
}

type TActionAllState = IStudentFeeState & {
  id: number;
  school: ISchool;
  studentId: number;
  studentFee: IStudentFee;
  studentFeeInfo: Partial<IStudentFee>;
  studentFeeInfos: Partial<IStudentFee[]>;
};

export const actionTypes = {
  PULL_STUDENT_FEES: 'studentFees/PULL_STUDENT_FEES',
  SET_STUDENT_FEES: 'studentFees/SET_STUDENT_FEES',
  ADD_STUDENT_FEE: 'studentFees/ADD_STUDENT_FEE',
  ADD_BULK_STUDENT_FEE: 'studentFees/ADD_BULK_STUDENT_FEE',
  UPDATE_STUDENT_FEE: 'studentFees/UPDATE_STUDENT_FEE',
  UPDATE_BULK_STUDENT_FEE: 'studentFees/UPDATE_BULK_STUDENT_FEE',
  DELETE_STUDENT_FEE: 'studentFees/DELETE_STUDENT_FEE',
  REMOVE_STUDENT_FEE: 'studentFees/REMOVE_STUDENT_FEE',
  SET_STUDENT_FEE: 'studentFees/SET_STUDENT_FEE',
  SET_PHASE: 'studentFees/SET_PHASE'
};

export const initialState: IStudentFeeState = {
  studentFees: [],
  phase: null
};

export const studentFeesSelector = createSelector(
  (state: IStudentFeeState) => objectPath.get(state, ['studentFees', 'studentFees']),
  (studentFees: IStudentFee[]) => studentFees
);

export const studentFeesPhaseSelector = createSelector(
  (state: IStudentFeeState) => objectPath.get(state, ['studentFees', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentFees' },
  (state: IStudentFeeState = initialState, action: IAction<TActionAllState>): IStudentFeeState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_FEES: {
        const { studentFees } = action.payload;
        return { ...state, studentFees };
      }
      case actionTypes.SET_STUDENT_FEE: {
        const { studentFee } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentFees.findIndex((d) => d.id === studentFee.id);
          if (index > -1) {
            draftState.studentFees[index] = studentFee;
          } else {
            draftState.studentFees.unshift(studentFee);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_FEE: {
        const { id } = action.payload;
        const studentFees = { ...state }.studentFees.filter((d) => d.id !== id);
        return { ...state, studentFees };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentFeesActions = {
  pullStudentFees: (studentId: number, school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_FEES,
    payload: { studentId, school }
  }),
  setStudentFees: (studentFees: IStudentFee[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_FEES,
    payload: { studentFees }
  }),
  addStudentFee: (studentFeeInfo: Partial<IStudentFee>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_FEE,
    payload: { studentFeeInfo }
  }),
  addBulkStudentFee: (
    studentFeeInfos: Partial<IStudentFee[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_BULK_STUDENT_FEE,
    payload: { studentFeeInfos }
  }),
  updateStudentFee: (studentFeeInfo: Partial<IStudentFee>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_FEE,
    payload: { studentFeeInfo }
  }),
  updateBulkStudentFee: (
    studentFeeInfos: Partial<IStudentFee[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_BULK_STUDENT_FEE,
    payload: { studentFeeInfos }
  }),
  deleteStudentFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_FEE,
    payload: { id }
  }),
  removeStudentFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_FEE,
    payload: { id }
  }),
  setStudentFee: (studentFee: IStudentFee): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_FEE,
    payload: { studentFee }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_FEES,
    function* pullStudentFeesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { studentId, school } = payload;
      const url = `${STUDENT_FEES_API_URL}.json?studentId=${studentId}&school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentFeesActions.setPhase('pulling-error'));
        return;
      }

      yield put(studentFeesActions.setStudentFees(response.data));
      yield put(studentFeesActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_FEE,
    function* addStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { studentFeeInfo } = payload;
      const response = yield axios.post(`${STUDENT_FEES_API_URL}`, {
        studentId: studentFeeInfo.studentId,
        feeName: studentFeeInfo.feeName,
        feeAmount: studentFeeInfo.feeAmount.toString(),
        school: `/api/schools/${studentFeeInfo.school.id}`,
        courseId: studentFeeInfo.courseId
      });

      if (response.status !== 201) {
        yield put(studentFeesActions.setPhase('adding-error'));
        return;
      }

      yield put(studentFeesActions.setStudentFee(response.data));
      yield put(studentFeesActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_BULK_STUDENT_FEE,
    function* addBulkStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { studentFeeInfos } = payload;
      try {
        for (let i = 0; i < studentFeeInfos.length; i++) {
          const response = yield axios.post(`${STUDENT_FEES_API_URL}`, {
            studentId: studentFeeInfos[i].studentId,
            feeName: studentFeeInfos[i].feeName,
            feeAmount: studentFeeInfos[i].feeAmount.toString(),
            school: `/api/schools/${studentFeeInfos[i].school.id}`,
            courseId: studentFeeInfos[i].courseId
          });

          yield put(studentFeesActions.setStudentFee(response.data));
        }

        yield put(studentFeesActions.setPhase('success'));
      } catch {
        yield put(studentFeesActions.setPhase('error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_FEE,
    function* updateStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { studentFeeInfo } = payload;
      const response = yield axios.patch(`${STUDENT_FEES_API_URL}/${studentFeeInfo.id}`, {
        feeAmount: studentFeeInfo.feeAmount.toString()
      });

      if (response.status !== 200) {
        yield put(studentFeesActions.setPhase('updating-error'));
        return;
      }

      yield put(studentFeesActions.setStudentFee(response.data));
      yield put(studentFeesActions.setPhase('updating-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_BULK_STUDENT_FEE,
    function* updateBulkStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { studentFeeInfos } = payload;
      try {
        for (let i = 0; i < studentFeeInfos.length; i++) {
          const response = yield axios.patch(`${STUDENT_FEES_API_URL}/${studentFeeInfos[i].id}`, {
            feeAmount: studentFeeInfos[i].feeAmount.toString()
          });

          yield put(studentFeesActions.setStudentFee(response.data));
        }

        yield put(studentFeesActions.setPhase('success'));
      } catch {
        yield put(studentFeesActions.setPhase('error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_FEE,
    function* deleteStudentFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_FEES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentFeesActions.setPhase('deleting-error'));
        return;
      }

      yield put(studentFeesActions.removeStudentFee(id));
      yield put(studentFeesActions.setPhase('deleting-success'));
    }
  );
}
