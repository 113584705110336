import React from 'react';

import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const CourseAssetList = Loadable(lazy(() => import('./CourseAssetList')));
const CourseAssetNew = Loadable(lazy(() => import('./CourseAssetNew')));
const routes = {
  path: 'asset',
  children: [
    {
      path: '',
      element: <CourseAssetList />
    },
    {
      path: 'new',
      element: <CourseAssetNew />
    },
    {
      path: ':id',
      element: <CourseAssetList />
    },
    {
      path: ':id/:action',
      element: <CourseAssetList />
    }
  ]
};

export default routes;
