import React from 'react';
import loadable from '@loadable/component';

import enrollmentAgreementRoutes from './agreements/routes';
import enrollmentDocumentRoutes from './documents/routes';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));
const EnrollmentPanel = loadable(() => import('./dashboard/index'));
const NewEnrollment = loadable(() => import('./enrollment/NewEnrollment'));
const StudentPreviousSeason = loadable(() => import('./enrollment/StudentPreviousSeason'));

const routes = {
  path: 'enrollment',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <EnrollmentPanel />
    },
    {
      path: 'dashboard',
      element: <EnrollmentPanel />
    },
    {
      path: 'new',
      element: <NewEnrollment />
    },
    {
      path: 'previous-season',
      element: <StudentPreviousSeason />
    },
    enrollmentAgreementRoutes,
    enrollmentDocumentRoutes
  ]
};

export default routes;
