import React from 'react';
import LegacyModalDialog from 'components/LegacyModalDialog';
import { Box } from '@mui/material';

export interface ISMModalSettings {
  intro?: string;
  iframeSrc: string;
  isOpen: boolean;
  handleClose?: () => void;
  selectCallback: (fieldId: string, url: string) => void;
  title: string;
}

const defaultSettings: ISMModalSettings = {
  intro: null,
  iframeSrc: null,
  isOpen: false,
  handleClose: null,
  selectCallback: null,
  title: 'SmartClass'
};

interface SMProviderProps {
  children?: React.ReactNode;
}
export interface LegacyModalContextValue {
  legacyModalSettings: Partial<ISMModalSettings>;
  showLegacyModal: (settings: Partial<ISMModalSettings>) => void;
}

const LegacyModalContext = React.createContext<LegacyModalContextValue>({
  legacyModalSettings: defaultSettings,
  showLegacyModal: () => {}
});
LegacyModalContext.displayName = 'SmartClass';

export const LegacyModalProvider = ({ children }: SMProviderProps) => {
  const [legacyModalSettings, setFileManagerSettings] = React.useState(defaultSettings);

  const showLegacyModal = (legacyModalSettings: Partial<ISMModalSettings>) => {
    setFileManagerSettings(Object.assign({}, defaultSettings, legacyModalSettings));
  };

  const handleHideFileManager = () => {
    setFileManagerSettings(Object.assign({}, legacyModalSettings, { isOpen: false }));
  };

  const handleModalClose = () => {
    if (legacyModalSettings.handleClose) {
      legacyModalSettings.handleClose();
    }
    handleHideFileManager();
  };

  /*
  React.useEffect(() => {
    const handleFileManagerCallback = (event) => {
      // const originalEvent = event.originalEvent;

      // Make sure the sender of the event is trusted
      if (event.data.sender === 'responsivefilemanager') {
        if (event.data.field_id) {
          const fieldId = event.data.field_id;
          const url = event.data.url;

          if (fieldId) {
            legacyModalSettings.selectCallback(fieldId, url);
          }
          handleHideFileManager();
        }
      }
    };

    //handler for a message from out of the box
    window.addEventListener('message', handleFileManagerCallback, false);

    return () => window.removeEventListener('message', handleFileManagerCallback, false);
  }, [legacyModalSettings]);
  */

  return (
    <LegacyModalContext.Provider
      value={{
        legacyModalSettings,
        showLegacyModal
      }}
    >
      {children}

      <LegacyModalDialog
        isOpen={legacyModalSettings.isOpen || defaultSettings.isOpen}
        intro={legacyModalSettings.intro || defaultSettings.intro}
        handleClose={handleModalClose}
        title={legacyModalSettings.title || defaultSettings.title}
      >
        <Box sx={{ height: 610 }}>
          <iframe
            title='SmartClass'
            id='schoostFrame'
            width='100%'
            height='600px'
            frameBorder='0'
            src={legacyModalSettings.iframeSrc}
          />
        </Box>
      </LegacyModalDialog>
    </LegacyModalContext.Provider>
  );
};

export const LegacyModalConsumer = LegacyModalContext.Consumer;

export default LegacyModalContext;
