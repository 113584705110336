import React from 'react';
import { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const StudentList = Loadable(lazy(() => import('./StudentList')));
const StudentPage = Loadable(lazy(() => import('./StudentPage')));

const routes = {
  path: '',
  children: [
    {
      path: '',
      element: <StudentList />
    },
    {
      path: ':id',
      element: <StudentPage />
    },
    {
      path: ':id/:section',
      element: <StudentPage />
    },
    {
      path: ':id/:section/:subaction',
      element: <StudentPage />
    },
    {
      path: ':id/:section/:subid',
      element: <StudentPage />
    },
    {
      path: ':id/:section/:subid/:subaction',
      element: <StudentPage />
    },
    {
      path: ':id/:section/installments',
      element: <StudentPage />,
      children: [
        { path: '', element: <StudentPage /> },
        { path: ':saction', element: <StudentPage /> },
        { path: ':sid/:saction', element: <StudentPage /> }
      ]
    },
    {
      path: ':id/:section/discounts',
      element: <StudentPage />,
      children: [
        { path: '', element: <StudentPage /> },
        { path: ':saction', element: <StudentPage /> },
        { path: ':sid/:saction', element: <StudentPage /> }
      ]
    },
    {
      path: ':id/:section/scholarship',
      element: <StudentPage />,
      children: [
        { path: '', element: <StudentPage /> },
        { path: ':saction', element: <StudentPage /> },
        { path: ':sid/:saction', element: <StudentPage /> }
      ]
    },
    {
      path: ':id/:section/payment',
      element: <StudentPage />,
      children: [
        { path: '', element: <StudentPage /> },
        { path: ':saction', element: <StudentPage /> },
        { path: ':sid/:saction', element: <StudentPage /> }
      ]
    }
  ]
};

export default routes;
