import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { OPTIC_FORM_TEMPLATES_API_URL } from 'store/ApiUrls';

export interface IOpticFormTemplate {
  id: number;
  formTitle: string;
}

interface IOpticFormTemplateState {
  opticFormTemplates: IOpticFormTemplate[];
  phase: string;
}

type TActionAllState = IOpticFormTemplateState & {
  id: number;
  opticFormTemplate: IOpticFormTemplate;
  opticFormTemplateInfo: Partial<IOpticFormTemplate>;
};

export const actionTypes = {
  PULL_OPTIC_FORM_TEMPLATES: 'opticFormTemplates/PULL_OPTIC_FORM_TEMPLATES',
  SET_OPTIC_FORM_TEMPLATES: 'opticFormTemplates/SET_OPTIC_FORM_TEMPLATES',
  ADD_OPTIC_FORM_TEMPLATE: 'opticFormTemplates/ADD_OPTIC_FORM_TEMPLATE',
  UPDATE_OPTIC_FORM_TEMPLATE: 'opticFormTemplates/UPDATE_OPTIC_FORM_TEMPLATE',
  DELETE_OPTIC_FORM_TEMPLATE: 'opticFormTemplates/DELETE_OPTIC_FORM_TEMPLATE',
  REMOVE_OPTIC_FORM_TEMPLATE: 'opticFormTemplates/REMOVE_OPTIC_FORM_TEMPLATE',
  SET_OPTIC_FORM_TEMPLATE: 'opticFormTemplates/SET_OPTIC_FORM_TEMPLATE',
  SET_PHASE: 'opticFormTemplates/SET_PHASE'
};

export const initialState: IOpticFormTemplateState = {
  opticFormTemplates: [],
  phase: null
};

export const opticFormTemplatesSelector = createSelector(
  (state: IOpticFormTemplateState) =>
    objectPath.get(state, ['exams', 'opticFormTemplates', 'opticFormTemplates']),
  (opticFormTemplates: IOpticFormTemplate[]) => opticFormTemplates
);
export const opticFormTemplatesPhaseSelector = createSelector(
  (state: IOpticFormTemplateState) =>
    objectPath.get(state, ['exams', 'opticFormTemplates', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'opticFormTemplates' },
  (
    state: IOpticFormTemplateState = initialState,
    action: IAction<TActionAllState>
  ): IOpticFormTemplateState => {
    switch (action.type) {
      case actionTypes.SET_OPTIC_FORM_TEMPLATES: {
        const { opticFormTemplates } = action.payload;
        return { ...state, opticFormTemplates };
      }
      case actionTypes.SET_OPTIC_FORM_TEMPLATE: {
        const { opticFormTemplate } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.opticFormTemplates.findIndex(
            (d) => d.id === opticFormTemplate.id
          );
          if (index > -1) {
            draftState.opticFormTemplates[index] = opticFormTemplate;
          } else {
            draftState.opticFormTemplates.unshift(opticFormTemplate);
          }
        });
      }
      case actionTypes.REMOVE_OPTIC_FORM_TEMPLATE: {
        const { id } = action.payload;
        const opticFormTemplates = { ...state }.opticFormTemplates.filter((d) => d.id !== id);
        return { ...state, opticFormTemplates };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const opticFormTemplatesActions = {
  pullOpticFormTemplates: () => ({
    type: actionTypes.PULL_OPTIC_FORM_TEMPLATES
  }),
  setOpticFormTemplates: (
    opticFormTemplates: IOpticFormTemplate[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_OPTIC_FORM_TEMPLATES,
    payload: { opticFormTemplates }
  }),
  addOpticFormTemplate: (
    opticFormTemplateInfo: Partial<IOpticFormTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_OPTIC_FORM_TEMPLATE,
    payload: { opticFormTemplateInfo }
  }),
  updateOpticFormTemplate: (
    opticFormTemplateInfo: Partial<IOpticFormTemplate>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_OPTIC_FORM_TEMPLATE,
    payload: { opticFormTemplateInfo }
  }),
  deleteOpticFormTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_OPTIC_FORM_TEMPLATE,
    payload: { id }
  }),
  removeOpticFormTemplate: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_OPTIC_FORM_TEMPLATE,
    payload: { id }
  }),
  setOpticFormTemplate: (
    opticFormTemplate: IOpticFormTemplate
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_OPTIC_FORM_TEMPLATE,
    payload: { opticFormTemplate }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_OPTIC_FORM_TEMPLATES, function* pullOpticFormTemplateSaga() {
    yield put(opticFormTemplatesActions.setPhase('loading'));

    const url = `${OPTIC_FORM_TEMPLATES_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(opticFormTemplatesActions.setPhase('error'));
      return;
    }

    yield put(opticFormTemplatesActions.setOpticFormTemplates(response.data));
    yield put(opticFormTemplatesActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_OPTIC_FORM_TEMPLATE,
    function* addOpticFormTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplatesActions.setPhase('adding'));

      const { opticFormTemplateInfo } = payload;
      const response = yield axios.post(`${OPTIC_FORM_TEMPLATES_API_URL}`, opticFormTemplateInfo);

      if (response.status !== 201) {
        yield put(opticFormTemplatesActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplatesActions.setOpticFormTemplate(response.data));
      yield put(opticFormTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_OPTIC_FORM_TEMPLATE,
    function* updateOpticFormTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplatesActions.setPhase('updating'));

      const { opticFormTemplateInfo } = payload;
      const response = yield axios.patch(
        `${OPTIC_FORM_TEMPLATES_API_URL}/${opticFormTemplateInfo.id}`,
        opticFormTemplateInfo
      );

      if (response.status !== 200) {
        yield put(opticFormTemplatesActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplatesActions.setOpticFormTemplate(response.data));
      yield put(opticFormTemplatesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_OPTIC_FORM_TEMPLATE,
    function* deleteOpticFormTemplateSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(opticFormTemplatesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${OPTIC_FORM_TEMPLATES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(opticFormTemplatesActions.setPhase('error'));
        return;
      }

      yield put(opticFormTemplatesActions.removeOpticFormTemplate(id));
      yield put(opticFormTemplatesActions.setPhase('success'));
    }
  );
}
