import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { DISCOUNT_CRITERIAS_API_URL } from 'store/ApiUrls';
import { IDiscountCriteria } from './data-types';

interface IDiscountCriteriaState {
  discountCriterias: IDiscountCriteria[];
  phase: string;
}

type TActionAllState = IDiscountCriteriaState & {
  id: number;
  activeSchool: ISchool;
  discountCriteria: IDiscountCriteria;
  discountCriteriaInfo: Partial<IDiscountCriteria>;
};

export const actionTypes = {
  PULL_DISCOUNT_CRITERIAS: 'discounts/PULL_DISCOUNT_CRITERIAS',
  SET_DISCOUNT_CRITERIAS: 'discounts/SET_DISCOUNT_CRITERIAS',
  ADD_DISCOUNT_CRITERIA: 'discounts/ADD_DISCOUNT_CRITERIA',
  DELETE_DISCOUNT_CRITERIA: 'discounts/DELETE_DISCOUNT_CRITERIA',
  REMOVE_DISCOUNT_CRITERIA: 'discounts/REMOVE_DISCOUNT_CRITERIA',
  SET_DISCOUNT_CRITERIA: 'discounts/SET_DISCOUNT_CRITERIA',
  SET_PHASE: 'discounts/SET_PHASE'
};

export const initialState: IDiscountCriteriaState = {
  discountCriterias: [],
  phase: null
};

export const discountCriteriasSelector = createSelector(
  (state: IDiscountCriteriaState) =>
    objectPath.get(state, ['discountCriterias', 'discountCriterias']),
  (discountCriterias: IDiscountCriteria[]) => discountCriterias
);

export const discountCriteriasPhaseSelector = createSelector(
  (state: IDiscountCriteriaState) => objectPath.get(state, ['discountCriterias', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'discountCriterias' },
  (
    state: IDiscountCriteriaState = initialState,
    action: IAction<TActionAllState>
  ): IDiscountCriteriaState => {
    switch (action.type) {
      case actionTypes.SET_DISCOUNT_CRITERIAS: {
        const { discountCriterias } = action.payload;
        return { ...state, discountCriterias };
      }
      case actionTypes.SET_DISCOUNT_CRITERIA: {
        const { discountCriteria } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.discountCriterias.findIndex((d) => d.id === discountCriteria.id);
          if (index > -1) {
            draftState.discountCriterias[index] = discountCriteria;
          } else {
            draftState.discountCriterias.unshift(discountCriteria);
          }
        });
      }
      case actionTypes.REMOVE_DISCOUNT_CRITERIA: {
        const { id } = action.payload;
        const discountCriterias = { ...state }.discountCriterias.filter((d) => d.id !== id);
        return { ...state, discountCriterias };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const discountCriteriasActions = {
  pullDiscountCriterias: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_DISCOUNT_CRITERIAS,
    payload: { activeSchool }
  }),
  setDiscountCriterias: (
    discountCriterias: IDiscountCriteria[]
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DISCOUNT_CRITERIAS,
    payload: { discountCriterias }
  }),
  addDiscountCriteria: (
    discountCriteriaInfo: Partial<IDiscountCriteria>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_DISCOUNT_CRITERIA,
    payload: { discountCriteriaInfo }
  }),
  deleteDiscountCriteria: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_DISCOUNT_CRITERIA,
    payload: { id }
  }),
  removeDiscountCriteria: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_DISCOUNT_CRITERIA,
    payload: { id }
  }),
  setDiscountCriteria: (
    discountCriteria: IDiscountCriteria
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_DISCOUNT_CRITERIA,
    payload: { discountCriteria }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_DISCOUNT_CRITERIAS,
    function* pullDiscountCriteriasSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountCriteriasActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${DISCOUNT_CRITERIAS_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(discountCriteriasActions.setPhase('error'));
        return;
      }

      yield put(discountCriteriasActions.setDiscountCriterias(response.data));
      yield put(discountCriteriasActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_DISCOUNT_CRITERIA,
    function* addDiscountCriteriaSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountCriteriasActions.setPhase('adding'));

      const { discountCriteriaInfo } = payload;
      const response = yield axios.post(`${DISCOUNT_CRITERIAS_API_URL}`, discountCriteriaInfo);

      if (response.status !== 200) {
        yield put(discountCriteriasActions.setPhase('error'));
        return;
      }

      yield put(discountCriteriasActions.setDiscountCriteria(response.data));
      yield put(discountCriteriasActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_DISCOUNT_CRITERIA,
    function* deleteDiscountCriteriaSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(discountCriteriasActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${DISCOUNT_CRITERIAS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(discountCriteriasActions.setPhase('error'));
        return;
      }
      yield put(discountCriteriasActions.removeDiscountCriteria(id));
      yield put(discountCriteriasActions.setPhase('success'));
    }
  );
}
