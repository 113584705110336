import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { IAction } from 'store/store';
import { COURSE_ASSET_API_URL } from 'store/ApiUrls';
import { IUser } from 'pages/account/account-types';

export interface ICourseAsset {
  id?: number;
  lectureName?: string;
  lectureFile?: string;
  courseId?: number;
  batchId?: number;
  classId?: number;
  createdAt?: string;
  updatedAt?: string;
}

interface ICourseAssetState {
  courseAssets: ICourseAsset[];
  phase: string;
}

type TActionAllState = ICourseAssetState & {
  id: number;
  activeUser: IUser;
  courseAsset: ICourseAsset;
  courseAssetInfo: Partial<ICourseAsset>;
};

export const actionTypes = {
  PULL_COURSE_ASSETS: 'courseAsset/PULL_COURSE_ASSETS',
  SET_COURSE_ASSETS: 'courseAsset/SET_COURSE_ASSETS',
  ADD_COURSE_ASSET: 'courseAsset/ADD_COURSE_ASSET',
  UPDATE_COURSE_ASSET: 'courseAsset/UPDATE_COURSE_ASSET',
  DELETE_COURSE_ASSET: 'courseAsset/DELETE_COURSE_ASSET',
  REMOVE_COURSE_ASSET: 'courseAsset/REMOVE_COURSE_ASSET',
  SET_COURSE_ASSET: 'courseAsset/SET_COURSE_ASSET',
  SET_PHASE: 'courseAsset/SET_PHASE'
};

export const initialState: ICourseAssetState = {
  courseAssets: [],
  phase: null
};

export const courseAssetsSelector = createSelector(
  (state: ICourseAssetState) => objectPath.get(state, ['courseAssets', 'courseAssets']),
  (courseAssets: ICourseAsset[]) => courseAssets
);

export const courseAssetsPhaseSelector = createSelector(
  (state: ICourseAssetState) => objectPath.get(state, ['courseAssets', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'courseAssets' },
  (
    state: ICourseAssetState = initialState,
    action: IAction<TActionAllState>
  ): ICourseAssetState => {
    switch (action.type) {
      case actionTypes.SET_COURSE_ASSETS: {
        const { courseAssets } = action.payload;
        return { ...state, courseAssets };
      }
      case actionTypes.SET_COURSE_ASSET: {
        const { courseAsset } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.courseAssets.findIndex((d) => d.id === courseAsset.id);
          if (index > -1) {
            draftState.courseAssets[index] = courseAsset;
          } else {
            draftState.courseAssets.unshift(courseAsset);
          }
        });
      }
      case actionTypes.REMOVE_COURSE_ASSET: {
        const { id } = action.payload;
        const courseAssets = { ...state }.courseAssets.filter((d) => d.id !== id);
        return { ...state, courseAssets };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const courseAssetsActions = {
  pullCourseAssets: (): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_COURSE_ASSETS
  }),
  setCourseAssets: (courseAssets: ICourseAsset[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COURSE_ASSETS,
    payload: { courseAssets }
  }),
  addCourseAsset: (courseAssetInfo: Partial<ICourseAsset>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_COURSE_ASSET,
    payload: { courseAssetInfo }
  }),
  updateCourseAsset: (
    courseAssetInfo: Partial<ICourseAsset>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_COURSE_ASSET,
    payload: { courseAssetInfo }
  }),
  deleteCourseAsset: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_COURSE_ASSET,
    payload: { id }
  }),
  removeCourseAsset: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_COURSE_ASSET,
    payload: { id }
  }),
  setCourseAsset: (courseAsset: ICourseAsset): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COURSE_ASSET,
    payload: { courseAsset }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.PULL_COURSE_ASSETS, function* pullCourseAssetSaga() {
    yield put(courseAssetsActions.setPhase('loading'));

    const url = `${COURSE_ASSET_API_URL}.json?pagination=false`;
    const response = yield axios.get(url);

    if (response.status !== 200) {
      yield put(courseAssetsActions.setPhase('error'));
      return;
    }

    yield put(courseAssetsActions.setCourseAssets(response.data));
    yield put(courseAssetsActions.setPhase('success'));
  });

  yield takeLatest(
    actionTypes.ADD_COURSE_ASSET,
    function* addCourseAssetSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseAssetsActions.setPhase('adding'));

      const { courseAssetInfo } = payload;
      const response = yield axios.post(`${COURSE_ASSET_API_URL}`, {
        id: courseAssetInfo.id,
        lectureName: courseAssetInfo.lectureName,
        lectureFile: courseAssetInfo.lectureFile,
        courseId: courseAssetInfo.courseId,
        batchId: courseAssetInfo.batchId,
        classId: courseAssetInfo.classId
      });

      if (response.status !== 200) {
        yield put(courseAssetsActions.setPhase('error'));
        return;
      }

      yield put(courseAssetsActions.setCourseAsset(response.data));
      yield put(courseAssetsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_COURSE_ASSET,
    function* updateCourseAssetSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseAssetsActions.setPhase('updating'));

      const { courseAssetInfo } = payload;
      const response = yield axios.patch(`${COURSE_ASSET_API_URL}/${courseAssetInfo.id}`, {
        lectureName: courseAssetInfo.lectureName,
        lectureFile: courseAssetInfo.lectureFile,
        courseId: courseAssetInfo.courseId,
        batchId: courseAssetInfo.batchId,
        classId: courseAssetInfo.classId
      });

      if (response.status !== 200) {
        yield put(courseAssetsActions.setPhase('error'));
        return;
      }

      yield put(courseAssetsActions.setCourseAsset(response.data));
      yield put(courseAssetsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_COURSE_ASSET,
    function* deleteCourseAssetSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(courseAssetsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${COURSE_ASSET_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(courseAssetsActions.setPhase('error'));
        return;
      }
      yield put(courseAssetsActions.removeCourseAsset(id));
      yield put(courseAssetsActions.setPhase('success'));
    }
  );
}
