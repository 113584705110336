import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { PROMISSORIES_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { ISchool } from 'pages/organization/organization-types';

export interface IPromissoryNote {
  id: number;
  promissoryNo?: number;
  studentId: number;
  title?: string;
  address?: string;
  promissoryDate?: string;
  amount?: number;
  promissoryOrder?: number;
  numberOfPromissory?: number;
  isPaid?: string;
  returnDate?: string;
  lawyer?: string;
  school: number;
  courseId?: number;
}

interface IPromissoryNoteState {
  promissoryNotes: IPromissoryNote[];
  phase: string;
}

type TActionAllState = IPromissoryNoteState & {
  id: number;
  school: ISchool;
  promissoryNote: IPromissoryNote;
  promissoryNoteInfo: Partial<IPromissoryNote>;
  promissoryNoteInfos: Partial<IPromissoryNote[]>;
};

export const actionTypes = {
  PULL_PROMISSORY_NOTES: 'promissoryNotes/PULL_PROMISSORY_NOTES',
  SET_PROMISSORY_NOTES: 'promissoryNotes/SET_PROMISSORY_NOTES',
  ADD_PROMISSORY_NOTE: 'promissoryNotes/ADD_PROMISSORY_NOTE',
  UPDATE_PROMISSORY_NOTE: 'promissoryNotes/UPDATE_PROMISSORY_NOTE',
  DELETE_PROMISSORY_NOTE: 'promissoryNotes/DELETE_PROMISSORY_NOTE',
  REMOVE_PROMISSORY_NOTE: 'promissoryNotes/REMOVE_PROMISSORY_NOTE',
  SET_PROMISSORY_NOTE: 'promissoryNotes/SET_PROMISSORY_NOTE',
  SET_PHASE: 'promissoryNotes/SET_PHASE'
};

export const initialState: IPromissoryNoteState = {
  promissoryNotes: [],
  phase: null
};

export const promissoryNotesSelector = createSelector(
  (state: IPromissoryNoteState) =>
    objectPath.get(state, ['students', 'promissoryNotes', 'promissoryNotes']),
  (promissoryNotes: IPromissoryNote[]) => promissoryNotes
);

export const promissoryNotesPhaseSelector = createSelector(
  (state: IPromissoryNoteState) => objectPath.get(state, ['students', 'promissoryNotes', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'promissoryNotes' },
  (
    state: IPromissoryNoteState = initialState,
    action: IAction<TActionAllState>
  ): IPromissoryNoteState => {
    switch (action.type) {
      case actionTypes.SET_PROMISSORY_NOTES: {
        const { promissoryNotes } = action.payload;
        return { ...state, promissoryNotes };
      }
      case actionTypes.SET_PROMISSORY_NOTE: {
        const { promissoryNote } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.promissoryNotes.findIndex((d) => d.id === promissoryNote.id);
          if (index > -1) {
            draftState.promissoryNotes[index] = promissoryNote;
          } else {
            draftState.promissoryNotes.unshift(promissoryNote);
          }
        });
      }
      case actionTypes.REMOVE_PROMISSORY_NOTE: {
        const { id } = action.payload;
        const promissoryNotes = { ...state }.promissoryNotes.filter((d) => d.id !== id);
        return { ...state, promissoryNotes };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const promissoryNotesActions = {
  pullPromissoryNotes: (school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_PROMISSORY_NOTES,
    payload: { school }
  }),
  setPromissoryNotes: (promissoryNotes: IPromissoryNote[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PROMISSORY_NOTES,
    payload: { promissoryNotes }
  }),
  addPromissoryNote: (
    promissoryNoteInfos: Partial<IPromissoryNote[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_PROMISSORY_NOTE,
    payload: { promissoryNoteInfos }
  }),
  updatePromissoryNote: (
    promissoryNoteInfo: Partial<IPromissoryNote>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_PROMISSORY_NOTE,
    payload: { promissoryNoteInfo }
  }),
  deletePromissoryNote: (
    promissoryNoteInfos: Partial<IPromissoryNote[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_PROMISSORY_NOTE,
    payload: { promissoryNoteInfos }
  }),
  removePromissoryNote: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_PROMISSORY_NOTE,
    payload: { id }
  }),
  setPromissoryNote: (promissoryNote: IPromissoryNote): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PROMISSORY_NOTE,
    payload: { promissoryNote }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_PROMISSORY_NOTES,
    function* pullPromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(promissoryNotesActions.setPhase('loading'));

      const { school } = payload;
      const url = `${PROMISSORIES_API_URL}.json?school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(promissoryNotesActions.setPhase('error'));
        return;
      }

      yield put(promissoryNotesActions.setPromissoryNotes(response.data));
      yield put(promissoryNotesActions.setPhase('pulling-success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_PROMISSORY_NOTE,
    function* addPromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(promissoryNotesActions.setPhase('adding'));

      const { promissoryNoteInfos } = payload;

      try {
        for (let i = 0; i < promissoryNoteInfos.length; i++) {
          const response = yield axios.post(`${PROMISSORIES_API_URL}`, {
            studentId: promissoryNoteInfos[i].studentId,
            title: promissoryNoteInfos[i].title,
            address: promissoryNoteInfos[i].address,
            promissoryNo: promissoryNoteInfos[i].promissoryNo,
            promissoryDate: promissoryNoteInfos[i].promissoryDate,
            amount: promissoryNoteInfos[i].amount.toString(),
            promissoryOrder: promissoryNoteInfos[i].promissoryOrder,
            numberOfPromissory: promissoryNoteInfos[i].numberOfPromissory,
            isPaid: promissoryNoteInfos[i].isPaid,
            school: promissoryNoteInfos[i].school,
            courseId: promissoryNoteInfos[i].courseId,
            returnDate: '0000-00-00',
            lawyer: '0'
          });

          yield put(promissoryNotesActions.setPromissoryNote(response.data));
        }

        yield put(promissoryNotesActions.setPhase('adding-success'));
      } catch (error) {
        // Update phase
        yield put(promissoryNotesActions.setPhase('adding-error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_PROMISSORY_NOTE,
    function* updatePromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(promissoryNotesActions.setPhase('updating'));

      const { promissoryNoteInfo } = payload;
      const response = yield axios.patch(
        `${PROMISSORIES_API_URL}/${promissoryNoteInfo.id}`,
        promissoryNoteInfo
      );

      if (response.status !== 200) {
        yield put(promissoryNotesActions.setPhase('updating-error'));
        return;
      }

      yield put(promissoryNotesActions.setPromissoryNote(response.data));
      yield put(promissoryNotesActions.setPhase('updating-success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_PROMISSORY_NOTE,
    function* deletePromissoryNoteSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(promissoryNotesActions.setPhase('loading'));

      const { promissoryNoteInfos } = payload;

      try {
        for (let i = 0; i < promissoryNoteInfos.length; i++) {
          const response = yield axios.delete(
            `${PROMISSORIES_API_URL}/${promissoryNoteInfos[i].id}`
          );

          if (response.status === 204) {
            yield put(promissoryNotesActions.removePromissoryNote(promissoryNoteInfos[i].id));
          }
        }
        yield put(promissoryNotesActions.setPhase('deleting-success'));
      } catch (error) {
        yield put(promissoryNotesActions.setPhase('deleting-error'));
      }
    }
  );
}
