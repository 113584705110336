import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';

import { IAction, TPhase } from 'store/store';
import { ACADEMIC_REPORT_STATIC_TEXT_API_URL } from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';
import produce from 'immer';

export interface IAcReportStaticText {
  id?: number;
  staticText?: string;
  staticTextStyle?: string;
  reportId?: number;
  school?: ISchool;
}
interface IAcReportStaticTextState {
  staticTexts: IAcReportStaticText[];
  phase: TPhase;
}
type TActionAllState = IAcReportStaticTextState & {
  id: number;
  acReportId: number;
  staticText: IAcReportStaticText;
  staticTextInfo: Partial<IAcReportStaticText>;
  activeSchool: ISchool;
};

export const actionTypes = {
  PULL_AC_REPORT_STATIC_TEXTS: 'acReportStaticTexts/PULL_AC_REPORT_STATIC_TEXTS',
  SET_AC_REPORT_STATIC_TEXTS: 'acReportStaticTexts/SET_AC_REPORT_STATIC_TEXTS',
  ADD_AC_REPORT_STATIC_TEXT: 'acReportStaticTexts/ADD_AC_REPORT_STATIC_TEXT',
  SET_AC_REPORT_STATIC_TEXT: 'acReportStaticTexts/SET_AC_REPORT_STATIC_TEXT',
  DELETE_AC_REPORT_STATIC_TEXT: 'acReportStaticTexts/DELETE_AC_REPORT_STATIC_TEXT',
  REMOVE_AC_REPORT_STATIC_TEXT: 'acReportStaticTexts/REMOVE_AC_REPORT_STATIC_TEXT',
  SET_PHASE: 'acReportStaticTexts/SET_PHASE'
};

export const initialState: IAcReportStaticTextState = {
  staticTexts: [],
  phase: null
};

export const acReportStaticTextSelector = createSelector(
  (state: IAcReportStaticTextState) =>
    objectPath.get(state, ['academic', 'staticTexts', 'staticTexts']),
  (reportTypes: IAcReportStaticText[]) => reportTypes
);
export const acReportStaticTextPhaseSelector = createSelector(
  (state: IAcReportStaticTextState) => objectPath.get(state, ['academic', 'staticTexts', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'acReportStaticTexts', whitelist: ['staticTexts', 'phase'] },
  (
    state: IAcReportStaticTextState = initialState,
    action: IAction<TActionAllState>
  ): IAcReportStaticTextState => {
    switch (action.type) {
      case actionTypes.SET_AC_REPORT_STATIC_TEXTS: {
        const { staticTexts } = action.payload;
        return { ...state, staticTexts };
      }
      case actionTypes.SET_AC_REPORT_STATIC_TEXT: {
        const { staticText } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.staticTexts.findIndex((d) => d.id === staticText.id);
          if (index > -1) {
            draftState.staticTexts[index] = staticText;
          } else {
            draftState.staticTexts.unshift(staticText);
          }
        });
      }
      case actionTypes.REMOVE_AC_REPORT_STATIC_TEXT: {
        const { id } = action.payload;
        const staticTexts = { ...state }.staticTexts.filter((d) => d.id !== id);
        return { ...state, staticTexts };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const acReportStaticTextActions = {
  pullStaticTexts: (
    acReportId: number,
    activeSchool: ISchool
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_AC_REPORT_STATIC_TEXTS,
    payload: { activeSchool, acReportId }
  }),
  setStaticTexts: (staticTexts: IAcReportStaticText[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_AC_REPORT_STATIC_TEXTS,
    payload: { staticTexts }
  }),
  addStaticText: (
    staticTextInfo: Partial<IAcReportStaticText>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_AC_REPORT_STATIC_TEXT,
    payload: { staticTextInfo }
  }),
  deleteStaticText: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_AC_REPORT_STATIC_TEXT,
    payload: { id }
  }),
  setStaticText: (staticText: IAcReportStaticText): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_AC_REPORT_STATIC_TEXT,
    payload: { staticText }
  }),
  removeStaticText: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_AC_REPORT_STATIC_TEXT,
    payload: { id }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_AC_REPORT_STATIC_TEXTS,
    function* pullAcReportStaticTextsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportStaticTextActions.setPhase('loading'));

      const { acReportId, activeSchool } = payload;

      const url = `${ACADEMIC_REPORT_STATIC_TEXT_API_URL}.json?reportId=${acReportId}&school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(acReportStaticTextActions.setPhase('error'));
        return;
      }

      yield put(acReportStaticTextActions.setStaticTexts(response.data));
      yield put(acReportStaticTextActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_AC_REPORT_STATIC_TEXT,
    function* addAcReportStaticTextSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportStaticTextActions.setPhase('adding'));

      const { staticTextInfo } = payload;
      const response = yield axios.post(`${ACADEMIC_REPORT_STATIC_TEXT_API_URL}`, staticTextInfo);

      if (response.status !== 201) {
        yield put(acReportStaticTextActions.setPhase('error'));
        return;
      }

      yield put(acReportStaticTextActions.setStaticText(response.data));
      yield put(acReportStaticTextActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_AC_REPORT_STATIC_TEXT,
    function* deleteAcReportStaticTextSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportStaticTextActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${ACADEMIC_REPORT_STATIC_TEXT_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(acReportStaticTextActions.setPhase('error'));
        return;
      }
      yield put(acReportStaticTextActions.removeStaticText(id));
      yield put(acReportStaticTextActions.setPhase('success'));
    }
  );
}
