import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';
import { ISchool, ISeason } from 'pages/organization/organization-types';
import { IAction, TPhase } from 'store/store';
import { ATTENDANCES_API_URL } from 'store/ApiUrls';
import { IStudent } from 'pages/students/_store/types';
import { IAttendanceType } from '../types/_store/attendance-types';
import moment from 'moment';
import { schedulesActions } from 'pages/schedule/_store/schedule';

export interface IAttendance {
  id?: number;
  attendanceDate?: string;
  attendanceHour?: number;
  enterTime?: string;
  exitTime?: string;
  explanation?: string;
  attendanceType?: IAttendanceType;
  student?: IStudent;
  classTypeId?: number;
  classBranchId?: number;
  teacherId?: number;
  classId?: number;
  scheduleId?: number;
  adder?: string;
  addedAt?: string;
  school?: ISchool;
}

interface IAttendanceState {
  attendances: IAttendance[];
  phase: TPhase;
}

type TActionAllState = IAttendanceState & {
  id: number;
  scheduleId: number;
  activeSchool: ISchool;
  activeSeason: ISeason;
  studentId: number;
  attendance: IAttendance;
  attendanceInfo: Partial<IAttendance>;
  attendanceInfos: Partial<IAttendance[]>;
};

export const actionTypes = {
  PULL_ATTENDANCES: 'attendances/PULL_ATTENDANCES',
  SET_ATTENDANCES: 'attendances/SET_ATTENDANCES',
  ADD_ATTENDANCE: 'attendances/ADD_ATTENDANCE',
  ADD_MULTIPLE_ATTENDANCE: 'attendances/ADD_MULTIPLE_ATTENDANCE',
  UPDATE_ATTENDANCE: 'attendances/UPDATE_ATTENDANCE',
  DELETE_ATTENDANCE: 'attendances/DELETE_ATTENDANCE',
  REMOVE_ATTENDANCE: 'attendances/REMOVE_ATTENDANCE',
  SET_ATTENDANCE: 'attendances/SET_ATTENDANCE',
  SET_PHASE: 'attendances/SET_PHASE'
};

export const initialState: IAttendanceState = {
  attendances: [],
  phase: null
};

export const attendancesSelector = createSelector(
  (state: IAttendanceState) => objectPath.get(state, ['attendances', 'attendances']),
  (attendances: IAttendance[]) => attendances
);

export const attendancesPhaseSelector = createSelector(
  (state: IAttendanceState) => objectPath.get(state, ['attendances', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'attendances' },
  (state: IAttendanceState = initialState, action: IAction<TActionAllState>): IAttendanceState => {
    switch (action.type) {
      case actionTypes.SET_ATTENDANCES: {
        const { attendances } = action.payload;
        return { ...state, attendances };
      }
      case actionTypes.SET_ATTENDANCE: {
        const { attendance } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.attendances.findIndex((d) => d.id === attendance.id);
          if (index > -1) {
            draftState.attendances[index] = attendance;
          } else {
            draftState.attendances.unshift(attendance);
          }
        });
      }
      case actionTypes.REMOVE_ATTENDANCE: {
        const { id } = action.payload;
        const attendances = { ...state }.attendances.filter((d) => d.id !== id);
        return { ...state, attendances };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const attendancesActions = {
  pullAttendances: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_ATTENDANCES,
    payload: { activeSchool }
  }),
  setAttendances: (attendances: IAttendance[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ATTENDANCES,
    payload: { attendances }
  }),
  addAttendance: (attendanceInfo: Partial<IAttendance>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_ATTENDANCE,
    payload: { attendanceInfo }
  }),
  addMultipleAttendance: (
    scheduleId: number,
    attendanceInfos: Partial<IAttendance[]>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_MULTIPLE_ATTENDANCE,
    payload: { scheduleId, attendanceInfos }
  }),
  updateAttendance: (attendanceInfo: Partial<IAttendance>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_ATTENDANCE,
    payload: { attendanceInfo }
  }),
  deleteAttendance: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_ATTENDANCE,
    payload: { id }
  }),
  removeAttendance: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_ATTENDANCE,
    payload: { id }
  }),
  setAttendance: (attendance: IAttendance): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_ATTENDANCE,
    payload: { attendance }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_ATTENDANCES,
    function* pullAttendancesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(attendancesActions.setPhase('loading'));

      const { activeSchool } = payload;
      const url = `${ATTENDANCES_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(attendancesActions.setPhase('error'));
        return;
      }

      yield put(attendancesActions.setAttendances(response.data));
      yield put(attendancesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_MULTIPLE_ATTENDANCE,
    function* addMultipleAttendanceSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(attendancesActions.setPhase('adding'));

      const { scheduleId, attendanceInfos } = payload;

      try {
        attendanceInfos.map((v) => {
          axios.post(`${ATTENDANCES_API_URL}`, {
            attendanceDate: v.attendanceDate,
            enterTime: v.enterTime,
            exitTime: v.exitTime,
            teacherId: v.teacherId,
            student: `/api/students/${v.student?.id}`,
            classTypeId: v.classTypeId,
            classBranchId: v.classBranchId,
            classId: v.classId,
            scheduleId: v.scheduleId,
            adder: v.adder,
            addedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            school: `/api/schools/${v.school.id}`,
            attendanceType: v.attendanceType?.id
              ? `/api/attendance_types/${v.attendanceType.id}`
              : null
          });
        });

        yield put(schedulesActions.updateSchedule({ id: scheduleId }));
      } catch (e) {
        yield put(attendancesActions.setPhase('error'));
      }

      yield put(attendancesActions.setPhase('success'));
    }
  );
}
