import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { STUDENT_BUS_FEES_API_URL } from 'store/ApiUrls';
import produce from 'immer';
import { ISchoolBusFee } from 'pages/bus-fee/_store/bus-fee';

export interface IStudentBusFee {
  id: number;
  studentId: number;
  schoolBusFee?: ISchoolBusFee;
  feeTitle?: string;
  feeAmount?: number;
  isFull?: string;
  school?: number;
}

interface IStudentBusFeeState {
  studentBusFees: IStudentBusFee[];
  phase: string;
}

type TActionAllState = IStudentBusFeeState & {
  id: number;
  studentId: number;
  studentBusFee: IStudentBusFee;
  studentBusFeeInfo: Partial<IStudentBusFee>;
};

export const actionTypes = {
  PULL_STUDENT_BUS_FEES: 'studentBusFees/PULL_STUDENT_BUS_FEES',
  SET_STUDENT_BUS_FEES: 'studentBusFees/SET_STUDENT_BUS_FEES',
  ADD_STUDENT_BUS_FEE: 'studentBusFees/ADD_STUDENT_BUS_FEE',
  UPDATE_STUDENT_BUS_FEE: 'studentBusFees/UPDATE_STUDENT_BUS_FEE',
  DELETE_STUDENT_BUS_FEE: 'studentBusFees/DELETE_STUDENT_BUS_FEE',
  REMOVE_STUDENT_BUS_FEE: 'studentBusFees/REMOVE_STUDENT_BUS_FEE',
  SET_STUDENT_BUS_FEE: 'studentBusFees/SET_STUDENT_BUS_FEE',
  SET_PHASE: 'studentBusFees/SET_PHASE'
};

export const initialState: IStudentBusFeeState = {
  studentBusFees: [],
  phase: null
};

export const studentBusFeesSelector = createSelector(
  (state: IStudentBusFeeState) =>
    objectPath.get(state, ['students', 'studentBusFees', 'studentBusFees']),
  (studentBusFees: IStudentBusFee[]) => studentBusFees
);

export const studentBusFeesPhaseSelector = createSelector(
  (state: IStudentBusFeeState) => objectPath.get(state, ['students', 'studentBusFees', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentBusFees' },
  (
    state: IStudentBusFeeState = initialState,
    action: IAction<TActionAllState>
  ): IStudentBusFeeState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_BUS_FEES: {
        const { studentBusFees } = action.payload;
        return { ...state, studentBusFees };
      }
      case actionTypes.SET_STUDENT_BUS_FEE: {
        const { studentBusFee } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.studentBusFees.findIndex((d) => d.id === studentBusFee.id);
          if (index > -1) {
            draftState.studentBusFees[index] = studentBusFee;
          } else {
            draftState.studentBusFees.unshift(studentBusFee);
          }
        });
      }
      case actionTypes.REMOVE_STUDENT_BUS_FEE: {
        const { id } = action.payload;
        const studentBusFees = { ...state }.studentBusFees.filter((d) => d.id !== id);
        return { ...state, studentBusFees };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentBusFeesActions = {
  pullStudentBusFees: (studentId: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_BUS_FEES,
    payload: { studentId }
  }),
  setStudentBusFees: (studentBusFees: IStudentBusFee[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BUS_FEES,
    payload: { studentBusFees }
  }),
  addStudentBusFee: (
    studentBusFeeInfo: Partial<IStudentBusFee>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_STUDENT_BUS_FEE,
    payload: { studentBusFeeInfo }
  }),
  updateStudentBusFee: (
    studentBusFeeInfo: Partial<IStudentBusFee>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_STUDENT_BUS_FEE,
    payload: { studentBusFeeInfo }
  }),
  deleteStudentBusFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_STUDENT_BUS_FEE,
    payload: { id }
  }),
  removeStudentBusFee: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_STUDENT_BUS_FEE,
    payload: { id }
  }),
  setStudentBusFee: (studentBusFee: IStudentBusFee): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_BUS_FEE,
    payload: { studentBusFee }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_BUS_FEES,
    function* pullStudentBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBusFeesActions.setPhase('loading'));

      const { studentId } = payload;
      const url = `${STUDENT_BUS_FEES_API_URL}.json?studentId=${studentId}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentBusFeesActions.setPhase('error'));
        return;
      }

      yield put(studentBusFeesActions.setStudentBusFees(response.data));
      yield put(studentBusFeesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_STUDENT_BUS_FEE,
    function* addStudentBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBusFeesActions.setPhase('adding'));

      const { studentBusFeeInfo } = payload;
      const response = yield axios.post(`${STUDENT_BUS_FEES_API_URL}`, studentBusFeeInfo);

      if (response.status !== 201) {
        yield put(studentBusFeesActions.setPhase('adding-error'));
        return;
      }

      yield put(studentBusFeesActions.setStudentBusFee(response.data));
      yield put(studentBusFeesActions.setPhase('adding-success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_STUDENT_BUS_FEE,
    function* updateStudentBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBusFeesActions.setPhase('adding'));

      const { studentBusFeeInfo } = payload;
      const response = yield axios.patch(
        `${STUDENT_BUS_FEES_API_URL}/${studentBusFeeInfo.id}`,
        studentBusFeeInfo
      );

      if (response.status !== 200) {
        yield put(studentBusFeesActions.setPhase('error'));
        return;
      }

      yield put(studentBusFeesActions.setStudentBusFee(response.data));
      yield put(studentBusFeesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_STUDENT_BUS_FEE,
    function* deleteStudentBusFeeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentBusFeesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${STUDENT_BUS_FEES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(studentBusFeesActions.setPhase('error'));
        return;
      }

      yield put(studentBusFeesActions.removeStudentBusFee(id));
      yield put(studentBusFeesActions.setPhase('deleting-success'));
    }
  );
}
