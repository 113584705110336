import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import produce from 'immer';

import { IAction, TPhase } from 'store/store';
import { ACADEMIC_REPORT_TYPES_API_URL } from 'store/ApiUrls';
import { ISchool } from 'pages/organization/organization-types';

export interface IAcReportType {
  id?: number;
  reportTitle?: string;
  reportLanguage?: string;
  paperSize?: string;
  paperSizeWidth?: number;
  paperSizeHeight?: number;
  paperLayout?: string;
  fontSize?: number;
  bgImage?: string;
  staticText?: string;
  successDocument?: string;
  school?: ISchool;
}
interface IAcReportTypeState {
  reportTypes: IAcReportType[];
  phase: TPhase;
}
type TActionAllState = IAcReportTypeState & {
  id: number;
  activeSchool: ISchool;
  reportType: IAcReportType;
  reportTypeInfo: Partial<IAcReportType>;
};

export const actionTypes = {
  PULL_AC_REPORT_TYPES: 'acReportTypes/PULL_AC_REPORT_TYPES',
  SET_AC_REPORT_TYPES: 'acReportTypes/SET_AC_REPORT_TYPES',
  ADD_AC_REPORT_TYPE: 'acReportTypes/ADD_AC_REPORT_TYPE',
  UPDATE_AC_REPORT_TYPE: 'acReportTypes/UPDATE_AC_REPORT_TYPE',
  DELETE_AC_REPORT_TYPE: 'acReportTypes/DELETE_AC_REPORT_TYPE',
  REMOVE_AC_REPORT_TYPE: 'acReportTypes/REMOVE_AC_REPORT_TYPE',
  SET_AC_REPORT_TYPE: 'acReportTypes/SET_AC_REPORT_TYPE',
  SET_PHASE: 'acReportTypes/SET_PHASE'
};

export const initialState: IAcReportTypeState = {
  reportTypes: [],
  phase: null
};

export const acReportTypesSelector = createSelector(
  (state: IAcReportTypeState) => objectPath.get(state, ['academic', 'reportTypes', 'reportTypes']),
  (reportTypes: IAcReportType[]) => reportTypes
);
export const acReportTypesPhaseSelector = createSelector(
  (state: IAcReportTypeState) => objectPath.get(state, ['academic', 'reportTypes', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'acReportTypes', whitelist: ['reportTypes', 'phase'] },
  (
    state: IAcReportTypeState = initialState,
    action: IAction<TActionAllState>
  ): IAcReportTypeState => {
    switch (action.type) {
      case actionTypes.SET_AC_REPORT_TYPES: {
        const { reportTypes } = action.payload;
        return { ...state, reportTypes };
      }
      case actionTypes.SET_AC_REPORT_TYPE: {
        const { reportType } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.reportTypes.findIndex((d) => d.id === reportType.id);
          if (index > -1) {
            draftState.reportTypes[index] = reportType;
          } else {
            draftState.reportTypes.unshift(reportType);
          }
        });
      }
      case actionTypes.REMOVE_AC_REPORT_TYPE: {
        const { id } = action.payload;
        const reportTypes = { ...state }.reportTypes.filter((d) => d.id !== id);
        return { ...state, reportTypes };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const acReportTypesActions = {
  pullReportTypes: (activeSchool: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_AC_REPORT_TYPES,
    payload: { activeSchool }
  }),
  setReportTypes: (reportTypes: IAcReportType[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_AC_REPORT_TYPES,
    payload: { reportTypes }
  }),
  addReportType: (reportTypeInfo: Partial<IAcReportType>): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_AC_REPORT_TYPE,
    payload: { reportTypeInfo }
  }),
  updateReportType: (
    reportTypeInfo: Partial<IAcReportType>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_AC_REPORT_TYPE,
    payload: { reportTypeInfo }
  }),
  deleteReportType: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_AC_REPORT_TYPE,
    payload: { id }
  }),
  removeReportType: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_AC_REPORT_TYPE,
    payload: { id }
  }),
  setReportType: (reportType: IAcReportType): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_AC_REPORT_TYPE,
    payload: { reportType }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_AC_REPORT_TYPES,
    function* pullAcReportTypesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportTypesActions.setPhase('loading'));

      const { activeSchool } = payload;

      const url = `${ACADEMIC_REPORT_TYPES_API_URL}.json?school=${activeSchool.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(acReportTypesActions.setPhase('error'));
        return;
      }

      yield put(acReportTypesActions.setReportTypes(response.data));
      yield put(acReportTypesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_AC_REPORT_TYPE,
    function* addAcReportTypeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportTypesActions.setPhase('adding'));

      const { reportTypeInfo } = payload;
      const response = yield axios.post(`${ACADEMIC_REPORT_TYPES_API_URL}`, reportTypeInfo);

      if (response.status !== 200) {
        yield put(acReportTypesActions.setPhase('error'));
        return;
      }

      yield put(acReportTypesActions.setReportTypes(response.data));
      yield put(acReportTypesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_AC_REPORT_TYPE,
    function* updateAcReportTypeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportTypesActions.setPhase('updating'));

      const { reportTypeInfo } = payload;
      const response = yield axios.patch(
        `${ACADEMIC_REPORT_TYPES_API_URL}/${reportTypeInfo.id}`,
        reportTypeInfo
      );

      if (response.status !== 200) {
        yield put(acReportTypesActions.setPhase('error'));
        return;
      }

      yield put(acReportTypesActions.setReportType(response.data));
      yield put(acReportTypesActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_AC_REPORT_TYPE,
    function* deleteAcReportTypeSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(acReportTypesActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${ACADEMIC_REPORT_TYPES_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(acReportTypesActions.setPhase('error'));
        return;
      }
      yield put(acReportTypesActions.removeReportType(id));
      yield put(acReportTypesActions.setPhase('success'));
    }
  );
}
