import React from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Avatar, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { AppDispatch, RootState } from 'store/store';
import { authUserSelector } from 'store/auth';
import {
  scheduleSelector,
  schedulePhaseSelector,
  schedulesActions
} from 'pages/schedule/_store/schedule';
import { ISchool } from 'pages/organization/organization-types';
import {
  userActiveSchoolSelector,
  userActiveSeasonSelector,
  userPersonalSelector
} from 'store/user';
import SchoostDialog from 'components/SchoostDialog';
import { ISchedule } from 'pages/schedule/_store/schedule-types';
import moment from 'moment';
import { attendancesActions, IAttendance } from 'pages/attendance/_store/attendance';

const mapStateToProps = (state: RootState) => ({
  user: authUserSelector(state),
  userPersonal: userPersonalSelector(state),
  activeSchool: userActiveSchoolSelector(state),
  activeSeason: userActiveSeasonSelector(state),
  schedules: scheduleSelector(state),
  schedulePhase: schedulePhaseSelector(state)
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  pullSchedules: (school: ISchool) => dispatch(schedulesActions.pullSchedules(school)),
  addMultipleAttendance: (scheduleId: number, attendanceInfos: Partial<IAttendance[]>) =>
    dispatch(attendancesActions.addMultipleAttendance(scheduleId, attendanceInfos)),
  pullTeacherSchedules: (teacherId: number, school: ISchool) =>
    dispatch(schedulesActions.pullTeacherSchedules(teacherId, school))
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TAttendanceDialogProps = PropsFromRedux;

const AttendanceDialog: React.FC<TAttendanceDialogProps> = (props) => {
  const {
    user,
    userPersonal,
    activeSchool,
    activeSeason,
    schedules,
    schedulePhase,
    addMultipleAttendance,
    pullTeacherSchedules
  } = props;
  const intl = useIntl();
  const [activeTermId, setActiveTermId] = React.useState<number>(0);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchResult, setSearchResult] = React.useState<ISchedule[]>([]);
  const [attendanceStudents, setAttendanceStudents] = React.useState<IAttendance[]>([]);

  const handleOnChangeAttendance = (index: number) => {
    const values = [...attendanceStudents];
    if (values[index].attendanceType?.id === 100) {
      values[index].attendanceType.id = null;
      values[index].attendanceType.title = intl.formatMessage({ id: 'attendance.available' });
    } else {
      values[index].attendanceType.id = 100;
      values[index].attendanceType.title = intl.formatMessage({ id: 'attendance.unknown' });
    }
    setAttendanceStudents(values);
  };

  const handleSaveAttendance = () => {
    setIsLoading(true);
    addMultipleAttendance(searchResult[0].id, attendanceStudents);
  };

  React.useEffect(() => {
    const currentTime = moment().format('HH:mm');
    if (user?.userType?.id === 6) {
      if (schedules.length > 0) {
        const searchData = schedules.filter(
          (schedule) =>
            schedule.enterTime <= currentTime &&
            schedule.exitTime >= currentTime &&
            schedule.isDoneAttendance === '0'
        );
        setSearchResult(searchData);

        if (searchData.length > 0) {
          const classStudents = searchData[0].classId.students.filter(
            (s) => s.term === activeTermId
          );

          if (classStudents.length > 0) {
            setAttendanceStudents(
              classStudents?.map((v) => ({
                enterTime: searchData[0].enterTime,
                exitTime: searchData[0].exitTime,
                classTypeId: searchData[0].classTypeId?.id,
                classBranchId: searchData[0].classBranchId,
                classId: searchData[0].classId.id,
                scheduleId: searchData[0].id,
                adder: user.uuid,
                teacherId: userPersonal?.id,
                attendanceType: {
                  id: null,
                  title: intl.formatMessage({ id: 'attendance.available' })
                },
                school: activeSchool,
                attendanceDate: moment().format('YYYY-MM-DD').toString(),
                student: v.student
              }))
            );
            setShowDialog(true);
          }
        } else {
          setSearchResult([]);
          setShowDialog(false);
        }
      } else {
        setSearchResult([]);
        setShowDialog(false);
      }
    } else {
      setShowDialog(false);
    }
  }, [schedules]);

  React.useEffect(() => {
    if (activeSeason?.gradingTerms?.length > 0) {
      const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');
      const activeTerm = activeSeason.gradingTerms.filter(
        (t) => t.startDate.toString() <= currentDate && t.endDate.toString() >= currentDate
      );

      if (activeTerm.length > 0) {
        setActiveTermId(activeTerm[0].id);
      } else {
        setActiveTermId(0);
      }
    } else {
      setActiveTermId(0);
    }
  }, [activeSchool]);

  React.useEffect(() => {
    if (schedulePhase === 'success') {
      setIsLoading(false);
    }
  }, [schedulePhase]);

  React.useEffect(() => {
    if (user?.userType?.id === 6) {
      pullTeacherSchedules(userPersonal.id, activeSchool);
    }
  }, [activeSchool]);

  return showDialog ? (
    <SchoostDialog
      title={
        intl.formatMessage({ id: 'academic.take_attendance' }) +
        ' [' +
        searchResult[0]?.classId?.title +
        '] [' +
        searchResult[0]?.enterTime +
        ' - ' +
        searchResult[0]?.exitTime +
        ']'
      }
      isOpen={true}
      dividers={true}
      handleClose={() => setShowDialog(false)}
    >
      <Grid container spacing={1}>
        {attendanceStudents &&
          attendanceStudents.map((student, i) => (
            <Grid key={student?.student?.id} item xs={4} md={3} lg={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Avatar
                  onClick={() => handleOnChangeAttendance(i)}
                  src={student?.student?.photo}
                  sx={{
                    width: 70,
                    height: 70,
                    border: student?.attendanceType?.id ? '5px solid red' : '5px solid green',
                    cursor: 'pointer'
                  }}
                />
                <Typography mt={1} variant='caption'>
                  {student?.student?.fullName}
                </Typography>
                <Typography variant='caption'>
                  {student?.attendanceType?.title
                    ? '[' + student?.attendanceType?.title + ']'
                    : null}
                </Typography>
              </Box>
            </Grid>
          ))}
        <Grid item xs={12} md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button variant='contained' onClick={handleSaveAttendance}>
                {intl.formatMessage({ id: 'attendance.click_here_to_mark_attendance' })}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </SchoostDialog>
  ) : null;
};

export default connector(AttendanceDialog);
